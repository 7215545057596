import React, { useState } from "react";
import { InputMoneyStyled } from "./InputMoney.style";
import { formatMoney } from "../../../utils/formatMoney";

function InputMoney({ pattern, onChangeValue, placeholder, ...props }) {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    let formattedValue = event.target.value;
    formattedValue = formatMoney(formattedValue);

    setValue(formattedValue);
    onChangeValue(formattedValue.slice(3));
  };

  return (
    <InputMoneyStyled
      {...props}
      value={value}
      placeholder={placeholder}
      pattern={pattern}
      onChange={handleChange}
    />
  );
}

export default InputMoney;
