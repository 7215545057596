/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { LINKS } from "../../../constants";
import { Section } from "../../../components/shared/pages";
import imagensConsorcio from "../../../asserts/images/consorcios";
import { publishAndRemoveBanner, publishBanner } from "../../../utils";
import {
  CardInfo,
  CardReducedInstallment,
  SectionCards,
  SectionContentInfo,
  SectionInfo,
  SectionReducedInfo,
} from "./style";
import HandleMoney from "../../../asserts/components/HandleMoney";
import HandShake from "../../../asserts/components/HandShake";
import HouseKey from "../../../asserts/components/HouseKey";
import HouseTree from "../../../asserts/components/HouseTree";
import { ParcelaReduzidaFAQ } from "../sections/ParcelaReduzidaFAQ";

// import {ReactComponent as rouletteIcon} from "../../../asserts/images/icon-seja-sortudao.svg";

const ReducedInstallment = (props) => {
  const iconStyle = {
    color: "blue",
  };

  const regulamentoSortudao = () => {
    window.open(LINKS.linkRegulamentoAcaoPremiada, "_blank");
  };

  const linkAutoCompra = () => {
    window.open(LINKS.autoCompraConsorcioProdutos, "_blank");
  };

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  const publishBannerSortudaoDate = "2024-08-27 00:00:00";
  const removeBannerSortudaoDate = "2024-12-31 23:59:59";

  const publishBannerSortudaoEncerradoDate = "2024-08-31 23:59:59";

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {publishAndRemoveBanner(
        publishBannerSortudaoDate,
        removeBannerSortudaoDate
      ) &&
        (isLargeScreen ? (
          <a href={LINKS.autoCompraConsorcio} target="_blank" rel="noreferrer">
            <img
              src={imagensConsorcio.parcelaReduzida}
              alt="bannerLPParcelaReduzida"
              style={{ objectFit: "contain", width: "100%" }}
            />
          </a>
        ) : (
          <a href={LINKS.autoCompraConsorcio} target="_blank" rel="noreferrer">
            <img
              src={imagensConsorcio.parcelaReduzidaMobile}
              alt="bannerLPParcelaReduzida"
              style={{ objectFit: "contain", width: "100%" }}
              href={LINKS.autoCompraConsorcio}
            />
          </a>
        ))}
      <SectionCards>
        <h3>O primeiro passo para ter o seu imóvel</h3>
        <div className="content-cards">
          <CardInfo>
            <HandleMoney />
            <h5>Conquiste pagando pouco</h5>
            <p>
              Agora o Consórcio Imobiliário possui a opção de Parcela Reduzida
              em 30% por 80 meses, ou até a contemplação.
            </p>
          </CardInfo>
          <CardInfo>
            <HandShake />
            <h5>Invista no seu sonho</h5>
            <p>
              Mas corra! O Parcela Reduzida no Consórcio Imobiliário estará
              disponível apenas entre os dias{" "}
              <span>01/10/2024 e 31/01/2025</span>.
            </p>
          </CardInfo>
          <CardInfo>
            <HouseKey />
            <h5>Mude a sua Vida</h5>
            <p>
              Esta é a oportunidade perfeita para você investir ou sair do
              aluguel e mudar a sua vida para melhor.
            </p>
          </CardInfo>
        </div>
      </SectionCards>

      <SectionReducedInfo>
        <img src={imagensConsorcio.parcela30} alt="parcela reduzida em 30%" />
        <div className="content-info">
          <p>
            Ao adquirir um <span>Consórcio Imobiliário</span> você conta com a
            opção de Parcela Reduzida!
          </p>
          <p>
            Ou seja, até a contemplação** você paga 30% a menos na sua parcela!
          </p>
        </div>
        <h3>O primeiro passo para ter o seu imóvel</h3>
        <div className="content-cards">
          <CardReducedInstallment>
            <p className="subtitle">Valor da Carta</p>
            <h5>
              <span>R$</span> 50.000,00
            </h5>
            <p className="installment-details">Parcelas de</p>
            <p className="installment-details">
              <span>R$ 1.000,00</span> por
            </p>
            <h5 className="installment">
              <span>R$</span> 700,00
            </h5>
          </CardReducedInstallment>
          <CardReducedInstallment>
            <p className="subtitle">Valor da Carta</p>
            <h5>
              <span>R$</span> 200.000,00
            </h5>
            <p className="installment-details">Parcelas de</p>
            <p className="installment-details">
              <span>R$ 1.245,00</span> por
            </p>
            <h5 className="installment">
              <span>R$</span> 871,50
            </h5>
          </CardReducedInstallment>
          <CardReducedInstallment>
            <p className="subtitle">Valor da Carta</p>
            <h5>
              <span>R$</span> 400.000,00
            </h5>
            <p className="installment-details">Parcelas de</p>
            <p className="installment-details">
              <span>R$ 2.490,00</span> por
            </p>
            <h5 className="installment">
              <span>R$</span> 1.743,00
            </h5>
          </CardReducedInstallment>
          <CardReducedInstallment>
            <p className="subtitle">Valor da Carta</p>
            <h5>
              <span>R$</span> 900.000,00
            </h5>
            <p className="installment-details">Parcelas de</p>
            <p className="installment-details">
              <span>R$ 5.602,50</span> por
            </p>
            <h5 className="installment">
              <span>R$</span> 3.921,75
            </h5>
          </CardReducedInstallment>
        </div>
      </SectionReducedInfo>

      <SectionContentInfo>
        <div className="content">
          <HouseTree />
          <h3>Seu sonho da casa própria com parcelas ainda mais leves</h3>

          <div className="final-time">
            <h3>Mas corra, é só até 31/12/24!</h3>
          </div>

          <div className="general-info">
            <h3>Conheça as vantagens exclusivas CAIXA Consórcio</h3>
            <div className="content-descriptions">
              <div className="description">
                <img src={imagensConsorcio.nuvem} alt="chave voando" />
                <div className="column-text">
                  <h5>Parcelas Reduzidas</h5>
                  <p>
                    Tenha mais tranquilidade financeira com parcelas 30% menores
                    até a contemplação ou a 80ª parcela, o que ocorrer primeiro.
                  </p>
                </div>
              </div>
              <div className="description">
                <img src={imagensConsorcio.nuvem} alt="chave voando" />
                <div className="column-text">
                  <h5>Uso do FGTS</h5>
                  <p>
                    Utilize seu FGTS para dar lances, facilitando a antecipação
                    da contemplação e a conquista do seu imóvel mais cedo.
                  </p>
                </div>
              </div>
              <div className="description">
                <img src={imagensConsorcio.nuvem} alt="chave voando" />
                <div className="column-text">
                  <h5>Sem Juros</h5>
                  <p>
                    Diferente de um financiamento tradicional, no consórcio você
                    não paga juros. Economize e invista no que realmente
                    importa.
                  </p>
                </div>
              </div>
              <div className="description">
                <img src={imagensConsorcio.nuvem} alt="chave voando" />
                <div className="column-text">
                  <h5>Planejamento de Vida</h5>
                  <p>
                    Ideal para quem planeja com calma e quer comprar um imóvel
                    de forma inteligente, sem apertos financeiros.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a href={LINKS.autoCompraConsorcio} target="_blank" rel="noreferrer">
            <div className="link-info">
              <h3>Simule Aqui</h3>
            </div>
          </a>
        </div>
      </SectionContentInfo>

      <Section
        as={ParcelaReduzidaFAQ}
        title="Perguntas Frequentes"
        className="cc-faq-saiba-mais titleSectionParcelaReduzida"
        id="saiba-mais-consorcio-sortudao"
        sectionLayout={null}
        sectionName="saiba mais parcela reduzida"
      />

      <SectionInfo>
        <div className="content">
          <p>
            Valores de parcela para grupos em formação, e podem sofrer alteração
            sem prévia informação devido a movimentação do grupo e cota.
            Consulte disponibilidade.
          </p>
        </div>
      </SectionInfo>
    </>
  );
};

export default ReducedInstallment;
