import React from "react";
import PropTypes from "prop-types";
import DualContent from "../blocks/DualContent";
import Section from "../pages/section";
import CenterImageContent from "./CenterImageContent";

const ContentWithImage = ({
  imageColumn,
  contentColumn,
  image,
  children,
  imageRight,
  className,
}) => {
  return (
    <DualContent
      className={`${imageRight ? "flex-row-reverse" : ""} ${className}`}
      colunaA={imageColumn}
      colunaB={contentColumn}
    >
      <CenterImageContent image={image} />
      <Section.Content>{children}</Section.Content>
    </DualContent>
  );
};

ContentWithImage.defaultProps = {
  imageColumn: null,
  contentColumn: null,
  imageRight: false,
  image: null,
};

ContentWithImage.propTypes = {
  imageColumn: PropTypes.objectOf(Object),
  contentColumn: PropTypes.objectOf(Object),
  image: PropTypes.objectOf(Object),
  imageRight: PropTypes.bool,
};

export default ContentWithImage;
