import styled from "styled-components";

export const InputContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgb(51, 51, 51, 0.62);

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start; /* Centrer verticalement */
    align-items: center;
  }
`;

export const ContainerNovo = styled.div`
  display: flex;
  flex-direction: column;
  aign-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`;

export const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  aign-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const RadioContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  aign-items: center;
  flex-wrap: wrap;
  width: 100%;
`;
