import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Section } from "@components/shared/pages";
import { useMakeClass } from "../../../../hooks";

// eslint-disable-next-line react/prop-types
const BannerContent = ({ content, right, customPros, offsetRight }) => {
  const colConfig = right
    ? {
        md: { colspan: 6, offset: 6 },
        lg: { colspan: 4, offset: offsetRight !== 0 ? offsetRight : 8 },
      }
    : { md: 10, lg: 4 };

  return (
    <Row className="banner-content">
      <Col {...{ xs: 12, ...colConfig, ...customPros }}>{content}</Col>
    </Row>
  );
};

BannerContent.defaultProps = {
  right: false,
  customPros: null,
};

BannerContent.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  right: PropTypes.bool,
  customPros: PropTypes.objectOf(Object),
};

const Banner = (props) => {
  const { right, children, className, href } = props;
  const classes = useMakeClass(`page-banner`, className);

  const section = (
    <Section
      as={BannerContent}
      right={right}
      content={children}
      {...props}
      className={classes}
      sectionName="banner produto"
    />
  );

  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {section}
      </a>
    );
  }

  return section;
};

Banner.valid = (el) => Banner.name === el.type.name;

Banner.defaultProps = {
  right: false,
  offsetRight: 0,
  group: null,
  className: null,
  customPros: null,
  href: null,
};

Banner.propTypes = {
  right: PropTypes.bool,
  offsetRight: PropTypes.bool,
  group: PropTypes.string,
  className: PropTypes.string,
  customPros: PropTypes.objectOf(Object),
  href: PropTypes.string,
};

export default Banner;
