import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../shared/actions";

const CookiesModal = ({ onClose, onSave }) => {
  const [cookiesAnalitico, setCookiesAnalitico] = useState(false);
  const [cookiesMarketing, setCookiesMarketing] = useState(false);

  const handleSave = () => {
    onSave(cookiesAnalitico, cookiesMarketing);
  };
  return (
    <div className="modal-container">
      <div className="cookies-modal">
        <button type="button" className="fechar-modal" onClick={onClose}>
          x
        </button>
        <span className="titulo-modal">Personalizar Cookies</span>
        <span className="subtitulo-modal">
          Ligue e desligue quais os tipos de cookies deseja compartilhar com a
          gente:
        </span>
        <div className="cards-container">
          <div className="card-cookies">
            <span className="title-card">Cookies Essenciais</span>
            <span className="description-card">
              Os cookies essenciais já são ativos e coletados a partir do
              primeiro momento que você entra em nosso site, eles servem pra
              você conseguir navegar sem complicações e com segurança.
            </span>
          </div>
          <div className="card-cookies">
            <span className="title-card">Cookies Analíticos</span>
            <span className="description-card">
              Para entender melhor o seu comportamento e analisar os dados sobre
              as ações tomadas no site com o objetivo de verificar a performance
              dos nossos produtos.
            </span>
            <div className="toggle-cookie">
              <label htmlFor="b">
                <input
                  type="checkbox"
                  checked={cookiesAnalitico}
                  onChange={() => setCookiesAnalitico(!cookiesAnalitico)}
                />{" "}
              </label>
              <span className="label-toggle">
                Autorizo o uso dos meus dados
              </span>
            </div>
          </div>
          <div className="card-cookies">
            <span className="title-card">Cookies Marketing</span>
            <span className="description-card">
              Para mapear sua jornada de compra, possibilitando o uso destes
              dados para te oferecer produtos de forma personalizada.
            </span>
            <div className="toggle-cookie">
              <label htmlFor="b">
                <input
                  type="checkbox"
                  checked={cookiesMarketing}
                  onChange={() => setCookiesMarketing(!cookiesMarketing)}
                />{" "}
              </label>
              <span className="label-toggle">
                Autorizo o uso dos meus dados
              </span>
            </div>
          </div>
        </div>
        <div className="modal-botao">
          <span>
            Saiba mais em{" "}
            <a href="https://portaldaprivacidade.caixaconsorcio.com.br/">
              Política de Cookies e Privacidade
            </a>
            .
          </span>
          <Button className="botao-salvar" onClick={handleSave}>
            Salvar
          </Button>
          <Button className="botao-cancelar" outline onClick={onClose}>
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};

CookiesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CookiesModal;
