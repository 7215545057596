import React from "react";
import PropTypes from "prop-types";
import { Spinner, SpinnerContainer } from "./styles";

const SpinnerLoading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner animation="border" variant="primary" />
      </SpinnerContainer>
    );
  }

  return null;
};

SpinnerLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default SpinnerLoading;
