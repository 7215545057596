/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
import React from "react";
import * as style from "./styles";

const InputTxt = ({
  mask,
  maskChar,
  label,
  name,
  type,
  register,
  errors,
  validationRules,
  setValue,
  onkeydown,
  setRendaValue,
  setPremiacaoValue,
}) => {
  const changedRendaValue = (value) => {
    setRendaValue(value);
    setValue(name, value);
  };
  const changedPremiacaoValue = (value) => {
    setPremiacaoValue(value);
    setValue(name, value);
  };
  return (
    <style.Inputdiv>
      <>
        {name === "Renda" || name === "Premiacao" ? (
          <style.InputMoneyStyled
            onChangeValue={
              name === "Premiacao" ? changedPremiacaoValue : changedRendaValue
            }
            onChange={(e) => {
              setValue(name, e.target.value);
            }}
            placeholder={label}
            onKeyDown={(e) => {
              if (
                !/^[0-9]*$/.test(e.key) &&
                e.key !== "Backspace" &&
                e.key !== "Tab"
              ) {
                e.preventDefault();
              }
            }}
            {...register(name, validationRules)}
            type="text"
          />
        ) : (
          <style.InputMaskStyled
            key={name}
            mask={mask}
            maskChar={maskChar}
            id={name}
            name={name}
            type={type}
            onKeyDown={onkeydown}
            placeholder={label}
            {...register(name, validationRules)}
            onChange={(e) => {
              setValue(name, e.target.value);
            }}
          />
        )}
        {errors[name] && <p>{errors[name].message}</p>}
      </>
    </style.Inputdiv>
  );
};

export default InputTxt;
