import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Nav, Row } from "react-bootstrap";
import { applyKey, distinctArray } from "@utils";
import { Button } from "../shared/actions";

const DesktopSubMenuContents = ({ menuGroupItems }) => {
  const [collumnItems, setCollumnItems] = useState([]);

  useEffect(() => {
    const groups = distinctArray(menuGroupItems.map((item) => item.group));

    const groupedItems = groups.map((group) =>
      menuGroupItems.filter((item) => item.group === group)
    );

    setCollumnItems(groupedItems);

    return () => {
      setCollumnItems([]);
    };
  }, [menuGroupItems, setCollumnItems]);

  const columns = applyKey(collumnItems, (group) => {
    const menuItens = applyKey(group, (item) => {
      return (
        <Nav.Link
          as={Button}
          ignoreBlank
          {...item}
          variant="link"
          className={
            `focus-shadow-none text-left d-flex justify-content-start align-items-center ${item.className}` ||
            ""
          }
        />
      );
    });
    return (
      <Col className="d-flex flex-column justify-content-start pt-2">
        {menuItens}
      </Col>
    );
  });

  return (
    <Nav className="mr-auto flex-column ">
      <Row>{columns}</Row>
    </Nav>
  );
};

DesktopSubMenuContents.defaultProps = {};

DesktopSubMenuContents.propTypes = {
  menuGroupItems: PropTypes.arrayOf(Object).isRequired,
};

export default DesktopSubMenuContents;
