import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Link from "./Link";
import { envs } from "../../../config";

const ChatButton = (props) => {
  const { chatId, button, chatPopUpProps } = props;

  const ref = useRef(null);

  function openChatHandle() {
    window.open(
      `${envs.hostLiveAgentChatRouting}?button_id=${chatId}`,
      null,
      Object.keys(chatPopUpProps)
        .map((key) => `${key}=${chatPopUpProps[key]}`)
        .join(", ")
    );

    if (ref.current) {
      ref.current.blur();
    }
  }

  const actionProps = {
    ...props,
    blank: true,
    onClick: openChatHandle,
    chatPopUpProps: null,
    button: null,
    chatId: null,
    custom: !button,
  };

  return <Button ref={ref} {...actionProps} />;
};

ChatButton.defaultProps = {
  ...Button.defaultProps,
  ...Link.defaultProps,
  button: false,
  chatPopUpProps: {
    height: 600,
    width: 500,
    status: "yes",
    fullscreen: "no",
    toolbar: "no",
    menubar: "no",
    location: "no",
  },
};

ChatButton.propTypes = {
  ...Button.propTypes,
  ...Link.propTypes,
  chatId: PropTypes.string.isRequired,
  chatPopUpProps: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    status: PropTypes.oneOf(["yes", "no"]),
    fullscreen: PropTypes.oneOf(["yes", "no"]),
    toolbar: PropTypes.oneOf(["yes", "no"]),
    menubar: PropTypes.oneOf(["yes", "no"]),
    location: PropTypes.oneOf(["yes", "no"]),
  }),
  button: PropTypes.bool,
};

export default ChatButton;
