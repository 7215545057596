import querystring from "querystring";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";

import envs from "../../config/envs";
import { endPoints } from "../../config";

const useAuthToken = () => {
  const [token, setToken] = useState(sessionStorage.getItem("oauth_token"));
  const [error, setError] = useState(null);

  const refreshToken = useCallback(async () => {
    const tokenRequestBody = {
      grant_type: "client_credentials",
      scope: envs.authScope,
      client_id: envs.clientId,
      client_secret: envs.clientSecret,
    };

    try {
      const { data } = await axios.post(
        endPoints.getAuthTokenEndPoint,
        querystring.stringify(tokenRequestBody),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      setToken(data.access_token);
      return data.access_token;
    } catch (err) {
      setError(err);
    }
    return "";
  }, []);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("oauth_token", token);
    } else {
      refreshToken();
    }
  }, [token, refreshToken]);

  const getToken = async () => {
    return token || refreshToken();
  };

  return { error, refreshToken, getToken };
};

export default useAuthToken;
