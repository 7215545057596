import React from "react";
import PropTypes from "prop-types";
import { ContentWithCardList } from "@components/blocosCustomizados";
import { createImageElement } from "../../asserts/images";

const Benefits = ({ items, sectionTitle }) => {
  const conteudoVantagem = items?.map(
    ({ title, image, description, ...rest }) => ({
      ...rest,
      imagem: createImageElement(image, {
        alt: title,
        className: "cvp-benefit-image w-100 h-100 ",
      }),
      titleConteudo: <h5>{title}</h5>,
      conteudo: <p>{description}</p>,
    })
  );
  return (
    <ContentWithCardList listItens={conteudoVantagem} className="cvp-benefits">
      <h3 className="content-title">{sectionTitle}</h3>
    </ContentWithCardList>
  );
};

Benefits.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Benefits;
