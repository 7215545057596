import React from "react";
import PropTypes from "prop-types";

export const error404 = "404";
export const adicionar = "adicionar";
export const adicionarm = "adicionar-m";
export const anexar = "anexar";
export const apagar = "apaerrorgar";
export const atencaog = "atencao-g";
export const atencaom = "atencao-m";
export const atencaop = "atencao-p";
export const atendimento = "atendimento";
export const auto = "auto";
export const bloquear = "bloquear";
export const boletom = "boleto-m";
export const boletop = "boleto-p";
export const calendariom = "calendario-m";
export const calendariop = "calendario-p";
export const camerap = "camera-p";
export const cap = "cap";
export const chat = "chat";
export const conversa = "conversa";
export const checkg = "check-g";
export const checkm = "check-m";
export const checkp = "check-p";
export const compartilharp = "compartilhar-p";
export const conectarp = "conectar-p";
export const conexao = "conexao";
export const configuracoesp = "configuracoes-p";
export const consorcio = "consorcio";
export const creditop = "credito-p";
export const dental = "dental";
export const desbloquear = "desbloquear";
export const download = "download";
export const editar = "editar";
export const emailp = "email-p";
export const errog = "erro-g";
export const errop = "erro-p";
export const facebook = "facebook";
export const fechar = "fechar";
export const formulario = "formulario";
export const habitacao = "habitacao";
export const hora = "hora";
export const imagem = "imagem";
export const imprimir = "imprimir";
export const infog = "info-g";
export const infom = "info-m";
export const infop = "info-p";
export const insta = "insta";
export const linkar = "linkar";
export const linkedin = "linkedin";
export const logout = "logout";
export const menu = "menu";
export const menuacionar = "menu-acionar";
export const menubusca = "menu-busca";
export const menuduvidas = "menu-duvidas";
export const menupagamento = "menu-pagamento";
export const menupainel = "menu-painel";
export const menuseguro = "menu-seguro";
export const menuusuario = "menu-usuario";
export const midia = "midia";
export const pagamentog = "pagamento-g";
export const pdf = "pdf";
export const pin = "pin";
export const prev = "prev";
export const refreshm = "refresh-m";
export const refreshp = "refresh-p";
export const residencia = "residencia";
export const sair = "sair";
export const salvar = "salvar";
export const setabaixo = "seta-baixo";
export const setabaixop = "seta-baixo-p";
export const setacima = "seta-cima";
export const setacimap = "seta-cima-p";
export const setadireita = "seta-direita";
export const setadireitap = "seta-direita-p";
export const setaesquerda = "seta-esquerda";
export const setaesquerdap = "seta-esquerda-p";
export const telefonar = "telefonar";
export const telefone = "telefone";
export const tempom = "tempo-m";
export const tempop = "tempo-p";
export const timeline = "timeline";
export const triste = "triste";
export const twitter = "twitter";
export const upload = "upload";
export const vida = "vida";
export const visualizarp = "visualizar-p";
export const word = "word";
export const youtube = "youtube";

export const iconNames = [
  error404,
  adicionar,
  adicionarm,
  anexar,
  apagar,
  atencaog,
  atencaom,
  atencaop,
  atendimento,
  auto,
  bloquear,
  boletom,
  boletop,
  calendariom,
  calendariop,
  camerap,
  cap,
  chat,
  conversa,
  checkg,
  checkm,
  checkp,
  compartilharp,
  conectarp,
  conexao,
  configuracoesp,
  consorcio,
  creditop,
  dental,
  desbloquear,
  download,
  editar,
  emailp,
  errog,
  errop,
  facebook,
  fechar,
  formulario,
  habitacao,
  hora,
  imagem,
  imprimir,
  infog,
  infom,
  infop,
  insta,
  linkar,
  linkedin,
  logout,
  menu,
  menuacionar,
  menubusca,
  menuduvidas,
  menupagamento,
  menupainel,
  menuseguro,
  menuusuario,
  midia,
  pagamentog,
  pdf,
  pin,
  prev,
  refreshm,
  refreshp,
  residencia,
  sair,
  salvar,
  setabaixo,
  setabaixop,
  setacima,
  setacimap,
  setadireita,
  setadireitap,
  setaesquerda,
  setaesquerdap,
  telefonar,
  telefone,
  tempom,
  tempop,
  timeline,
  triste,
  twitter,
  upload,
  vida,
  visualizarp,
  word,
  youtube,
];
const CsIcon = ({ icon, className }) => {
  return <em className={`cs-icon icon-${icon} ${className}`} />;
};

CsIcon.propTypes = {
  icon: PropTypes.oneOf(iconNames).isRequired,
};

export default CsIcon;
