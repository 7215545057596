import React from "react";
import PropTypes from "prop-types";
import PainelResultadoAssembleiaHeader from "./PainelResultadoAssembleiaHeader";
import CotasPesquisadas from "./CotasPesquisadas";
import CotasContempladas from "./CotasContempladas";
import NumerosLoteriaFederal from "./NumerosLoteriaFederal";
import Apresentacao from "../blocosCustomizados/Apresentacao";
import images from "../../asserts/images";
import ResultadoAssembleiaContext from "./ResultadoAssembleiaContext";

const PainelResultadoAssembleia = ({
  resultado,
  cotasPesquisadas,
  showError,
}) => {
  if (showError) {
    return (
      <Apresentacao
        title="Não foi possível finalizar a consulta"
        image={images.ilustracaoAtencao}
        titleAs="h6"
      >
        <p className="text-danger">
          O grupo pesquisado não existe ou nossos sistemas estão fora do ar no
          momento!
        </p>
      </Apresentacao>
    );
  }

  if (!resultado) {
    return null;
  }

  const {
    resultadoAssembleia: {
      cotasAtivasConfirmada,
      cotasCotaCanceladas,
      valorMinimoLanceLivre: lanceMin,
      valorMaximoLanceLivre: lanceMax,
    },
  } = resultado;

  const cotasAtivas = cotasAtivasConfirmada
    .map((x) =>
      x.cotas.map(({ codCota, nomModalidade }) => ({
        codCota,
        nomModalidade,
        status: "ativa",
      }))
    )
    .reduce((a, b) => a.concat(b), []);

  const cotasCanceladas = cotasCotaCanceladas
    .map((x) =>
      x.cotas.map(({ codCota, nomModalidade }) => ({
        codCota,
        nomModalidade,
        status: "cancelada",
      }))
    )
    .reduce((a, b) => a.concat(b), []);

  return (
    <ResultadoAssembleiaContext.Provider
      value={{
        lanceLivre: {
          lanceMin: Number.parseFloat(lanceMin) / 100,
          lanceMax: Number.parseFloat(lanceMax) / 100,
        },
      }}
    >
      <PainelResultadoAssembleiaHeader
        tipoConsorcio={resultado.grupo.nomProduto}
        dataAssembleia={resultado.assembleia.data_assembleia}
        codGrupo={resultado.grupo.codGrupo}
        prazo={resultado.grupo.numPrazoGrupo}
      />
      <CotasPesquisadas
        cotasPesquisadas={cotasPesquisadas.filter((x) => x)}
        cotasSorteadas={cotasAtivas}
      />
      <NumerosLoteriaFederal premioLoteria={resultado.premioLoteria} />
      <CotasContempladas cotas={cotasAtivas} title="Cotas ativas" />
      <CotasContempladas cotas={cotasCanceladas} title="Cotas canceladas" />
    </ResultadoAssembleiaContext.Provider>
  );
};

PainelResultadoAssembleia.defaultProps = {
  resultado: null,
  showError: null,
  cotasPesquisadas: [],
};

PainelResultadoAssembleia.propTypes = {
  resultado: PropTypes.objectOf(Object),
  showError: PropTypes.objectOf(Object),
  cotasPesquisadas: PropTypes.arrayOf(PropTypes.string),
};

export default PainelResultadoAssembleia;
