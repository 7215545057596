import React from "react";
import PropTypes from "prop-types";
import { useAnalytcs, useMakeClass } from "../../../hooks";
import Action, { actionPropTypes } from "./Action";

const AnalitycsAction = React.forwardRef((props, ref) => {
  const { className, noAnalitycs, analitycsValue: label, active } = props;

  const customProps = { ...props };

  const analitycsProps = {
    ...useAnalytcs({ label }),
    className: useMakeClass(
      className,
      "make-click",
      !noAnalitycs && !label ? "" : "make-label"
    ),
    ...(active ? { active: "active" } : {}),
  };
  return <Action ref={ref} {...{ ...customProps, ...analitycsProps }} />;
});

AnalitycsAction.defaultProps = {
  noAnalitycs: false,
  analitycsValue: null,
};

AnalitycsAction.propTypes = {
  ...actionPropTypes,
  noAnalitycs: PropTypes.bool,
  analitycsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default AnalitycsAction;
