import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { envs } from "../../../config";

const ReCaptchaControl = ({ siteKey, size, setFieldValue, name, ...props }) => {
  const recaptchaRef = React.createRef();

  function changeHandle(value) {
    setFieldValue(name, value);
  }

  function expiredHandle(widgetId) {
    recaptchaRef.current.reset(widgetId);
  }

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size={size}
      sitekey={siteKey}
      onExpired={expiredHandle}
      badge="bottomright"
      {...props}
      onChange={changeHandle}
    />
  );
};

ReCaptchaControl.defaultProps = {
  siteKey: envs.reCaptchaSiteKey,
  size: "invisible",
  name: "reCaptcha",
};
ReCaptchaControl.propTypes = {
  siteKey: PropTypes.string,
  size: PropTypes.oneOf(["invisible", "compact", "normal"]),
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default ReCaptchaControl;
