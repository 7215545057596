import React from "react";
import PropTypes from "prop-types";
import { Modal as BsModal } from "react-bootstrap";

const Modal = ({
  title,
  show,
  handleClose,
  closeButton,
  children,
  footer,
  className,
  titleAs,
  size,
}) => {
  return (
    <BsModal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={`cc-modal ${className || ""}`}
      centered
      size={size}
    >
      <BsModal.Header closeButton={closeButton}>
        <BsModal.Title as={titleAs}>{title}</BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>{children}</BsModal.Body>
      {footer ? <BsModal.Footer>{footer}</BsModal.Footer> : null}
    </BsModal>
  );
};

Modal.defaultProps = {
  show: false,
  closeButton: true,
  footer: null,
  titleAs: "h4",
  size: "lg",
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
  footer: PropTypes.element,
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
};

export default Modal;
