import styled from "styled-components";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

export const Spinner = styled(BootstrapSpinner)`
  width: 50px;
  height: 50px;
  font-size: 1rem;
`;
