import { css } from "styled-components";

export const breakpoints = {
  tablet: 960,
  desktop: 1080,
  mobile: 550,
};

export const slickBreakpoints = {
  iphone5: 350,
  mobile: 550,
  tablet: 960,
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
