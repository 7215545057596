import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { MONTHS } from "../../constants";
import { mapToOptions } from "../../utils";
import { InputControl, SelectControl } from "../shared/forms";
import { Button } from "../shared/actions";
import InputCotaControl from "./InputCotaControl";

const ResultadoAssembleiaForm = ({
  handleChange,
  handleBlur,
  values: { mes, grupo, cotas },
  setFieldValue,
  showForm,
}) => {
  const formikBag = useFormikContext();

  const [mesesOptions, setMesesOptions] = useState([]);

  useEffect(() => {
    const mesCorrente = new Date().getMonth();

    const options = MONTHS.filter((m, index) => m && index <= mesCorrente)
      .map((m, index) => ({
        label: m,
        value: index + 1, // soma 1 para atender a parâmetrização de API
      }))
      .reverse();
    setMesesOptions(options);
  }, [setMesesOptions]);

  function getPaddingValue(str) {
    const value = Number.parseInt(str?.replace(/\D/, ""), 10);

    return value ? value.toString().padStart(6, "0").substr(0, 6) : "";
  }

  function paddingHandleChange(evt) {
    setFieldValue(
      evt.currentTarget.name,
      getPaddingValue(evt.currentTarget.value)
    );
  }

  const adicionarCotaHandle = () => {
    if (!(cotas.length === 10 || cotas.filter((c) => !c).length > 0)) {
      setFieldValue("cotas", cotas.concat(""));
    }
  };

  function cotaHandleChange(evt, posicao) {
    const cotasAtualizadas = cotas.map((c, index) =>
      index === posicao ? getPaddingValue(evt.currentTarget.value) : c
    );
    setFieldValue("cotas", cotasAtualizadas);
  }

  function removeInpuCotaHandle(posicao) {
    const cotasAtualizadas = cotas.filter((...[, index]) => index !== posicao);
    setFieldValue("cotas", cotasAtualizadas);
  }
  const fontFamily = "CAIXA Std";
  return showForm ? (
    <>
      <p className="text-center">
        Confira os resultados das contemplações e as datas das assembleias do
        seu consórcio. Para saber se hoje é seu dia de sorte, preencha as
        informações abaixo. O resultado da assembleia sai sempre às 15h.
      </p>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 4, offset: 4 }}>
            <SelectControl
              inputStyle={{ fontFamily }}
              size="lg"
              controlId="resultado-assembleias.mes"
              placeHolder="Selecione o mês"
              name="mes"
              value={mes}
              {...formikBag}
              options={mapToOptions(mesesOptions)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col xs={12} md={{ span: 4, offset: 4 }}>
            <InputControl
              inputStyle={{ fontFamily }}
              size="lg"
              controlId="resultado-assembleias.grupo"
              placeHolder="Nº do grupo"
              name="grupo"
              value={grupo}
              {...formikBag}
              onChange={paddingHandleChange}
              onBlur={handleBlur}
            />
          </Col>
          {" "
            .padEnd(10, ".")
            .split(".")
            .map((...[, idx]) => (
              <InputCotaControl
                inputStyle={{ fontFamily }}
                size="lg"
                formikBag={formikBag}
                cotas={cotas}
                position={idx}
                cotaHandleChange={cotaHandleChange}
                removeControlHandle={removeInpuCotaHandle}
              />
            ))}
          <Col xs={12} className="d-flex justify-content-center">
            <Button
              custom
              variant="link"
              label="+ Adicionar cota desse grupo"
              onClick={adicionarCotaHandle}
            />
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <Button
              secondary
              label="Consultar"
              type="submit"
              disabled={
                !(
                  formikBag.dirty &&
                  formikBag.isValid &&
                  !formikBag.isSubmitting
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <Row>
      <Col xs={12} className="d-flex justify-content-center">
        <Button
          outline
          disabled={showForm}
          label="Nova consulta"
          type="reset"
          className="text-center"
        />
      </Col>
    </Row>
  );
};

ResultadoAssembleiaForm.defaultProps = {
  showForm: false,
};

ResultadoAssembleiaForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape({
    mes: PropTypes.string,
    grupo: PropTypes.string,
    cotas: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
};

export default ResultadoAssembleiaForm;
