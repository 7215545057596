import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const CepInput = ({ cep, formikBag, ...props }) => (
  <InputControl
    style={{ maxWidth: "400px" }}
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.cep"
    label="Qual o seu CEP?"
    placeHolder="Digite um CEP"
    name="ouvidoria.contato.cep"
    value={cep}
    {...formikBag}
    {...props}
  />
);

CepInput.propTypes = {
  cep: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default CepInput;
