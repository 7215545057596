/* eslint-disable max-len */
import React from 'react';

function HouseKey() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='150'
      height='150'
      fill='none'
      viewBox='0 0 150 150'
    >
      <g
        stroke='#FFAA59'
        strokeLinecap='round'
        strokeLinejoin='round'
        clipPath='url(#clip0_4001_22)'
      >
        <path
          strokeWidth='3.97'
          d='M65.49 37.956c2.69-3.19 6.56-5.38 11.03-5.86 9.19-.98 17.44 5.68 18.42 14.87.25 2.3.01 4.54-.62 6.62l2.76 1.63 1.05-2.82 8.8 3.68 2.01 6.09 6.54-2.16 1.82 5.5 5.65-1.87 2.8 8.47-8.76 2.9-20.46-10.54s-6.55-3.6-6.87-3.46c-.53.49-1.13.94-1.77 1.34m-17.14 8.03c-9.15-1.32-15.51-9.8-14.19-18.95 1.32-9.15 9.8-15.5 18.95-14.19 9.15 1.31 15.51 9.8 14.19 18.95a16.73 16.73 0 01-2.23 6.26l2.27 2.26 1.71-2.47 7.62 5.74.45 6.4 6.87-.48.41 5.77 5.93-.42.63 8.9-9.21.65-17.23-15.25s-5.46-5.1-5.81-5.04c-3.97 2.17-10.36 1.88-10.36 1.88v-.01zM58.1 114.226l1.89-9.94c.06-.33.4-.54.76-.47l5.64 1.07c.36.07.6.39.54.72l-1.89 9.94m3.67-21.19a3.708 3.708 0 01-4.34 2.95 3.715 3.715 0 01-2.95-4.34 3.715 3.715 0 014.34-2.95c2.01.38 3.33 2.33 2.95 4.34z'
        />
        <path
          strokeWidth='3.97'
          d='M30.42 107.766l3.81-20.06a3.571 3.571 0 012.85-2.84l19.22-3.54m17.66 35.19l4.6-24.22c.27-1.44-.37-2.91-1.62-3.69l-16.58-10.34M54.47 75.576l-25.81 5.76m33.15-8.75l27.58 17.93M25 107.936l55.48 10.54'
        />
        <path
          strokeWidth='3.97'
          d='M40.34 96.04l1.13-5.93 9.14 1.73-1.01 5.33'
        />
        <path
          strokeWidth='3'
          d='M60.37 78.26c-1.15-.72-2.65-.07-2.93 1.25l-4.12 20.01-16.27-3.09m20-46.77c-7.45 2.24-11.72 9.9-9.55 17.11.73 2.41 2.09 4.47 3.85 6.05 1.01.9 2.14 1.65 3.37 2.22 0 0 3.36 2.07 7.27.84m8.77-24.64c1.07.83 2.01 1.81 2.8 2.91 1.37 1.93 2.24 4.24 2.42 6.75.53 7.51-5.33 14.03-13.09 14.58M78.74 53.4c.22 3.1-2.12 5.8-5.22 6.01a5.64 5.64 0 01-6.02-5.22c-.22-3.1 2.12-5.8 5.22-6.01a5.63 5.63 0 016.01 5.22h.01z'
        />
      </g>
      <defs>
        <clipPath id='clip0_4001_22'>
          <path
            fill='#fff'
            d='M0 0H104.7V90.45H0z'
            transform='translate(23 30)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HouseKey;
