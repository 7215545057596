import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import { applyKey } from "../../../../utils";

const DropDownSubMenu = (props) => {
  const { show, onToggleClick, label, children, className } = props;

  const [menuItems, setMenuItems] = useState(null);

  function toggleHandle(evt) {
    if (onToggleClick) {
      onToggleClick(evt);
    }
  }

  useEffect(() => {
    const subMenuItems = React.Children.toArray(children)
      .filter((x) => React.isValidElement(x))
      .map(({ props: elProps }) => elProps);

    setMenuItems(menuItems || subMenuItems);
    return () => {
      setMenuItems([]);
    };
  }, [children, menuItems]);

  return (
    <Dropdown className={className} show={show} as={NavItem}>
      <Dropdown.Toggle
        as={NavLink}
        className="mr-md-3 mr-xl-5"
        onClick={toggleHandle}
      >
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="d-md-none">
        {applyKey(menuItems, (item) =>
          React.createElement(Dropdown.Item, { ...item, onClick: toggleHandle })
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropDownSubMenu.Item = Dropdown.Item;

DropDownSubMenu.defaultProps = {
  show: false,
  onToggleClick: null,
};
DropDownSubMenu.propTypes = {
  show: PropTypes.bool,
  onToggleClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default DropDownSubMenu;
