import React from "react";
import { LINKS } from "../../../constants";
import { Button } from "../actions";

const FaqButtonAreaCliente = () => {
  return (
    <div className="faq-fotter-buttons d-block d-lg-flex justify-content-center small-button">
      <Button
        href={LINKS.areaLogada}
        blank
        className="mr-3 d-block d-sm-inline-block"
        label="Acessar área do cliente"
      />
    </div>
  );
};

export default FaqButtonAreaCliente;
