import React from "react";
import animatedScroll from "animated-scroll-to";
import PropTypes from "prop-types";
import { Accordion, Card } from "react-bootstrap";
import { VARIANT_COLORS } from "../../../constants";
import CardToggleButton from "./CardToggleButton";

const AccordionCard = ({
  label,
  eventKey,
  children,
  iconShow,
  iconHide,
  variant,
  verticalOffset,
  analitycsValue,
}) => {
  const ref = React.createRef();

  const expandedHandle = (element) =>
    animatedScroll(element, {
      verticalOffset,
    });

  return (
    <Card ref={ref} title={label} scro>
      <Card.Header>
        <CardToggleButton
          label={label}
          iconShow={iconShow}
          iconHide={iconHide}
          eventKey={eventKey}
          variant={variant}
          analitycsValue={analitycsValue}
        />
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey} onEntered={expandedHandle}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

AccordionCard.defaultProps = {
  variant: "primary",
  verticalOffset: -165,
  analitycsValue: null,
};
AccordionCard.propTypes = {
  label: PropTypes.string.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  iconShow: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  iconHide: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  variant: PropTypes.oneOf([...VARIANT_COLORS, "seguridade-light"]),
  verticalOffset: PropTypes.number,
  analitycsValue: PropTypes.string,
};

export default AccordionCard;
