import React from "react";
import PropTypes from "prop-types";
import { distinctArray } from "../../utils";
import { Button } from "../shared/actions";
import Apresentacao from "../blocosCustomizados/Apresentacao";
import images from "../../asserts/images/consorcios";
import { createImageElement } from "../../asserts/images";

const getTitleResult = (cotasEncontradas) => {
  const modalidadesSorteadas = distinctArray(
    cotasEncontradas.map(({ nomModalidade }) => nomModalidade)
  );

  if (!modalidadesSorteadas.length) {
    return "A sua cota ainda não foi contemplada :(";
  }

  const plural = cotasEncontradas.length !== 1 ? "s" : "";

  const modalidadeSorteada =
    modalidadesSorteadas.length === 1 ? `por ${modalidadesSorteadas[0]}` : "";

  return `Parabéns! A${plural} sua${plural} cota${plural} fo${
    plural ? "ram" : "i"
  } contemplada${plural} ${modalidadeSorteada.toLocaleLowerCase()} ;)`;
};

const CotasPesquisadas = ({ cotasPesquisadas, cotasSorteadas }) => {
  if (!cotasPesquisadas || !cotasPesquisadas.length) {
    return null;
  }

  const matchCotas = cotasSorteadas.filter((c) =>
    cotasPesquisadas.find(
      (p) =>
        Number.parseInt(c.codCota.split("-")[0], 10) === Number.parseInt(p, 10)
    )
  );

  const title = getTitleResult(matchCotas);
  const image = matchCotas.length
    ? images.cotasContempladasEncontrada
    : images.cotasContempladasNaoEncontrada;

  const plural = matchCotas.length && matchCotas.length > 1 ? "s" : "";
  const content = matchCotas.length ? (
    <div style={{ marginTop: "1rem" }}>
      <strong className="text-brand-secondary text-body-md">{`Cota${plural}: ${matchCotas
        .map(({ codCota }) => codCota.split("-")[0].padStart(6, "0"))
        .join(", ")}`}</strong>
      <p className="mt-3">
        Para utilizar o crédito, leia o processo de pós-contemplação e siga os
        procedimentos disponíveis nas cartilhas com orientações ao consorciado
        contemplado de acordo com a modalidade.
      </p>
      <p>
        <Button href="/consorcio/contemplacao" label="Como usar meu crédito" />
      </p>
    </div>
  ) : (
    <p>
      Continue torcendo e mantendo as mensalidades em dia!
      <br />
      Quem sabe na próxima assembleia?
    </p>
  );

  return cotasPesquisadas.length ? (
    <Apresentacao
      image={image}
      title={title}
      titleAs="h3"
      titleOptions={{
        className: matchCotas.length ? "text-brand-secondary" : null,
      }}
    >
      <div className="text-center text-lg-left w-100">
        <p className="d-lg-none">
          {createImageElement(image, { className: "d-lg-none" })}
        </p>
        {content}
      </div>
    </Apresentacao>
  ) : null;
};

CotasPesquisadas.defaultProps = {
  cotasPesquisadas: null,
};

CotasPesquisadas.propTypes = {
  cotasPesquisadas: PropTypes.arrayOf(PropTypes.string),
  cotasSorteadas: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CotasPesquisadas;
