import iconPhonebook from "./icon-phonebook.svg";
import iconHeart from "./icon-heart.svg";
import iconHome from "./icon-home.svg";
import calendarIcon from "./calendar.svg";
import chatFloatIcon from "./chat-float-icon.svg";

export default {
  iconHeart,
  iconPhonebook,
  iconHome,
  calendarIcon,
  chatFloatIcon,
};
