import React from "react";
import { Container, Title, Description, ExclamacaoIcon } from "./styles";

const OuvidoriaPending = () => {
  return (
    <Container>
      <ExclamacaoIcon />
      <Title variant="headline-06" align="center">
        Não foi possível abrir sua ocorrência para a Ouvidoria.
      </Title>
      <Description variant="body01-lg" align="center">
        O seu protocolo do atendimento anterior ainda está no prazo de análise.
        <br />
        Por favor, aguarde o nosso retorno.
      </Description>
    </Container>
  );
};

export default OuvidoriaPending;
