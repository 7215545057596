import React from "react";
import PropTypes from "prop-types";
import { Button } from "../shared/actions";
import "./style.css";

const CookiesNotification = ({ onAccept, onCustomize }) => {
  return (
    <div className="cookies-banner">
      <div className="cookies-notification">
        <span className="titulo">Cookies</span>
        <div className="container-flex">
          <p className="description">
            Cookies são pedaços de informações coletadas diretamente no momento
            da sua navegação com a gente. Salvamos seu histórico de uso e você
            pode personalizar quais informações iremos coletar. Isto serve pra
            entender o seu comportamento e experiência com a gente. Saiba mais
            em{" "}
            <a
              className="link"
              href="https://portaldaprivacidade.caixaconsorcio.com.br"
            >
              Política de Cookies e Privacidade
            </a>
          </p>
          <div className="container-buttons">
            <Button
              className="botao-aceito"
              onClick={() => onAccept(true, true)}
            >
              Aceito
            </Button>
            <Button
              className="botao-personalizar"
              outline
              onClick={onCustomize}
            >
              Personalizar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

CookiesNotification.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCustomize: PropTypes.func.isRequired,
};

export default CookiesNotification;
