import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";
import TabPane from "./TabPane";
import NavPageTab from "./NavPageTab";
import BreadcrumbSeguridade from "./BreadcrumbSeguridade";
import { useBreadCrumb, useParams } from "../../../../../hooks";
import { TabRouteContext } from "../../../../../context";
import { envs } from "../../../../../config";

const defaultCrumb = [
  {
    label: "Página Inicial",
    href: envs.homePortal,
  },
];

const TabsContent = ({ children, setNavConfig }) => {
  const [tabsProps, setTabsProps] = useState(null);
  useEffect(() => {
    if (setTabsProps) {
      const navConfig = React.Children.toArray(children)
        .filter((el) => React.isValidElement(el))
        .map(({ props }) => props);

      setTabsProps(tabsProps || navConfig);
    }

    return () => {
      setTabsProps(null);
    };
  }, [children, setTabsProps, tabsProps]);

  useEffect(() => {
    if (tabsProps) {
      setNavConfig(tabsProps);
    }

    return () => {
      setNavConfig(null);
    };
  }, [setNavConfig, tabsProps]);

  return <Tab.Content>{children}</Tab.Content>;
};

TabsContent.propTypes = {
  setNavConfig: PropTypes.func.isRequired,
};

const SeguridadePageLayout = (props) => {
  const {
    children,
    pageTitle,
    setGroupBanner,
    updateCrumb,
    disabledCrumb,
  } = props;
  const [crumbs, includeCrumb] = useBreadCrumb(defaultCrumb);
  const [key, setKey] = useState(null);
  const [tabVisibleKey, setTabVisibleKey] = useState(null);
  const [tabItems, setTabItems] = useState([]);
  const [navsItems, setNavsItems] = useState([]);

  const { showContent } = useParams();
  useEffect(() => {
    const items = React.Children.map(
      children,
      ({ props: { label, eventKey } }) => ({ label, eventKey })
    );
    setTabItems(items);

    setKey(showContent || items[0]?.eventKey);
    setTabVisibleKey(items[0]?.eventKey);
  }, []);

  useEffect(() => {
    if (Array.isArray(tabItems) && tabItems.length) {
      setNavsItems(tabItems.filter((x) => x.label));
    }

    return () => {
      setNavsItems([]);
    };
  }, [tabItems, setNavsItems]);

  useEffect(() => {
    setGroupBanner(key);
    return () => {
      setGroupBanner(null);
    };
  }, [key, setGroupBanner]);
  useEffect(() => {
    includeCrumb({ label: pageTitle }, updateCrumb);
  }, [pageTitle]);

  function changeTabHandle({ key: changeKey, navKey }) {
    setKey(changeKey);
    setTabVisibleKey(navKey || changeKey);
  }

  return (
    <TabRouteContext.Provider
      value={{
        setKey: changeTabHandle,
        contentKey: key,
        navKey: tabVisibleKey,
      }}
    >
      {!disabledCrumb ? <BreadcrumbSeguridade crumbs={crumbs.items} /> : null}
      <Tab.Container id="cvp-tab-container" activeKey={key}>
        <NavPageTab tabs={navsItems} />
        <Tab.Content className="pt-4">{children}</Tab.Content>
      </Tab.Container>
    </TabRouteContext.Provider>
  );
};

SeguridadePageLayout.Tab = TabPane;

SeguridadePageLayout.defaultProps = {
  updateCrumb: null,
  disabledCrumb: false,
};
SeguridadePageLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  setGroupBanner: PropTypes.func.isRequired,
  updateCrumb: PropTypes.objectOf(Object),
  disabledCrumb: PropTypes.bool,
};

export default SeguridadePageLayout;
