import React from "react";
import PropTypes from "prop-types";

export const Main = ({ children }) => {
  return (
    <>
      <main className="main-page">{children}</main>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Main;
