import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { parseISO } from "../../utils";

const PainelResultadoAssembleiaHeader = ({
  tipoConsorcio,
  dataAssembleia,
  codGrupo,
  prazo,
}) => {
  const colWidth = tipoConsorcio ? 3 : 4;

  return (
    <Row className="pb-4 mb-2 text-center">
      <Col xs={12} className="d-flex justify-content-center pb-4 mb-4">
        Confira o resultado para a data, grupo e cota informados.
      </Col>
      {tipoConsorcio ? (
        <Col xs="12" md={colWidth}>
          Consórcio{" "}
          <strong>{_.upperFirst(tipoConsorcio.toLocaleLowerCase())}</strong>
        </Col>
      ) : null}
      <Col xs="12" md={colWidth}>
        Data da Assembleia:{" "}
        <strong>{parseISO(dataAssembleia).toLocaleDateString()}</strong>
      </Col>
      <Col xs="6" md={colWidth}>
        Grupo: <strong>{codGrupo}</strong>
      </Col>
      <Col xs="6" md={colWidth}>
        Prazo: <strong>{prazo} meses</strong>
      </Col>
    </Row>
  );
};

PainelResultadoAssembleiaHeader.propTypes = {
  tipoConsorcio: PropTypes.string.isRequired,
  dataAssembleia: PropTypes.string.isRequired,
  codGrupo: PropTypes.string.isRequired,
  prazo: PropTypes.string.isRequired,
};

export default PainelResultadoAssembleiaHeader;
