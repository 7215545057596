import styled from "styled-components";

export const Box = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",

  [theme.breakpoint.lg()]: {},
}));

export const ButtonLabel = styled.label(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "240px",
  height: "48px",
  border: "none",
  background: "#005fc8",
  color: "#fff",
  cursor: "pointer",
  marginTop: "12px",
  [theme.breakpoint.lg()]: {
    marginBottom: 0,
  },
}));

export const SpanStyled = styled.span(({ theme }) => ({
  color: "#007BFF",
  fontSize: "18px",
  cursor: "pointer",
  [theme.breakpoint.lg()]: {},
}));

export const DeleteFile = styled.span(({ theme }) => ({
  color: "#007BFF",
  fontSize: "24px",
  padding: "0 12px",
  cursor: "pointer",
  [theme.breakpoint.lg()]: {},
}));

export const LabInputFile = styled.label((props) => ({
  width: "97%",
  border: "none",
  borderRadius: "8px",
  padding: "25px 10px",
  alignItems: "center",
  backgroundColor: props.bg,
  cursor: "pointer",
  p: {
    color: "#999",
    margin: "0",
    fontWeight: "600",
  },
  span: {
    color: "var(--color-feedback-info-light) !important",
  },
}));

export const Inputdiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "47%",
  margin: "15px auto 15px 10px",
  p: {
    color: "red",
  },
  "@media (max-width: 768px)": {
    width: "95%",
  },
}));

export const InputFdiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "15px auto",
  p: {
    color: "red",
  },
}));
