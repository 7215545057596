import React from "react";
import PropTypes from "prop-types";

const FiqueAtento = ({ sectionTitle }) => {
  return (
    <>
      <h3 className="text-center mb-3">{sectionTitle}</h3>
      <p>Mantendo o compromisso de transparência com você, informamos que:</p>
      <p>
        - Não comercializamos cotas contempladas de consórcios, assim como não
        garantimos a data de contemplação;
        <br />- E, se você ocupa cargo como por exemplo: presidente, diretor,
        conselheiro, entre outros, de empresas da Caixa Consórcio ou coligadas,
        só poderá ser contemplado no Consórcio após todos os demais
        participantes do grupo, conforme art.15 da lei 11.795.
      </p>
    </>
  );
};

FiqueAtento.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

export default FiqueAtento;
