import React from "react";
import { Benefits } from "@components/blocosCustomizados";
import { Section } from "@components/shared/pages";

export const ConhecaAsVantagens = ({ images, sectionTitle }) => {
  const conhecaVantagens = [
    {
      image: images.semJurosConsorcio,
      title: "Sem juros",
      description:
        "Escolha a oferta ideal para você sem pagar juros. Pague somente a taxa de administração, que é diluída no prazo contratado.",
      col: { lg: 4 },
    },
    // {
    //   image: images.useSeuFgtsConsorcio,
    //   title: "Use seu FGTS",
    //   description:
    //     "No Consórcio Imobiliário, você pode usar o FGTS para complementar o valor do imóvel, pagar parte das parcelas ou quitar o saldo devedor.  ",
    // },
    {
      image: images.reduzaTaxaConsorcio,
      title: "Reduza taxas e impostos",
      description:
        "Você pode usar até 10% do valor do crédito para pagar despesas referentes ao bem, como seguros, tributos e registros.",
      col: { lg: 4 },
    },
    {
      image: images.amplieRenoveSuaFrota,
      title: "Amplie e renove a sua frota",
      description:
        "Com o consórcio de veículos pesados, você pode adquirir ônibus e caminhões com até 100 meses para pagar.",
      col: { lg: 4 },
    },
  ];

  return <Benefits sectionTitle={sectionTitle} items={conhecaVantagens} />;
};

ConhecaAsVantagens.propTypes = {
  ...Section.specializedProps,
};
export default ConhecaAsVantagens;
