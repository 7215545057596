import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { ReCaptchaControl } from "../../../../components/shared/forms";
import { Button } from "../../../../components/shared/actions";
import {
  ContactDataFormTitle,
  FormRowAddress1,
  FormRowAddress2,
  FormRowNameAndCpf,
  FormRowPhoneAndEmail,
  WarningParagraph,
  CEPContainer,
  SubmitButton,
} from "./styles";
import { cepMask, readProperty } from "../../../../utils";
import { useProtocol, useCEP } from "../../../../hooks";
import { ProtocolStatusError, OuvidoriaApiError } from "..";
import {
  AddressComplementInput,
  AddressInput,
  AddressNumberInput,
  CepInput,
  CityInput,
  CpfCnpjInput,
  DetailsInput,
  EmailInput,
  OuvidoriaFileInput,
  NameInput,
  PhoneInput,
  StateSelect,
  ProtocolInput,
  ProductSelect,
} from "./FormInputs";
import SpinnerLoading from "../../../../components/shared/layout/SpinnerLoading";

const CEP_INPUT_NAME = "ouvidoria.contato.cep";
const TAMANHO_MAXIMO_PROTOCOLO = 20;
const TAMANHO_MINIMO_PROTOCOLO = 4;

const setValuesCep = (dataCEP) => (prevState) => ({
  ...prevState,
  ouvidoria: {
    ...prevState.ouvidoria,
    contato: {
      ...prevState.ouvidoria.contato,
      endereco: dataCEP.indice_tipo_logradouro ?? "",
      estado: dataCEP.codigo_uf ?? "",
      cidade: dataCEP.nome_localidade ?? "",
    },
  },
});

const FormularioOuvidoria = ({
  isLoadingOuvidoria,
  setAnexo,
  anexo,
  values: {
    produto,
    protocolo,
    ouvidoria: {
      nome,
      cpfCnpj,
      pedido,
      contato: {
        telefone,
        email,
        cep,
        endereco,
        numero,
        complemento,
        estado,
        cidade,
      },
    },
  },
  handleBlur,
  handleChange,
  isValid,
  errors,
  dirty,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setValues,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldError,
    setValues,
  };
  const {
    errorProtocol,
    isLoadingProtocol,
    avaliableProtocol,
    checkProtocol,
  } = useProtocol();

  const showForm = useMemo(() => produto && avaliableProtocol, [
    produto,
    avaliableProtocol,
  ]);

  const { isLoadingCEP, dataCEP, consultCEP } = useCEP();

  useEffect(() => {
    setValues(setValuesCep(dataCEP));
    if (dataCEP?.codigo_retorno !== "0") {
      formikBag.setFieldError(
        CEP_INPUT_NAME,
        "Desculpe, não localizamos o CEP informado, por favor, confirme e tente novamente"
      );
    }
  }, [dataCEP]);

  const hasGeneralError = useMemo(() => errorProtocol?.status >= 500, [
    errorProtocol?.status,
  ]);

  const hasNetworkError = useMemo(
    () => errorProtocol?.message === "Network Error",
    [errorProtocol?.message]
  );

  return (
    <>
      <ProductSelect
        produto={produto}
        isLoadingProtocol={isLoadingProtocol}
        formikBag={formikBag}
      />
      {produto && (
        <>
          <ProtocolInput
            protocolo={protocolo}
            isLoadingProtocol={isLoadingProtocol}
            formikBag={formikBag}
            maxLength={TAMANHO_MAXIMO_PROTOCOLO}
            minLength={TAMANHO_MINIMO_PROTOCOLO}
          />
          <ProtocolStatusError
            isLoadingProtocol={isLoadingProtocol}
            errorCode={errorProtocol?.status || 0}
          />
          <Button
            onClick={() => checkProtocol(protocolo, produto)}
            disabled={
              isLoadingProtocol ||
              protocolo.length < TAMANHO_MINIMO_PROTOCOLO ||
              protocolo.length > TAMANHO_MAXIMO_PROTOCOLO
            }
          >
            Verificar protocolo
          </Button>
        </>
      )}
      <SpinnerLoading isLoading={isLoadingProtocol} />
      {hasGeneralError && <OuvidoriaApiError />}
      {hasNetworkError && <OuvidoriaApiError title="Erro de rede" />}
      {showForm && (
        <>
          <WarningParagraph>
            ATENÇÃO: Os dados pessoais solicitados aqui serão utilizados única e
            exclusivamente para a análise e devolutiva da sua ocorrência de
            ouvidoria.
          </WarningParagraph>
          <FormRowNameAndCpf>
            <NameInput nome={nome} formikBag={formikBag} />
            <CpfCnpjInput cpfCnpj={cpfCnpj} formikBag={formikBag} />
          </FormRowNameAndCpf>
          <DetailsInput pedido={pedido} formikBag={formikBag} />
          <OuvidoriaFileInput
            setAnexo={setAnexo}
            name="ouvidoria.anexo"
            anexo={anexo}
          />
          <ContactDataFormTitle>
            Para resolver a sua solicitação, é importante preencher todos os
            dados abaixo:
          </ContactDataFormTitle>
          <FormRowPhoneAndEmail>
            <PhoneInput telefone={telefone} formikBag={formikBag} />
            <EmailInput email={email} formikBag={formikBag} />
          </FormRowPhoneAndEmail>
          <CEPContainer>
            <CepInput
              cep={cep}
              disabled={isLoadingCEP}
              onChange={(e) => {
                e.target.value = cepMask(e.target.value);
                formikBag.onChange(e);
              }}
              onBlur={(e) => {
                formikBag.onBlur(e);
                if (!readProperty(formikBag.errors, CEP_INPUT_NAME)) {
                  consultCEP(cep.replace("-", ""));
                }
              }}
              formikBag={formikBag}
            />
            <SpinnerLoading isLoading={isLoadingCEP} />
          </CEPContainer>
          <FormRowAddress1>
            <AddressInput
              endereco={endereco}
              disabled={dataCEP?.indice_tipo_logradouro}
              formikBag={formikBag}
            />
            <AddressNumberInput numero={numero} formikBag={formikBag} />
          </FormRowAddress1>
          <FormRowAddress2>
            <AddressComplementInput
              complemento={complemento}
              formikBag={formikBag}
            />
            <StateSelect
              estado={estado}
              disabled={dataCEP?.codigo_uf}
              formikBag={formikBag}
            />
            <CityInput
              cidade={cidade}
              disabled={dataCEP?.nome_localidade}
              formikBag={formikBag}
            />
          </FormRowAddress2>
          <ReCaptchaControl size="normal" {...formikBag} />
          <SubmitButton
            isLoading={isLoadingOuvidoria}
            label="Abrir ocorrência"
            {...formikBag}
          />
          <SpinnerLoading isLoading={isLoadingOuvidoria} />
        </>
      )}
    </>
  );
};

FormularioOuvidoria.defaultProps = {
  errorOuvidoria: null,
};

FormularioOuvidoria.propTypes = {
  values: PropTypes.objectOf(Object).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(Array).isRequired,
  dirty: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoadingOuvidoria: PropTypes.bool.isRequired,
  errorOuvidoria: PropTypes.objectOf(Object),
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setAnexo: PropTypes.func.isRequired,
  anexo: PropTypes.objectOf(Object).isRequired,
};

export default FormularioOuvidoria;
