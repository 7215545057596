import React from "react";
import images404 from "@asserts/images/page404";
import Info from "../sections/Info";
import { Page, Section } from "../../../components/shared/pages";

const HomePage = (props) => {
  return (
    <Page {...props} images={images404} disabledCrumb>
      <Section
        as={Info}
        title="Ops! Não conseguimos encontrar essa página"
        sectionName="nao-conseguimos-encontrar-essa-pagina"
        sectionLayout={null}
        fluid
        className="custom-section"
      />
    </Page>
  );
};

export default HomePage;
