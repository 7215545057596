import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "../../../pages";

const BreadcrumbSeguridade = ({ crumbs }) => {
  if (!Array.isArray(crumbs) && crumbs.length > 1) {
    return null;
  }
  return (
    <Breadcrumb
      listProps={{ className: "background-transparent" }}
      className="cvp-breadcrumb mt-3"
      label="cvp-breadcrumb-page"
      crumbs={crumbs}
    />
  );
};

BreadcrumbSeguridade.defaultProps = {
  crumbs: [],
};
BreadcrumbSeguridade.propTypes = {
  crumbs: PropTypes.arrayOf(Object),
};

export default BreadcrumbSeguridade;
