import React from "react";
import PropTypes from "prop-types";
import { SelectControl } from "../../../../../components/shared/forms";
import { mapToOptions } from "../../../../../utils";
import { FEDERAL_STATES } from "../../../../../constants";

const StateSelect = ({ estado, formikBag, ...props }) => (
  <SelectControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.estado"
    label="Estado"
    placeHolder="Escolha o estado"
    name="ouvidoria.contato.estado"
    value={estado}
    options={mapToOptions(FEDERAL_STATES)}
    {...formikBag}
    {...props}
  />
);

StateSelect.propTypes = {
  estado: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default StateSelect;
