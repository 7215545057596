import React from "react";
import PropTypes from "prop-types";
import Section from "../pages/section";

const CenterImageContent = ({ image }) => {
  return (
    <Section.Content className="center-image d-flex align-itens-center justify-content-center">
      {image}
    </Section.Content>
  );
};

CenterImageContent.propTypes = {
  image: PropTypes.element.isRequired,
};

export default CenterImageContent;
