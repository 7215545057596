import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { readProperty } from "../../../utils";

const FormControl = ({
  controlId,
  label,
  type,
  placeHolder,
  name,
  value,
  options,
  multiple,
  disabled,
  as,
  children,
  rows,
  errors,
  touched,
  onChange,
  onBlur,
  onClick,
  inputStyle,
  size,
}) => {
  const isTouched = !!readProperty(touched, name);
  const isInvalid = !!readProperty(errors, name);
  return (
    <Form.Group controlId={controlId} className="cvp-form-control">
      <Form.Label className="text-left d-block">{label}</Form.Label>
      <Form.Control
        size={size}
        style={inputStyle}
        as={as}
        name={name}
        type={type}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        rows={rows}
        isInvalid={isTouched && isInvalid}
      >
        {options || children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FormControl.defaultProps = {
  type: "text",
  placeHolder: "",
  value: "",
  options: null,
  multiple: false,
  disabled: false,
  as: "input",
  onChange: null,
  onBlur: null,
  onClick: null,
  rows: null,
  errors: null,
  touched: null,
  inputStyle: null,
  size: "",
};

FormControl.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.element),
  multiple: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.objectOf(Object),
  touched: PropTypes.objectOf(Object),
  inputStyle: PropTypes.objectOf(Object),
  size: PropTypes.string,
};

export default FormControl;
