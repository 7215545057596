import Axios from "axios";
import { useCallback, useEffect, useState } from "react";

const useStaticRequest = (endPoint, initialValue) => {
  const [response, setResponse] = useState({
    data: initialValue,
    loading: false,
    error: null,
  });

  const performRequest = useCallback(async () => {
    try {
      setResponse({
        ...response,
        loading: true,
      });

      const { data } = await Axios.get(endPoint);

      setResponse({
        data,
        loading: false,
      });

      return data;
    } catch (err) {
      setResponse({
        ...response,
        loading: false,
        error: err,
      });
    }
    return {};
  }, [endPoint]);

  useEffect(() => {
    performRequest();
  }, [endPoint]);

  return [response, performRequest];
};

export default useStaticRequest;
