import React from "react";
import PropTypes from "prop-types";
import { useMakeClass } from "../../../hooks";
import { actionPropTypes, getActionContent } from "./Action";
import AnalitycsAction from "./AnalitycsAction";

const Button = React.forwardRef((props, ref) => {
  const { icon, iconright, children, label } = props;

  const classes = useMakeClass(
    props.className,
    (props.rounded || props.pill) && "btn-rounded",
    props.transparent && "btn-transparent",
    props.phantom && "btn-phantom",
    props.pill && "btn-pill"
  );

  const variant = props.secondary ? "secondary" : props.variant;
  const iconElement = icon
    ? React.createElement(icon, {
        className: `cvp-action-icon icon-${iconright ? "right" : "left"}`,
      })
    : null;

  const content = getActionContent(children || label, iconElement, iconright);

  return (
    <AnalitycsAction ref={ref} {...props} className={classes} variant={variant}>
      {content}
    </AnalitycsAction>
  );
});

Button.defaultProps = {
  size: "lg",
  icon: null,
  iconright: false,
};

Button.propTypes = {
  ...actionPropTypes,
  icon: PropTypes.element,
  iconright: PropTypes.bool,
  size: PropTypes.string,
};

export default Button;
