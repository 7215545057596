import useStaticRequest from "./useStaticRequest";
import { endPoints } from "../../config";

const useCalendarioAssembleia = () => {
  const [{ data: calendarioAssembleia, error, loading }] = useStaticRequest(
    endPoints.getCalendarioAssembleiasEndPoint
  );

  return { calendarioAssembleia, error, loading };
};

export default useCalendarioAssembleia;
