import React from "react";
import PropTypes from "prop-types";
import { Link } from "@components/shared/actions";
import { LINKS } from "@constants";
import { Apresentacao } from "../../../components/blocosCustomizados";

const ComunicadoContemplacao = ({ images, sectionTitle }) => {
  return (
    <Apresentacao
      right
      image={images.comunicadoContemplacao}
      title={sectionTitle}
      titleRight
      titleAs="h1"
    >
      <p>
        Quando você for contemplado, nós enviaremos um comunicado de
        contemplação. <br />
        Esse comunicado será enviado <strong>apenas</strong> por e-mail e
        mensagem de celular. <br />
        Por isso, é importante que você mantenha seus dados atualizados na
        <Link blank href={LINKS.areaLogada} label="Área do Cliente" />.
      </p>
    </Apresentacao>
  );
};

ComunicadoContemplacao.propTypes = {
  images: PropTypes.objectOf(Object).isRequired,
  sectionTitle: PropTypes.string.isRequired,
};

export default ComunicadoContemplacao;
