import React from "react";
import PropTypes from "prop-types";

const Card = ({
  image,
  header,
  body,
  footer,
  children,
  className,
  headerClassName,
}) => {
  return (
    <>
      <div className={`cvp-card ${className || ""}`.trim()}>
        {image && (
          <div className="cvp-card-image d-flex justify-content-center align-items-end">
            {image}
          </div>
        )}
        {header && <div className={headerClassName}>{header}</div>}
        <div className="cvp-card-body">{body || children}</div>
        {footer && <div className="cvp-card-footer">{footer}</div>}
      </div>
    </>
  );
};

Card.defaultProps = {
  image: null,
  header: null,
  body: null,
  footer: null,
  className: null,
  headerClassName:
    "cvp-card-header d-flex justify-content-center align-items-center",
};

Card.propTypes = {
  image: PropTypes.objectOf(Object),
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
  headerClassName: PropTypes.string,
};

export default Card;
