import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { scrollToRefObject, applyKey } from "@utils";
import { useMakeClass } from "@hooks";
import { Button, Link } from "../../actions";

const CustomSubMenuToggle = ({ menuItens, className }) => {
  const [selectItem, setSelectItem] = useState("");

  useEffect(() => {
    if (menuItens && menuItens.length) {
      setSelectItem(menuItens[0].label);
    }
    return () => {
      setSelectItem("Sub Menu Página");
    };
  }, [menuItens]);

  function onClickHandle(item) {
    const { ref } = this;
    setSelectItem(item.label);
    setTimeout(() => scrollToRefObject(ref), 50);
  }

  const classes = useMakeClass(className, "nav-page-responsive-menu");

  return (
    <div className={classes}>
      <Dropdown>
        <Dropdown.Toggle as={Button} block id="dropdown-page-menu">
          {selectItem}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {applyKey(menuItens, (item) => (
            <Dropdown.Item as={Link} onClick={() => onClickHandle(item)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

CustomSubMenuToggle.defaultProps = {
  menuItens: [],
  className: null,
};

CustomSubMenuToggle.propTypes = {
  menuItens: PropTypes.arrayOf(PropTypes.objectOf(Object)),
  className: PropTypes.string,
};

export default CustomSubMenuToggle;
