import React from "react";
import PropTypes from "prop-types";
import { ContentWithCardList } from "@components/blocosCustomizados";
import { createImageElement } from "../../asserts/images";
import "./style.css";

const Information = ({ items, sectionTitle, className }) => {
  const conteudoVantagem = items?.map(
    ({ title, image, description, ...rest }) => ({
      ...rest,
      imagem: createImageElement(image, {
        alt: title,
        className,
      }),
      titleConteudo: <h5 className="titleInformation">{title}</h5>,
      conteudo: <p className="descriptionInformation">{description}</p>,
    })
  );
  return (
    <ContentWithCardList listItens={conteudoVantagem} className="cvp-benefits">
      <h3 className="content-title">{sectionTitle}</h3>
    </ContentWithCardList>
  );
};

Information.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default Information;
