import InputMask from "react-input-mask";
import styled from "styled-components";
import InputMoney from "../../InputMoney/InputMoney";

export const InputTxt = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  &:focus {
    border-color: black;
    outline: none;
  }
`;

export const InputMoneyStyled = styled(InputMoney)`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  height: auto;
  &:focus {
    border-color: black;
    outline: none;
  }
`;

export const InputMaskStyled = styled(InputMask)`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  &:focus {
    border-color: black;
    outline: none;
  }
`;

export const Inputdiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "47%",
  margin: "15px auto 15px 10px",
  p: {
    color: "red",
  },
  "@media (max-width: 768px)": {
    width: "95%",
  },
}));

export const InputFdiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "15px auto",
  p: {
    color: "red",
  },
}));

export const InputFile = styled.input`
  display: none;
`;

export const FileName = styled.div(() => ({
  display: "flex",
  p: {
    fontSize: "16px",
    color: "black",
    margin: "auto 10px",
  },
  img: {
    height: "16px",
    width: "16px",
    marginTop: "6px",
  },
}));

export const LabInputFile = styled.label((props) => ({
  width: "100%",
  border: "none",
  borderRadius: "8px",
  padding: "25px 10px",
  alignItems: "center",
  backgroundColor: props.bg,
  cursor: "pointer",
  p: {
    color: "#999",
    margin: "0",
    fontWeight: "600",
  },
  span: {
    color: "var(--color-feedback-info-light) !important",
  },
}));

export const InputRadio = styled.input`
  margin: 0 5px 0 0;
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;

  &:checked {
    background-color: var(--color-feedback-info-light);
    border-color: var(--color-feedback-info-light);
  }

  &:hover {
    border-color: var(--color-feedback-info-light);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

export const LabelRadio = styled.label`
  color: var(--color-feedback-info-light);
  font-weight: 700 !important;
  padding: 4px;
  cursor: pointer;
`;

export const DivRB = styled.div(() => ({
  display: "flex",
  margin: "10px",
}));

export const DeleteButton = styled.button(() => ({
  marginTop: "2px",
  borderRadius: "8px",
  padding: "0",
  height: "22px",
  width: "22px",
  background: "transparent",
  img: {
    margin: "0",
    height: "16px",
    width: "16px",
    objectFit: "contain",
    verticalAlign: "baseline",
  },
}));
