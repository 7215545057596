/* eslint-disable max-len */
import React from "react";

function IconLightVehicle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 400 400"
      version="1"
      viewBox="70 100 250 200"
      xmlSpace="preserve"
      fill="#035AA9"
    >
      <path d="M261.01 182.37c-4.05-.89-7.88-1.73-11.09-2.88a4.1 4.1 0 00-.77-.18c-.08-.36-.2-.71-.4-1.05-3.29-5.51-16.24-18.7-33.32-22.01-17.67-3.42-46.47-2.47-58.54.6a30.6 30.6 0 00-12.8 6.75c-.47.18-.9.44-1.26.81l-12.72 13.06c-.25.18-.55.41-.96.73-.17.14-.42.33-.65.51-.36.01-.73.01-1.1.02-6.02.08-15.13.19-16.2 8.62-.02.14-.03.28-.03.42l-.14 18.44c0 10.54 7.51 19.12 16.76 19.12l7.87-.04c.94 11.53 10.62 20.62 22.38 20.62 12.39 0 22.47-10.08 22.47-22.47 0-12.39-10.08-22.47-22.47-22.47-10.61 0-19.52 7.4-21.86 17.31l-8.41.04c-5.37 0-9.74-5.44-9.74-12.09l.14-18.15c.39-1.91 3.23-2.27 9.32-2.35.75-.01 1.49-.02 2.21-.04 1.29-.04 2.02-.6 3.77-1.96.28-.22.54-.42.7-.54.26-.15.49-.34.71-.56l9.52-9.78c2.12 3.94 5.29 8.87 8.6 10.56 7.45 3.78 23.08 5.46 46.46 4.99 25.64-.51 44.89-1.81 48.81-2.09 3.47 1.18 7.41 2.04 11.23 2.88 14.19 3.12 22.78 5.72 22.45 15.46V215.06c0 1.45-.85 2.53-1.63 2.53l-17.12.07c-2.55-9.61-11.32-16.71-21.71-16.71-10.45 0-19.26 7.18-21.76 16.86l-30.38.12a3.51 3.51 0 00-3.49 3.51 3.502 3.502 0 003.5 3.49h.01l29.69-.12c.73 11.74 10.5 21.06 22.42 21.06 11.97 0 21.78-9.41 22.43-21.23l16.42-.07c4.75 0 8.62-4.28 8.62-9.53V204.8c.51-16.17-15.26-19.64-27.94-22.43zm-102.96 25.61c8.53 0 15.47 6.94 15.47 15.47s-6.94 15.47-15.47 15.47-15.47-6.94-15.47-15.47c0-.51.03-1.01.08-1.5 0-.05.01-.09.01-.13.82-7.77 7.4-13.84 15.38-13.84zm41.27-26.57c-27.68.55-38.74-2-43.15-4.24-1.54-.78-4.27-4.97-6.4-9.18 2-1.58 4.94-3.36 8.86-4.35 11.31-2.88 39.13-3.68 55.48-.51 12.77 2.47 23.01 11.66 27.11 16.64-8.41.5-23.48 1.28-41.9 1.64zm42.17 57.5c-8.53 0-15.47-6.94-15.47-15.47s6.94-15.47 15.47-15.47 15.47 6.94 15.47 15.47-6.94 15.47-15.47 15.47z" />
    </svg>
  );
}

export default IconLightVehicle;
