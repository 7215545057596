import React from "react";
import { Col, Row } from "react-bootstrap";

const SeuCredito = () => {
  const renderInformacoesParcelas = (valor = "", meses = "") => {
    return (
      <p>
        Com parcelas a partir de <strong>{valor}</strong> e prazo de até{" "}
        <strong>{meses}</strong>, você pode:
      </p>
    );
  };

  return (
    <>
      <h3 className="text-center content-title pt-4 mb-5">
        O que você pode fazer com o seu crédito?
      </h3>
      <Row className="px-3">
        <Col xs={12} lg={4} style={{ minWidth: "250px" }}>
          <h4 className="mb-3">Consórcio Imobiliário</h4>
          {renderInformacoesParcelas("R$ 310,00", "200 meses")}
          <ul>
            <li>
              Comprar imóveis residenciais e comerciais, novos, usados ou na
              planta, terrenos, casa na praia e no campo.
            </li>
            <li>Utilizar o seu crédito para construir ou reformar.</li>
            <li>Quitar seu financiamento imobiliário.</li>
          </ul>
          <br />
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: "24px" }}>
          <h4 className="mb-3">Consórcio Veículos Leves</h4>
          {renderInformacoesParcelas("R$ 230,00", "80 meses")}
          <ul>
            <li>Comprar veículos 0km.</li>
            <li>Adquirir veículos com até 8 anos de uso.</li>
            <li>Quitar seu financiamento de veículos.</li>
          </ul>
        </Col>
        <Col xs={12} lg={4} style={{ marginBottom: "24px" }}>
          <h4 className="mb-3">Consórcio de Veículos Pesados</h4>
          {renderInformacoesParcelas("R$ 2.370,00", "100 meses")}
          <ul>
            <li>
              Comprar ônibus e caminhão, novo ou usado, com até 10 anos de
              fabricação.
            </li>
            <li>Adquirir trator, máquinas e equipamentos agrícolas.</li>
            <li>
              Utilizar o seu crédito para comprar aeronaves e embarcações.
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
};

SeuCredito.propTypes = {};

export default SeuCredito;
