import React, { useEffect } from "react";
import { Page, Section } from "../../../components/shared/pages";
import imagensConsorcio from "../../../asserts/images/consorcios";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import {
  ProximasAssembleias,
  BannerConsorcio,
  ComunicadoContemplacao,
  TemAlgumaDuvida,
} from "../sections";

const Assembleias = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 450);
    }, 500);
  });
  return (
    <Page
      {...props}
      id="page-assembleias"
      images={imagensConsorcio}
      banner={BannerConsorcio}
    >
      <SeguridadePageLayout.Tab eventKey="assembleias">
        <Section
          as={ProximasAssembleias}
          sectionName="proximas assembleias"
          id="proximas-assembleias"
        />
        <Section
          as={ComunicadoContemplacao}
          title="Comunicado de contemplação"
          sectionName="comunicado"
        />
        <Section
          as={TemAlgumaDuvida}
          title="Tem alguma dúvida?"
          sectionName="duvidas"
          sectionLayout={null}
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default Assembleias;
