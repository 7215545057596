function readProperty(obj, propPath) {
  if (!obj || typeof propPath !== "string") {
    return undefined;
  }

  return propPath
    .split(".")
    .reduce((current, prop) => (current ? current[prop] : current), obj);
}

export default readProperty;
