import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { applyKey } from "../../../utils";
import FloatChat from "../floatchat";
import AccessibilityButton from "../AccessibilityButton/AccessibilityButton";

const AreaRoute = ({ main: MainPage, routes, title, chatId }) => {
  const match = useRouteMatch();

  const handleAccessibilityTranslator = () => {
    if (window.ht) {
      window.ht.openWebsiteTranslator();
    }
  };

  const handleAccessibilityFeature = () => {
    if (window.ht) {
      window.ht.openAccessibilityFeatures();
    }
  };

  return (
    <>
      <Switch>
        <Route
          path={match.path}
          exact
          render={() => <MainPage pageTitle={title} />}
        />
        {applyKey(
          routes,
          ({ path, component: ComponentPage, pageTitle, pageProps }) => (
            <Route
              path={`${match.path}${path}`}
              render={() => (
                <ComponentPage {...pageProps} pageTitle={pageTitle} />
              )}
            />
          )
        )}
        <Route exact path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
      {chatId ? <FloatChat chatId={chatId} /> : null}
      <AccessibilityButton
        translator={() => handleAccessibilityTranslator()}
        accessibilityVisual={() => handleAccessibilityFeature()}
      />
    </>
  );
};

AreaRoute.defaultProps = {
  routes: [],
  chatId: null,
};

AreaRoute.propTypes = {
  main: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.objectOf(Object)),
  title: PropTypes.string.isRequired,
  chatId: PropTypes.string,
};

export default AreaRoute;
