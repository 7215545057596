import React, { useState } from "react";
import imagensPrevidencia from "@asserts/images/ouvidoria";
import {
  BannerOuvidoria,
  OuvidoriaContato,
  AbrirOuvidoria,
  OuvidoriaSucesso,
} from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {
  const [newProtocol, setNewProtocol] = useState(null);
  return (
    <Page {...props} images={imagensPrevidencia} banner={BannerOuvidoria}>
      <SeguridadePageLayout.Tab eventKey="ouvidoria">
        {newProtocol === null && (
          <>
            <Section
              id="ouvidoria-abertura-chamado"
              as={AbrirOuvidoria}
              title="Abra aqui a sua ocorrência para a Ouvidoria"
              sectionLayout={null}
              setNewProtocol={setNewProtocol}
            />
            <Section
              id="ouvidoria-contato"
              as={OuvidoriaContato}
              title="Se preferir, você pode abrir uma ocorrência para a Ouvidoria por telefone:"
              sectionName="solicitar protocolo"
            />
          </>
        )}
        {newProtocol !== null && (
          <Section
            id="ouvidoria-abertura-sucesso"
            as={OuvidoriaSucesso}
            title=""
            sectionLayout={null}
            newProtocol={newProtocol}
          />
        )}
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
