import { useState } from "react";

const useCache = (itemName) => {
  const [isCached, setCached] = useState(itemName in sessionStorage);
  const putCache = (cacheableEntity, requestData) => {
    sessionStorage.setItem(
      itemName,
      JSON.stringify({
        data: cacheableEntity,
        metadata: {
          createdAt: new Date().getTime(),
          ...requestData,
        },
      })
    );
    setCached(true);
  };
  const getCache = () => JSON.parse(sessionStorage.getItem(itemName));
  return [isCached, putCache, getCache];
};
export default useCache;
