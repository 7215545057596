import React from "react";
import PageError from "./PageError";

const PageNotFound = (props) => {
  return (
    <PageError
      {...props}
      errorCode={404}
      errorTitle="Página Não Encontrada"
      errorMessage="Desculpe, ocorreu um erro, a página solicitada não foi encontrada!"
    />
  );
};

export default PageNotFound;
