import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

const DualContent = ({ colunaA, colunaB, className, children }) => {
  const contentA = React.Children.map(children, (item, index) =>
    index === 0 ? item : null
  );
  const contentB = React.Children.map(children, (item, index) =>
    index === 1 ? item : null
  );

  return (
    <>
      <Row className={className}>
        <Col {...{ ...colunaA }}>{contentA}</Col>
        <Col {...{ ...colunaB }}>{contentB}</Col>
      </Row>
    </>
  );
};

DualContent.defaultProps = {
  colunaA: null,
  colunaB: null,
  className: null,
};

DualContent.propTypes = {
  colunaA: PropTypes.objectOf(Object),
  colunaB: PropTypes.objectOf(Object),
  className: PropTypes.string,
};

export default DualContent;
