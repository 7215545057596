import "./style.css";
import React from "react";
import HomePage from "./pages/HomePage";
import AreaRoute from "../../components/shared/routes";
import { useSegmentRoute } from "../../hooks";

export const Page404 = () => {
  const pageTitle = "Ops! Não conseguimos encontrar essa página";

  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
  ]);

  return <AreaRoute {...routerContext} title={pageTitle} />;
};

export default Page404;
