import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const ProtocolInput = ({
  protocolo,
  isLoadingProtocol,
  formikBag,
  maxLength,
  minLength,
}) => {
  return (
    <InputControl
      inputStyle={{ minHeight: "50px", maxWidth: "500px", marginBottom: 0 }}
      controlId="formOuvidoria.protocolo"
      label="Informe o protocolo da sua solicitação anterior:"
      placeHolder="Protocolo anterior"
      name="protocolo"
      value={protocolo}
      maxLength={maxLength}
      minLength={minLength}
      disabled={isLoadingProtocol}
      {...formikBag}
    />
  );
};

ProtocolInput.propTypes = {
  protocolo: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number.isRequired,
  isLoadingProtocol: PropTypes.bool.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default ProtocolInput;
