import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Card from "../cards";

const BlockContents = ({ className, children, columProps }) => {
  const conteudo = React.Children.map(children, (el) => {
    if (!React.isValidElement(el)) {
      return null;
    }
    return (
      <Col
        {...{
          ...columProps,
          className: `blocks-content-column ${columProps.className || ""}`,
        }}
      >
        {React.cloneElement(el, {
          className: "blocks-content-column-content",
        })}
      </Col>
    );
  });
  return (
    <div className="blocks-content">
      <Row className={className}>{conteudo}</Row>
    </div>
  );
};

BlockContents.Column = Card;

BlockContents.defaultProps = {
  columProps: {},
};

BlockContents.propTypes = {
  columProps: PropTypes.objectOf(Object),
};

export default BlockContents;
