import { useState, useEffect } from "react";
import { useParams } from "../common";

const useAnchorParam = () => {
  const [ancora, setAncora] = useState(null);

  const { ancora: paramAncora } = useParams();

  useEffect(() => {
    setAncora(paramAncora || window.location.hash);

    return () => {
      setAncora(null);
    };
  }, [ancora, paramAncora]);

  return [ancora, setAncora];
};

export default useAnchorParam;
