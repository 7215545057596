import React from "react";
import PropTypes from "prop-types";
import { Container, Title, Description } from "./styles";

const OuvidoriaApiError = ({ title, description }) => {
  return (
    <Container>
      <Title variant="headline-06" align="left">
        {title}
      </Title>
      <Description variant="body01-lg" align="left">
        {description}
      </Description>
    </Container>
  );
};
export default OuvidoriaApiError;

OuvidoriaApiError.defaultProps = {
  title: "Desculpe, estamos passando por alguma instabilidade",
  description: "Por favor, tente novamente mais tarde.",
};

OuvidoriaApiError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
