import React from "react";
import App from "./App";
import MainRouter from "./routers";

const Bootstrap = () => {
  return (
    <MainRouter>
      <App />
    </MainRouter>
  );
};

export default Bootstrap;
