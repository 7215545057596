/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import * as S from "./FileUploader.style";

function FileUploader({
  bg,
  text,
  label,
  setFileBase64,
  fileName,
  setFileName,
  name,
}) {
  const [file2, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type === "image/png" ||
        file.type === "image/jpeg")
    ) {
      setFileName(file.name);
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFileBase64(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Por favor, selecione um arquivo PDF, PNG ou JPG.");
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFile(null);
    setFileBase64("");
  };

  return (
    <S.Box>
      <S.LabInputFile htmlFor={name} bg={bg}>
        {text}
      </S.LabInputFile>

      <input
        id={name}
        type="file"
        accept=".pdf,.png,.jpg,.jpeg"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      {fileName && (
        <div>
          <S.SpanStyled onClick={handleRemoveFile}>{fileName}</S.SpanStyled>
          <S.DeleteFile>x</S.DeleteFile>
        </div>
      )}
    </S.Box>
  );
}

export default FileUploader;
