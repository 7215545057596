import styled from "styled-components";
import ReactInputMask from "react-input-mask";
import FraudeConsorcioBackground from "../../../asserts/images/consorcios/fraude-background.png";

export const Background = styled.div(({ theme }) => ({
  background: `url(${FraudeConsorcioBackground})`,
  backgroundSize: "cover",
  height: "90vh",
  minHeight: "500px",
  display: "flex",
  flexDirection: "column",
  backgroundPositionX: "73%",
  [theme.breakpoint.lg()]: {
    backgroundPositionX: "center",
    height: "80vh",
  },
}));

export const Container = styled.div(({ theme }) => ({
  width: "90%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingLeft: "0",
  marginTop: "24px",
  maxWidth: "1140px",
  marginLeft: "auto",
  marginRight: "auto",
  height: "90vh",
  gap: "28px",
  justifyContent: "center",
  [theme.breakpoint.lg()]: {
    height: "80vh",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    gap: "60px",
    marginBottom: "8%",
  },
}));

export const TitleText = styled.h1(({ theme }) => ({
  fontSize: "52px",
  color: "#fff",
  [theme.breakpoint.lg()]: {
    fontSize: "80px",
  },
}));

export const SubtitleText = styled.h1(({ theme }) => ({
  fontSize: "28px",
  color: "#fff",
  [theme.breakpoint.lg()]: {
    fontSize: "36px",
  },
}));

export const Box = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: "#454E4F",
  background: "#F7F7F7",
  padding: "21px 16px",
  margin: "auto",
  marginBottom: "-100px",
  [theme.breakpoint.lg()]: {
    padding: "21px",
  },
  [theme.breakpoint.xl()]: {
    padding: "21px 0",
  },
}));

export const SectionCards = styled.div`
  background-color: #fb75b3;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    color: #fff;
    text-align: center;
  }

  & > div.content-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1024px;

    @media all and (min-width: 650px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1.2rem;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex: 1;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > svg {
    width: 90px;
    height: 90px;
  }

  & > h5 {
    text-align: center;
    color: #606060;
    font-style: italic;
  }
  & > p {
    text-align: center;
  }
`;

export const SectionReducedInfo = styled.div`
  background-color: #67acfc;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 100%;
  }

  @media all and (min-width: 650px) {
    & > img {
      width: 70%;
    }
  }

  & > .content-info {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    border-bottom: 1px solid #fff;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    p {
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-weight: 100;
      line-height: 40px;

      span {
        font-family: var(--font-brand-bold);
      }
      @media (min-width: 800px) {
        width: 60%;
      }
    }
  }

  & > h3 {
    color: #fff;
    text-align: center;
  }

  & > div.content-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 1024px;
    width: 100%;

    @media all and (min-width: 500px) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;

export const CardReducedInstallment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 1.2rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  & > p {
    text-align: center;
    font-weight: 900;
    font-size: 16px;
  }

  & > h5 {
    text-align: center;
    color: #606060;
    font-style: italic;
    font-size: 24px;
    margin-bottom: 1rem;

    span {
      font-size: 24px;
    }
  }

  & > h5.installment {
    margin-top: 1rem;
    color: #fb75b3;
  }

  & > p.installment-details {
    margin: 0;
    span {
      text-decoration-line: line-through;
    }
  }

  @media all and(min-width: 550px) {
    min-width: 40%;
    max-width: 48%;
  }
  @media all and(min-width: 650px) {
    min-width: 22%;
    max-width: 24%;
  }
`;

export const SectionContentInfo = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1024px;
    align-items: center;
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 1rem;

    svg {
      width: 60%;
    }

    & > h3 {
      color: #fb75b3;
      text-align: center;
      margin-top: 1rem;
    }

    & > div.final-time {
      background-color: #fb75b3;
      display: flex;
      flex: 1;
      padding: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      margin-bottom: 1rem;

      h3 {
        color: #fff;
        margin: 0;
        text-align: center;
      }
    }

    & > div.general-info {
      background-color: #67acfc;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 1rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      margin-bottom: 1rem;
      -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      h3 {
        color: #fff;
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    & > .general-info > .content-descriptions {
      display: flex;
      flex-direction: column;

      .description {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 1;

        img {
          width: 150px;
          height: 148px;
        }

        .column-text {
          display: flex;
          flex-direction: column;

          h5,
          p {
            color: #fff;
          }
        }
      }
    }
  }

  & > div.content > a {
    cursor: pointer;
    text-decoration: none;
  }

  & > div.content > a > div.link-info {
    padding: 1rem 4rem;
    margin: 2rem 0;
    background-color: #ffaa59;
    border-radius: 12px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      text-decoration: none;
    }

    h3 {
      margin: 0;
      color: #fff;
      text-align: center;
    }
  }

  @media (min-width: 650px) {
    & > div.content > svg {
      width: 40%;
    }

    & > div.content div.general-info div.content-descriptions {
      flex-direction: row;
      flex-wrap: wrap;

      .description {
        min-width: 48%;
        max-width: 50%;
      }
    }
  }
`;

export const SectionInfo = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;

  & > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #b0b0b0;
    padding-bottom: 1rem;
    max-width: 1024px;

    p {
      font-size: 0.8rem;
      margin-top: 1rem;
    }
  }
`;

export const Formulario = styled.div(() => ({
  width: "100%",
  padding: "10px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
}));

export const Logo = styled.div(() => ({
  maxWidth: "33%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  img: {
    objectFit: "cover",
  },
}));

export const TitleSt = styled.h1(() => ({
  fontSize: "24px",
  fontFamily: "var(--font-brand), sans-serif",
  color: "var(--color-feedback-info-light)",
  fontWeight: "700",
  span: {
    fontFamily: "var(--font-brand-bold), sans-serif",
  },
}));

export const SubTitleSt = styled.h1(() => ({
  fontSize: "20px",
  fontFamily: "var(--font-brand), sans-serif",
  color: "var(--color-neutral-4)",
  fontWeight: "700",
}));

export const FormContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  padding: "10px",
}));

export const BtnEnviar = styled.button(() => ({
  width: "150px",
  maxHeight: "30px",
  borderStyle: "none",
  borderRadius: "8px",
  fontSize: "20px",
  fontWeight: "200",
  color: "white",
  cursor: "pointer",
  margin: "10px",
  backgroundColor: "var(--color-feedback-info-light)",
  transition: "background 0.3s ease",
  boxShadow: "0px 3px 6px 0px rgb(51, 51, 51, 0.62)",
  "&:hover": {
    backgroundColor: "#1414ff",
  },
  "&:active": {
    backgroundColor: "#1414ff",
    boxShadow: "0 0px",
    transform: "translateY(2px)",
  },
}));

export const BtnContainer = styled.div(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "40px",
  paddingBottom: "60px",
}));

export const TextError = styled.p(() => ({
  fontSize: "12px",
  textAlign: "left",
  color: "red",
}));

export const TextTitle = styled(Text)(() => ({
  fontSize: "16px !important",
  textAlign: "left !important",
}));

export const InputMaskStyled = styled(ReactInputMask)`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  &:focus {
    border-color: black;
    outline: none;
  }
`;

export const Inputdiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "47%",
  margin: "15px auto 15px 10px",
  p: {
    color: "red",
  },
  "@media (max-width: 768px)": {
    width: "95%",
  },
}));

export const TitleDiv = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  img: {
    width: "400px",
    padding: "90px 0 60px 0",
  },
  "@media (max-width: 768px)": {
    justifySelf: "center",
    img: {
      width: "300px",
      padding: 0,
      margin: "36px 24px",
    },
  },
}));

export const MascoteDiv = styled.div(() => ({
  img: {
    width: "100%",
    maxWidth: "530px",
    minWidth: "420px",
  },
  "@media (max-width: 768px)": {
    display: "contents",
    img: {
      minWidth: 0,
      width: "60%",
      maxWidth: "230px",
    },
  },
}));

export const PremiacaoDiv = styled.div(() => ({
  display: "flex",
  gap: "8px",
  "@media (max-width: 768px)": {
    justifyContent: "center",
  },
}));

export const PremiacaoText = styled.p(({ bold }) => ({
  fontFamily: bold ? "CAIXA Std Bold" : "CAIXA Std",
  fontSize: "28px",
  "@media (max-width: 768px)": {
    fontSize: bold ? "1.5em" : "1.2em",
  },
}));

export const PremiacaoFlex = styled.div(() => ({
  border: "2px solid #ffffff",
  width: "100%",
  maxWidth: "1180px",
  margin: "0 auto",
  borderRadius: "10px",
  color: "#ffffff",
  background: "rgba(0, 0, 100, 0.2)",
  height: "500px",
  marginBottom: "50px",
  display: "flex",
  justifyContent: "center",

  "@media (max-width: 768px)": {
    width: "80%",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));
