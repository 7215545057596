import React from "react";
import "./AjaxSpinner.css";

const AjaxSpinner = (props) => {
  let spinner = null;
  if (props.loading) {
    spinner = (
      <>
        <div data-html2canvas-ignore className="Backdrop" />
        <div data-html2canvas-ignore className="spinner">
          <div className="bouncer bounce1" />
          <div className="bouncer bounce2" />
          <div className="bouncer" />
        </div>
      </>
    );
  }
  return spinner;
};

export default AjaxSpinner;
