import { useMemo, useState } from "react";
import useAjaxRequest, { GET } from "./useAjaxRequest";
import { endPoints } from "../../config";

const useProtocol = () => {
  const {
    response: { error, loading: isLoadingProtocol },
    performRequest,
  } = useAjaxRequest();

  const [searchProtocol, setCurrentProtocol] = useState(null);

  async function checkProtocol(protocolo, produto) {
    const options = {
      method: GET,
      url: endPoints.checarProtocoloAberturaOuvidoriaEndPoint({
        protocolo,
        produto,
      }),
    };
    return performRequest(options).finally(() => setCurrentProtocol(protocolo));
  }

  const errorProtocol = useMemo(() => {
    if (error?.message) {
      return error;
    }
    return error?.response?.data;
  }, [error]);

  const avaliableProtocol = useMemo(
    () => !errorProtocol && !isLoadingProtocol && searchProtocol,
    [errorProtocol, isLoadingProtocol, searchProtocol]
  );

  return { errorProtocol, isLoadingProtocol, avaliableProtocol, checkProtocol };
};

export default useProtocol;
