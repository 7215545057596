import React, { useState } from "react";
import { Container, Image, Nav, Navbar, NavLink } from "react-bootstrap";
import { AiOutlineMenu } from "react-icons/ai";
import { BiHeart } from "react-icons/bi";
import {
  FaPiggyBank,
  FaHome,
  FaHandHoldingUsd,
  FaFileInvoiceDollar,
  FaCoins,
  FaTools,
  FaCarAlt,
  FaStethoscope,
} from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";
import { LINKS } from "@constants";
import images from "@asserts/images";
import { Link, Button } from "../shared/actions";
import { envs } from "../../config";
import { history } from "../../main/routers";
import DesktopSubMenu from "../shared/layout/header/DesktopSubMenu";
import DesktopSubMenuContents from "./DesktopSubMenuContents";
import DropDownSubMenu from "../shared/layout/header/DropDownSubMenu";
import "./style.css";
import { applyKey } from "../../utils";

const linksSuperiores =
  envs.scopedSeguridade === "true"
    ? [
        {
          label: "Quem Somos",
          href: LINKS.linkQuemSomos,
        },
        {
          label: "SAC/Ouvidoria",
          href: LINKS.sacOuvidoria,
        },
      ]
    : [
        {
          label: "Relação com Investidores",
          href: LINKS.relacaoInvestidores,
        },
      ];

const produtos =
  envs.scopedSeguridade === "true"
    ? [
        {
          label: "Seguro de vida",
          icon: BiHeart,
          group: 1,
          href: LINKS.linkSeguroVida,
        },
        {
          label: "Seguro Prestamista",
          icon: FaFileInvoiceDollar,
          group: 1,
          href: LINKS.linkSeguroPrestamista,
          className: "icon-menu-custom",
        },
        {
          label: "Previdência",
          icon: FaPiggyBank,
          group: 1,
          href: LINKS.linkPrevidencia,
        },
        {
          label: "Residencial e Habitacional",
          icon: FaHome,
          group: 2,
          href: LINKS.linkSeguroResidencial,
        },
        {
          label: "Capitalização",
          icon: FaCoins,
          group: 2,
          href: LINKS.linkCapitalizacao,
        },
        {
          label: "Consórcio",
          icon: FaHandHoldingUsd,
          group: 2,
          href: LINKS.linkConsorcio,
        },
        {
          label: "Assistência",
          icon: FaTools,
          group: 3,
          href: LINKS.linkAssitencia,
        },
        {
          label: "Seguro Auto",
          icon: FaCarAlt,
          group: 3,
          href: LINKS.linkSeguroAuto,
        },
        {
          label: "Saúde e Odonto",
          icon: FaStethoscope,
          group: 3,
          href: LINKS.linkSaudeOdonto,
        },
      ]
    : [];

const linksMenu =
  envs.scopedSeguridade === "true"
    ? [
        { label: "Canais de Atendimento", href: LINKS.canaisAtendimento },
        { label: "Área do Cliente", href: LINKS.areaCliente },
        { label: "Relação com Investidores", href: LINKS.relacaoInvestidores },
        {
          label: "Blog CAIXA Consórcio",
          href: LINKS.linkBlogConsorcio,
        },
      ]
    : [{ label: "Área do Cliente", href: LINKS.areaCliente }];

const HeaderSeguridade = (props) => {
  const [open, setOpen] = useState(false);

  const customMenuRef = React.createRef();

  const brandLogo =
    envs.scopedSeguridade === "true"
      ? images.brandLogoCaixaSeguridade
      : images.brandLogoCaixaConsorcios;

  const navSuperior = (
    <Nav>
      {applyKey(linksSuperiores, ({ label, href }) => (
        <Nav.Link as={Link} label={label} ignoreBlank href={href} />
      ))}
    </Nav>
  );

  return (
    <>
      <div className="header-seguridade">
        <Navbar className="text-white d-none d-lg-block">
          <Container className="align-self-start justify-content-end navbar-collapse d-lg-flex header-nav">
            {navSuperior}
          </Container>
        </Navbar>
        <Navbar
          {...props}
          expand="lg"
          className="w-100 text-white container p-0"
          sticky="top"
        >
          <Container fluid className="align-self-stretch mt-3 pb-2 p-0">
            <NavLink
              as={Link}
              className="d-md-none link-back-mobile text-white"
              onClick={history.back}
            >
              <BsChevronLeft />
            </NavLink>
            <Navbar.Brand as={Link} href={envs.homePortal} ignoreBlank>
              <Image src={brandLogo} alt="logo" className="logo" fluid />
            </Navbar.Brand>
            <Navbar.Toggle
              as={Button}
              variant="link"
              aria-controls="root-navbar-nav"
              className="text-white"
            >
              <AiOutlineMenu className="nav-brand-icon" />
            </Navbar.Toggle>
            <Navbar.Collapse
              id="root-navbar-nav"
              className="align-items-end align-self-stretch pt-2 pt-lg-0"
            >
              <Nav className="ml-1 ml-lg-4 mr-auto">
                {Array.isArray(produtos) && produtos.length ? (
                  <DropDownSubMenu
                    show={open}
                    onToggleClick={() => setOpen(!open)}
                    id="produtos-nav-dropdown"
                    label="Produtos"
                  >
                    {applyKey(produtos, (obj) => {
                      return (
                        <DropDownSubMenu.Item
                          as={Link}
                          ignoreBlank
                          {...obj}
                          onClick={() => setOpen(!open)}
                        />
                      );
                    })}
                  </DropDownSubMenu>
                ) : null}
                {applyKey(linksMenu, ({ label, href, blank }) => (
                  <Nav.Link
                    as={Link}
                    label={label}
                    href={href}
                    className="mr-md-2 mr-xl-4"
                    ignoreBlank
                    blank={blank}
                  />
                ))}
              </Nav>
              {React.cloneElement(navSuperior, { className: "d-lg-none ml-1" })}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <DesktopSubMenu
        ref={customMenuRef}
        in={open}
        selectItemHandle={() => setOpen(false)}
        onMouseLeave={() => setOpen(false)}
      >
        <DesktopSubMenuContents menuGroupItems={produtos} />
      </DesktopSubMenu>
    </>
  );
};

HeaderSeguridade.propTypes = {};

export default HeaderSeguridade;
