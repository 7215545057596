import { useCallback, useEffect, useState } from "react";
import { envs } from "../../config";

const useAutoCompraHabilitado = (autoCompraId) => {
  const [habilitado, setHabilitado] = useState(false);

  const isAutoCompraHabilitado = useCallback((autoCompra) => {
    const autoComprasHabilitados = envs.enabledStores
      .split(",")
      .find((id) => autoCompra === id);

    setHabilitado(autoComprasHabilitados);
  }, []);

  useEffect(() => {
    if (autoCompraId && envs.enabledStores) {
      isAutoCompraHabilitado(autoCompraId);
    }
  }, [autoCompraId, envs.enabledStores]);

  return { habilitado, isAutoCompraHabilitado };
};

export default useAutoCompraHabilitado;
