export const cpfCnpjRegex = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/;

export const cepRegex = /^[0-9]{5}-?[0-9]{3}$/;
export const cepMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
};

export const telefoneRegex = /(\(?\d{2}\)?\s)?(\d{4,5}\d{4})/;

export const protocoloRegex = /\d{4,20}/;

export const nomeRegex = /^[a-zA-ZáéíóúàèìòùâêîôûÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛãÃõÕ]{1,40}\s[a-zA-ZáéíóúàèìòùâêîôûÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛãÃõÕ]+/;
