import styled, { css } from "styled-components";

export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_RED = "#bb1f40";
export const COLOR_RED_DARKER = "#e61200";
export const COLOR_BLUE = "#005fc8";
export const COLOR_BLUE_100 = "#0039ba";
export const COLOR_BLUE_LIGHT = "#e2edf9";
export const COLOR_BLUE_DARK = "#002b8d";
export const COLOR_ORANGE = "#ff6d00";
export const COLOR_PURPLE = "#733791";
export const COLOR_GREEN = "#41b9b4";
export const COLOR_GREEN_DARK = "#00a499";
export const COLOR_GRAY_LIGHT = "#7e7e7e";
export const COLOR_GRAY_100 = "#c8caca";
export const COLOR_GRAY_200 = "#a6a6a7";
export const COLOR_GRAY_300 = "#e2e3e3";
export const COLOR_GRAY_400 = "#e1e1e1";
export const COLOR_GRAY_500 = "#f8f8f8";
export const COLOR_GRAY_600 = "#c1c1c1";
export const COLOR_GREY = "#b9b9b9";
export const COLOR_GREY_LITHER = "#e1e1e1";
export const COLOR_GREY_DARKER = "#494949";
export const COLOR_GREY_BORDER = "#e6e6e6";
export const COLOR_GREY_TITLE = "#313e47";
export const COLOR_GREY_SUBTITLE = "#7a7a7a";
export const COLOR_COLLAPSE_OPEN = "#1a4ec2";

export const COLOR_YELLOW_DARKER = "#c1d82f";

export const screenSizes = {
  iphone5: 568,
  mobile: 768,
  sm_desktop: 1024,
  lg_desktop: 1400,
};

export const media = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (args.length) {
      return css`
        @media (min-width: ${screenSizes[label] / 16}em) {
          ${css(...args)}
        }
      `;
    }
    return `@media (min-width: ${screenSizes[label] / 16}em)`;
  };
  return acc;
}, {});

export const productStyle = (type) => {
  if (type === "vida") {
    return `
        background-color: ${COLOR_GREEN_DARK};
    `;
  }
  if (type === "residencial") {
    return `
        background-color: ${COLOR_PURPLE};
    `;
  }
  if (type === "consorcio") {
    return `
      background-color: ${COLOR_ORANGE};
    `;
  }
  return `
    background: #FDFDFD;
    color: #ffffff;
  `;
};

export const TitleComponent = styled.h3`
  font-size: 18px;
  font-weight: 500;
`;

export const SectionPage = styled.section`
  margin-top: 30px;
`;

export const NumberItem = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
  line-height: 1.2;
  ${(props) => props.vida && `color: ${COLOR_GREEN_DARK}`}
  ${(props) => props.consorcio && `color: ${COLOR_ORANGE}`}
  ${(props) => props.residencial && `color: ${COLOR_PURPLE}`}
`;

export const PhoneNumber = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  ${(props) => props.vida && `color: ${COLOR_GREEN_DARK}`}
  ${(props) => props.consorcio && `color: ${COLOR_ORANGE}`}
  ${(props) => props.residencial && `color: ${COLOR_PURPLE}`}
`;
