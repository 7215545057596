import React from "react";
import PropTypes from "prop-types";
import { Collapse, Container } from "react-bootstrap";
import { scrollToTop } from "@utils";

const DesktopSubMenu = React.forwardRef((props, ref) => {
  const {
    selectItemHandle,
    children,
    fluid,
    in: collapse,
    onMouseOver,
    onMouseLeave,
    style,
    className,
    labeledBy,
  } = props;

  function onSelectHandle() {
    if (selectItemHandle) {
      selectItemHandle();
    }
    scrollToTop();
  }

  const customMenuChildren = React.Children.map(children, (el) => {
    if (React.isValidElement(el)) {
      return React.cloneElement(el, { ...props, onSelectHandle });
    }
    return null;
  });

  return (
    <Collapse
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      in={collapse}
    >
      <div className="desktop-submenu">
        <Container fluid={fluid} className="d-none d-md-block">
          {customMenuChildren}
        </Container>
      </div>
    </Collapse>
  );
});

DesktopSubMenu.defaultProps = {
  in: false,
  onMouseOver: null,
  onMouseLeave: null,
  selectItemHandle: null,
  style: {},
  className: null,
  labeledBy: null,
  fluid: false,
};

DesktopSubMenu.propTypes = {
  in: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selectItemHandle: PropTypes.func,
  style: PropTypes.objectOf(Object),
  className: PropTypes.string,
  labeledBy: PropTypes.string,
  fluid: PropTypes.bool,
};

export default DesktopSubMenu;
