import React, { useState } from "react";
import PropTypes from "prop-types";

import SwitchControl from "../../../../../../components/shared/forms/SwitchControl";
import {
  generateBase64,
  validateFile,
} from "../../../../../../utils/fileUtils";
import {
  ExternalContainer,
  AuxiliarText,
  InternalContainer,
  MainText,
  ErrorMessage,
} from "./styled";
import { envs } from "../../../../../../config";

const formatExtensionList = (extensions) =>
  extensions
    .replaceAll(".", "")
    .split("")
    .reverse()
    .join("")
    .replace(",", "uo ")
    .split("")
    .reverse()
    .join("");

const OuvidoriaFileInput = ({ anexo, setAnexo }) => {
  const [label, setLabel] = useState("Anexar arquivo");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = () => {
    const file = document.getElementById("OuvidoriaFileInput").files[0];
    if (file) {
      const isValid = validateFile(file, setError);
      if (isValid) {
        setLabel(file.name);
        setChecked(true);
        setError("");
        const fileBase64 = generateBase64(file, setAnexo, setError);
        file.value = fileBase64;
        if (!checked) {
          document.getElementById("ouvidoria-switch").click();
        }
      } else {
        document.getElementById("ErrorMessage").focus();
      }
    }
  };
  const upload = () => {
    const fileRef = document.getElementById("OuvidoriaFileInput");
    fileRef.onchange = handleFileChange;
    fileRef.click();
  };

  const handleSwitch = () => {
    const fileRef = document.getElementById("OuvidoriaFileInput");
    if (anexo.conteudo) {
      setChecked(false);
      setLabel("Anexar arquivo");
      setAnexo({ nome: "", conteudo: "", tamanho: "" });
      fileRef.value = "";
    } else {
      upload();
    }
  };
  return (
    <ExternalContainer>
      <InternalContainer>
        <MainText>
          <b>
            É fundamental que nos envie cópia de um documento pessoal que
            comprove a autenticidade dos seus dados pessoais.
          </b>{" "}
          Caso queira, também pode nos enviar arquivos como fotos ou documentos
          que nos auxiliem no melhor entendimento da sua ocorrência.
        </MainText>
        <div>
          <SwitchControl
            name="ouvidoria"
            label={label}
            checked={checked}
            onChange={handleSwitch}
            onClick={upload}
          />
          <input
            type="file"
            name="file"
            id="OuvidoriaFileInput"
            hidden
            accept={envs.acceptedFiles}
          />
        </div>
      </InternalContainer>
      <AuxiliarText>
        O tamanho máximo de cada arquivo é de 600 kb e as extensões permitidas
        são: {formatExtensionList(envs.acceptedFiles)}.
      </AuxiliarText>
      {error && <ErrorMessage id="ErrorMessage">{error}</ErrorMessage>}
    </ExternalContainer>
  );
};

OuvidoriaFileInput.propTypes = {
  anexo: PropTypes.objectOf(Object).isRequired,
  setAnexo: PropTypes.func.isRequired,
};

export default OuvidoriaFileInput;
