import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const NameInput = ({ nome, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.nome"
    label="Qual o seu nome?"
    placeHolder="Digite o seu nome completo"
    name="ouvidoria.nome"
    value={nome}
    {...formikBag}
  />
);

NameInput.propTypes = {
  nome: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default NameInput;
