import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Section from "../section";

const PageError = ({ errorCode, errorMessage, errorTitle }) => {
  return (
    <Section className="page-error" variant="light">
      <div className="error-template p-5">
        <h1 className="p-2 mb-5">Oops!</h1>
        <h3>{`${errorCode} ${errorTitle}!`}</h3>
        <div className="error-details p-5">{errorMessage}</div>
        <div className="error-action">
          <LinkContainer to="/">
            <Button variant="primary" size="lg">
              Página Inicial
            </Button>
          </LinkContainer>
        </div>
      </div>
    </Section>
  );
};

PageError.defaultProps = {
  errorCode: 500,
  errorMessage: `Desculpe, ocorreu um erro inesperado!`,
  errorTitle: `Internal Server Error`,
};

PageError.propTypes = {
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
};

export default PageError;
