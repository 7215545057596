import React from "react";
import PropTypes from "prop-types";

export const Footer = (props) => {
  const { component } = props;

  const footerContent = React.createElement(component, props);

  return (
    <>
      <footer
        className="footer make-section"
        id="main-footer"
        data-section="footer"
      >
        {footerContent}
      </footer>
    </>
  );
};

Footer.propTypes = {
  component: PropTypes.elementType.isRequired,
};
export default Footer;
