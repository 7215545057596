import { isDate, parse } from "date-fns";
import { parseISO as dfParseISO } from "date-fns/esm";
import { envs } from "../config";

const defaultPatter = "dd/MM/yyyy";

export const parseDateString = (strValue, pattern) => {
  if (!strValue) {
    return null;
  }
  return isDate(strValue)
    ? strValue
    : parse(strValue, pattern || defaultPatter, new Date());
};

export const parseStringDate = (date = new Date()) => {
  return isDate(date)
    ? date.toLocaleDateString(envs.locale, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : null;
};

export const yupTransformDateString = (...[, originalValue]) => {
  return parseDateString(originalValue);
};

export const parseISO = (data) => {
  return dfParseISO(data);
};

export const christmasCampaing = new Date() < new Date("01/01/2022");
export const bannerBirthday = new Date() < new Date("01/31/2022");
export const bannerInformeRendimentos = new Date() <= new Date("05/31/2022");
export const publishBanner = (action = "show", publishDate) => {
  const today = new Date();
  const publishBannerDate = new Date(publishDate);
  if (action === "show") {
    return today > publishBannerDate;
  }
  if (action === "remove") {
    return today < publishBannerDate;
  }
  return false;
};

export const publishAndRemoveBanner = (publishDate, removeDate) => {
  const today = new Date();
  const publishBannerDate = new Date(publishDate);
  const removeBannerDate = new Date(removeDate);

  if (today >= publishBannerDate && today <= removeBannerDate) {
    return true;
  }
  return false;
};
