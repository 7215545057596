import React from "react";
import PropTypes from "prop-types";

const DefaultLayout = ({ children, groupBanner, setGroupBanner }) => {
  React.Children.map(children, (el) =>
    React.cloneElement(el, { groupBanner, setGroupBanner })
  );

  return <>{children}</>;
};

DefaultLayout.defaultProps = {
  groupBanner: null,
  setGroupBanner: null,
};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  groupBanner: PropTypes.string,
  setGroupBanner: PropTypes.func,
};

export default DefaultLayout;
