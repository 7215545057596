import React from "react";
import { nanoid } from "nanoid";

function applyKey(elementArray, map) {
  if (!Array.isArray(elementArray)) {
    return [];
  }

  return elementArray
    .map(map)
    .map((el) => React.cloneElement(el, { key: nanoid() }));
}

export default applyKey;
