/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiInfoCircle } from "react-icons/bi";
import axios from "axios";
import api from "../../../utils/api";
import FormInputs from "../../../components/shared/FormSortuda/index";
import * as S from "./style";
import Logo from "../../../asserts/images/consorcios/Sortudao.png";
import Modal from "../../../components/shared/modal";
import AjaxSpinner from "../../../components/AjaxSpinner/AjaxSpinner";

const Formulario = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm();
  const [rendaValue, setRendaValue] = useState(`${"0,00"}`);
  const [premiacaoValue, setPremiacaoValue] = useState(`${"0,00"}`);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [cpfFile64, setCpfFile64] = useState("");
  const [rgFile64, setRgFile64] = useState("");
  const [compFile64, setCompFile64] = useState("");
  const [cpfFilename, setCpfFilename] = useState("");
  const [rgFilename, setRgFilename] = useState("");
  const [compFilename, setCompFilename] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const RG = watch("RGCopia");
  const cpf = watch("CPFCopia");
  const compr = watch("CompEndereco");
  const expe = watch("Expedicao");
  const nasc = watch("Nascimento");
  const values = getValues();

  const payload = {
    isSegundoNivel: true,
    desBU: "Caixa Cons",
    desCanal: "Web - Seguradora",
    desOrigemContato: "Solicitação Interna",
    numCPFCNPJ: values?.tipo === "Juridica" ? values?.CNPJ : values?.CPF,
    objCliente: {
      objEndereco: {
        numCEP: values?.CEP,
        desCidade: values?.Cidade,
        desBairro: values?.Bairro,
        desEstado: values?.Estado,
        desRua: values?.Endereço,
      },
      numTelefone: values?.Telefone,
      desEmail: values?.Email,
      desPrimeiroNome: values?.Nome?.split(" ")[0],
      desUltimoNome: values?.Nome?.split(" ")?.slice(1)?.join(" "),
    },
    objRepresentanteLegal: {
      numCPFCNPJ: values?.CPFProcurador,
      nomContato: values?.Nome,
      numTelefone: values?.Telefone,
      desEmail: values?.Email,
    },
    desFilaOrigem: "Serviços ao Cliente",
    nomAdmAssunto: `${
      process.env.REACT_APP_WEB_ENV === "prd" ? "10-1311-1312" : "10-1221-1222"
    }`,
    desComentarioCaso: `
      Razão Social (PJ): ${values?.RazaoSocial},
      Atividade Principal (PJ): ${values?.Atividade},
      Qualificação do Procurador (PJ): ${values?.Qualificacao},
      Tipo de protocolo (PJ): ${values?.tipo}, 
      Email (PJ): ${values?.EmailPJ},
      objRepresentanteLegal: {
            numCPFCNPJ: ${values?.CPFProcurador},
            nomContato: ${values?.NomePJ},
            numTelefone: ${values?.TelefonePJ},
            desEmail: ${values?.EmailPJ},
          },
      PPE: ${values?.PPE},
      Valor da premiacao: ${values?.Premiacao},
      Profissão do cliente: ${values?.Profissao},
      Renda do cliente: ${values?.Renda},
      Agência: ${values?.Agencia},
      Conta Corrente: ${values?.ContaCorrente},
      Expedição: ${values?.Expedicao},
      Nome do banco: ${values?.NomeBanco},
      Numero do banco: ${values?.NumBanco},
      Orgão: ${values?.orgao},
      RG: ${values?.RG},
      Data de nascimento: ${values?.Nascimento},
      isSegundoNivel: true,
      desBU: "Caixa Cons",
      desCanal: "Web - Seguradora",
      desOrigemContato: "Solicitação Interna",
      CPF: ${values?.CPF},
      CNPJ: ${values?.CNPJ},
      Email: ${values?.Email},
      objCliente: {
      objEndereco: {
        numero CEP: ${values?.CEP},
        Cidade: ${values?.Cidade},
        Bairro: ${values?.Bairro},
        Estado: ${values?.Estado},
        Rua: ${values?.Endereço},
      },
      Numero de telefone: ${values?.Telefone},
      Primeiro Nome: ${values?.Nome?.split(" ")[0]},
      Ultimo Nome: ${values?.Nome?.split(" ")?.slice(1)?.join(" ")},
    },
    FilaOrigem: "Serviços ao Cliente",
      `,

    tipOrigemProtocolo: "Cliente",
    indicadorSolicitacaoConcluida: true,
  };

  function convertFileToBase64(fil) {
    const reader = new FileReader();

    let base64Data = "";

    reader.readAsDataURL(fil);
    reader.onload = () => {
      base64Data = reader.result.split(",")[1];
      console.log(base64Data);
    };

    reader.onerror = () => {
      console.error("Erro ao converter o arquivo.");
    };

    return { base64Data };
  }

  const onSubmit = async (data) => {
    console.log(process.env.WEB_CONFIG_ENV);
    console.log(process.env.REACT_APP_WEB_ENV);
    if (isValid) {
      if (rgFile64 !== "" && cpfFile64 !== "" && compFile64 !== "") {
        try {
          setLoading(true);

          const token = await axios.get(api.TOKEN_OAUTH, {});
          sessionStorage.setItem("access_token", token.data.access_token);

          const tokenSL = await axios.get(api.TOKEN_SALESFORCE, {
            headers: {
              "X-IBM-Client-Id": process.env.REACT_APP_CLIENT_ID,
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              scope: "clientes",
              "X-Resource-Owner": "00000000000",
            },
          });

          sessionStorage.setItem("access_token_salesforce", tokenSL.data.token);

          const response = await axios.post(api.SOLICITACAO, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              token: sessionStorage.getItem("access_token_salesforce"),
              "X-IBM-Client-Id": process.env.REACT_APP_CLIENT_ID,
            },
          });
          console.log(response);
          if (response.data.status === "Falha") {
            throw new Error(
              "Ocorreu uma falha ao enviar os dados. Por favor, tente novamente mais tarde."
            );
          }
          sessionStorage.setItem("numProtocolo", response.data.numProtocolo);
          sessionStorage.setItem(
            "codSolicitacao",
            response.data.codSolicitacao
          );

          await axios.post(
            api.ANEXO_SALESFORCE,
            {
              arquivo: cpfFile64,
              descricao: "Anexo do cliente",
              id: sessionStorage.getItem("codSolicitacao"),
              nome: cpfFilename,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access_token"
                )}`,
                token: sessionStorage.getItem("access_token_salesforce"),
                "X-IBM-Client-Id": process.env.REACT_APP_CLIENT_ID,
              },
            }
          );
          await axios.post(
            api.ANEXO_SALESFORCE,
            {
              arquivo: rgFile64,
              descricao: "Anexo do cliente",
              id: sessionStorage.getItem("codSolicitacao"),
              nome: rgFilename,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access_token"
                )}`,
                token: sessionStorage.getItem("access_token_salesforce"),
                "X-IBM-Client-Id": process.env.REACT_APP_CLIENT_ID,
              },
            }
          );
          await axios.post(
            api.ANEXO_SALESFORCE,
            {
              arquivo: compFile64,
              descricao: "Anexo do cliente",
              id: sessionStorage.getItem("codSolicitacao"),
              nome: compFilename,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access_token"
                )}`,
                token: sessionStorage.getItem("access_token_salesforce"),
                "X-IBM-Client-Id": process.env.REACT_APP_CLIENT_ID,
              },
            }
          );

          setShowModalSuccess(true);
        } catch {
          console.log("deu ruim");
          setShowModalError(true);
        } finally {
          setLoading(false);
        }
      } else {
        alert("É necessário enviar todos os documentos solicitados.");
      }
    } else {
      alert("Prencha o formulario completamente");
    }
  };

  return (
    <>
      {loading && <AjaxSpinner loading={loading} />}
      <S.Formulario>
        <S.Logo>
          <img src={Logo} alt="Sortudão" />
        </S.Logo>
        <S.TitleSt>
          Parabéns ganhador do <span>Consócio Sortudão</span>
        </S.TitleSt>
        <S.SubTitleSt>
          Para pagamento do seu prêmio, preencha as informações a seguir:
        </S.SubTitleSt>
        <S.FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs
              register={register}
              errors={errors}
              rgFile={RG}
              cpf={cpf}
              comprovante={compr}
              expe={expe}
              nasc={nasc}
              setValue={setValue}
              rendaValue={rendaValue}
              setRendaValue={setRendaValue}
              premiacaoValue={premiacaoValue}
              setPremiacaoValue={setPremiacaoValue}
              setCpfFile64={setCpfFile64}
              setRgFile64={setRgFile64}
              setCompFile64={setCompFile64}
              cpfFilename={cpfFilename}
              setCpfFilename={setCpfFilename}
              rgFilename={rgFilename}
              setRgFilename={setRgFilename}
              compFilename={compFilename}
              setCompFilename={setCompFilename}
            />
            <S.BtnContainer>
              <S.BtnEnviar type="button" onClick={() => onSubmit(values)}>
                Enviar
              </S.BtnEnviar>
            </S.BtnContainer>
          </form>
        </S.FormContainer>
      </S.Formulario>
      <Modal
        show={showModalSuccess}
        size="sm"
        className="percentual-contemplacao"
        title={<BiInfoCircle className="text-body-md" />}
        handleClose={() => {
          setShowModalSuccess(false);
        }}
      >
        <div className="text-body-sm">
          <p>Dados enviados com sucesso!</p>
        </div>
      </Modal>
      <Modal
        show={showModalError}
        size="sm"
        className="percentual-contemplacao"
        title={<BiInfoCircle className="text-body-md" />}
        handleClose={() => {
          setShowModalError(false);
        }}
      >
        <div className="text-body-sm">
          <p>
            Houve uma falha ao enviar os dados. Por favor, tente novamente mais
            tarde.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Formulario;
