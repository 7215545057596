import envs from '../config/envs';

export const areaLogada = envs.hostAreaLogada;
export const cadastroAreaLogada = `${areaLogada}/perfil/criar`;

export const autoCompraConsorcio = `${envs.hostAutoCompra}/consorcio`;
export const autoCompraConsorcioProdutos = `${envs.hostAutoCompra}/consorcio/produtos`;

export const sortudao = `/consorcio/sortudao`;
export const parcelaReduzida = `/consorcio/parcela-reduzida`;

export const canalDenuncia = `${envs.hostCanalDenuncia}`;
export const caixa = `${envs.hostCaixa}`;
export const politicaPrivacidade = `${envs.hostPoliticaPrivacidade}`;

export const condicoesGeraisImobiliario = `${envs.hostStaticCdn}/AreaAberta/Consorcio/CondicoesGerais/Imobiliario/Caixa-Consorcio-Condicoes-Gerais-Imobiliario.pdf`;
export const condicoesGeraisVeiculo = `${envs.hostStaticCdn}/AreaAberta/Consorcio/CondicoesGerais/Veiculos/Caixa-Consorcio-Condicoes-Gerais-Veiculos.pdf`;
export const linkRegrasFgts = `${envs.hostStaticCdn}/AreaAberta/Consorcio/RegrasFGTS/Caixa-Consorcio-Regras-FGTS.pdf`;

export const contratoAdesaoImobiliario = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Caixa-Consorcio-Adesao-Imobiliario.pdf`;
export const contratoAdesaoVeiculos = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Caixa-Consorcio-Adesao-Veiculos.pdf`;

export const contratoAdesaoImobiliarioV082021 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_8-2021.pdf`;
export const contratoAdesaoImobiliarioV092021 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/CondicoesGerais/Imobiliario/Imobili%C3%A1rio_V2_09_2021.pdf`;
export const contratoAdesaoImobiliarioV042023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_4-2023.pdf`;
export const contratoAdesaoImobiliarioV052023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/CondicoesGerais/Imobiliario/Imobili%C3%A1rio_V3_05_2023.pdf`;
export const contratoAdesaoImobiliarioV072023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/CDG_Contrato_Imobiliario_7-2023.pdf`;
export const contratoAdesaoImobiliarioV102023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_versao_4.pdf`;
export const contratoAdesaoImobiliarioV072024 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_versao_5.pdf`;
export const contratoAdesaoVeiculosV082021 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_8-2021.pdf`;
export const contratoAdesaoVeiculosV092021 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/CondicoesGerais/Veiculos/Autom%C3%B3veis_V2_09_2021.pdf`;
export const contratoAdesaoVeiculosV032022 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_3-2022.pdf`;
export const contratoAdesaoVeiculosV042023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_4-2023.pdf`;
export const contratoAdesaoVeiculosV052023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_4-2023.pdf`;
export const contratoAdesaoVeiculosV072023 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/CDG_Contrato_Veículos_7-2023.pdf`;
export const contratoAdesaoVeiculosV072024 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_versao_5.pdf`;
export const contratoAdesaoVeiculosV082024 = `${envs.hostStaticCdn}/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_versao_6.pdf`;

export const linkCartilhaTransImob = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Cartilhas/Imobiliario/Cartilha_Transferencia_Imovel.pdf`;

export const cartilhaParaTranferenciaVeiculoLeve = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Cartilhas/Veiculos/Cartilha_Transferencia_Veiculos.pdf`;
export const cartilhaParaTranferenciaVeiculoPesado = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Cartilhas/Pesados/Cartilha_Transferencia_Pesados.pdf`;

export const linkFormularioTransferenciaPF = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Formularios/PessoaFisica/Caixa-Consorcio-Transferencia-de-Cota-Pessoa-Fisica.doc`;
export const linkFormularioTransferenciaPJ = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Formularios/PessoaJuridica/Caixa-Consorcio-Transferencia-de-Cota-Pessoa-Juridica.doc`;

export const linkSeguroVida = `${envs.hostCaixaVidaPrevidencia}/segurodevida`;
export const linkPrevidencia = `${envs.hostCaixaVidaPrevidencia}/previdencia`;
export const linkSeguroPrestamista = `${envs.hostCaixaVidaPrevidencia}/seguroprestamista`;
export const linkSeguroResidencial = `https://www.caixaresidencial.com.br/`;

export const linkQuemSomos = `${envs.hostSeguridade}/Paginas/QuemSomos.aspx`;
export const linkCapitalizacao = `${envs.hostSeguridade}/Paginas/Capitalizacao.aspx`;
export const sacOuvidoria = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const relacaoInvestidores = `${envs.hostRi}`;
export const queroComprar = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const canaisAtendimento = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const areaCliente = `${envs.hostSeguridade}/Paginas/AreaDoCliente.aspx`;

export const faleComSeguridade = 'link-faleSeguridade';

export const linkBlogConsorcio = 'https://blogcaixaconsorcio.com.br';
export const linkConsorcio = 'https://www.caixaconsorcio.com.br/consorcio';
export const linkAssitencia = 'https://www.caixaassistencia.com.br/';
export const linkSeguroAuto = 'https://www.autocontratar.com.br/seguroauto/';
export const linkSaudeOdonto =
  "https://www.caixasaudeeodonto.com.br/saudeodonto";

export const linkBlog = "https://blogcaixaconsorcio.com.br/";

export const linkContratacaoSegura =
  "https://www.caixa.gov.br/atendimento/Paginas/encontre-a-caixa.aspx";

export const linkDicionarioConsorcio =
  "https://static.caixaconsorcio.com.br/DicionarioDoConsorcio/Dicionário Consórcio - CAIXA Consórcio - Final.pdf";


// footer
export const aCaixa = `${envs.hostCaixa}/sobre-a-caixa/Paginas/default.aspx`;
export const caixaNoticias = `${envs.hostCaixaNoticia}`;
export const politicaDePrivacidade = `${envs.hostPoliticaPrivacidade}`;
export const relatorioTransparenciaSalarial = `${envs.hostStaticCdn}/AreaAberta/Consorcio/Relat%C3%B3rioDeTranspar%C3%AAncia/Relatorio_de_Transparencia_Salarial.pdf`;
export const seguranca = `/consorcio/seguranca`;
export const linkRegulamentoAcaoPremiada = `https://static.caixaconsorcio.com.br/Marketing/regulamento-promocao-consorcio-caixa.pdf`;
export const linkEducacaoFinanceira = `https://static.caixaconsorcio.com.br/EducacaoFinanceira/Cartilha_Educacao_Financeira.pdf`;
export const linkSustentabilidade = `https://www.caixaseguridade.com.br/Documents/Politica_Responsabilidade_Social_Ambiental_e_Climatica_CAIXA_Consorcio.pdf`;
