/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LINKS } from "@constants";
import { BannerContents } from "../../../components/shared/layout";
import { publishBanner, publishAndRemoveBanner } from "../../../utils";

export const BannerConsorcio = (props) => {
  const { images } = props;

  const publishBannerParcelaReduzidaDate = "2024-10-01 00:00:00";
  const removeBannerParcelaReduzida2Date = "2025-01-01 00:00:00";
  const removeBannerBlueFriday2024Date = "2024-11-29 00:00:00";
  const removeBannerNovoAppDate = "2024-12-31 00:00:00";

  const trocaBanners310824 = "2024-08-31 23:59:59";

  const trocaBanners111124 = "2024-11-11 00:00:00";  

  const BannerBlueFriday2024 = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerBlueFriday2024}
      backgroundImageMobile={images.bannerBlueFriday2024Mobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerParcelaReduzida = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerParcelaReduzida}
      backgroundImageMobile={images.bannerParcelaReduzidaMobile}
    />
  );

  const BannerParcelaReduzida2 = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerParcelaReduzida2}
      backgroundImageMobile={images.bannerParcelaReduzida2Mobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerSortudaoEncerrado = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerSortudaoEncerrado}
      backgroundImageMobile={images.bannerSortudaoEncerradoMobile}
      href={LINKS.sortudao}
    />
  );

  const BannerSortudaoGanhador = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerSortudaoGanhador}
      backgroundImageMobile={images.bannerSortudaoGanhadorMobile}
      href={LINKS.sortudao}
    />
  );

  const BannerNovoApp = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerNovoApp}
      backgroundImageMobile={images.bannerNovoAppMobile}
    />
  );

  const BannerAutoCompraOfertaConsorcio = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerAutoCompraOfertaConsorcio}
      backgroundImageMobile={images.bannerAutoCompraOfertaConsorcioMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );  

  const BannerLibras = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerLibras}
      backgroundImageMobile={images.bannerLibrasMobile}
    />
  );

  const BannerContratacaoSegura = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerContratacaoSegura}
      backgroundImageMobile={images.bannerContratacaoSeguraMobile}
      href={LINKS.linkContratacaoSegura}
    />
  );

  const BannerCDG = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerCDG}
      backgroundImageMobile={images.bannerCDGMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  return (
    <BannerContents {...props}>
      {publishAndRemoveBanner(trocaBanners111124, removeBannerBlueFriday2024Date) &&
        BannerBlueFriday2024()}
      
      {publishAndRemoveBanner(publishBannerParcelaReduzidaDate, trocaBanners111124) &&
        BannerParcelaReduzida()}

      {publishAndRemoveBanner(trocaBanners111124, removeBannerParcelaReduzida2Date) &&
        BannerParcelaReduzida2()}

      {publishAndRemoveBanner(trocaBanners310824, trocaBanners111124) &&
        BannerSortudaoEncerrado()}

      {publishBanner("show", trocaBanners111124) && BannerSortudaoGanhador()}

      {publishAndRemoveBanner(trocaBanners111124, removeBannerNovoAppDate) &&
        BannerNovoApp()}

      {publishBanner("show", trocaBanners310824) &&
        BannerAutoCompraOfertaConsorcio()}

      {BannerLibras()}

      {BannerCDG()}

      {BannerContratacaoSegura()}
    </BannerContents>
  );
};

BannerConsorcio.defaultProps = { images: {} };

BannerConsorcio.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerConsorcio;
