/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { BiInfoCircle } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import Card from "../shared/cards";
import { Button, Link } from "../shared/actions";
import { applyKey, distinctArray, formatPercent } from "../../utils";
import CsIcon, { download } from "../shared/icons/csicons";
import Modal from "../shared/modal";
import ResultadoAssembleiaContext from "./ResultadoAssembleiaContext";

const PercentuaisLances = ({ tamanho, min, max }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const lanceUnico = tamanho === 1 ? max || min : 0;
  return (
    <>
      <p className="text-center pt-4 d-flex align-items-center justify-content-center">
        <Link
          // className="text-body-md"
          label="Percentuais de contemplação"
          onClick={handleShow}
        />{" "}
        <BiInfoCircle className="text-brand text-body-md ml-2" />
      </p>
      <Modal
        show={showModal}
        size="sm"
        title={<BiInfoCircle className="text-body-md" />}
        className="percentual-contemplacao"
        handleClose={handleClose}
      >
        <div className="text-body-sm">
          <p>
            A oferta do <strong>lance livre</strong> deve ser maior que uma
            parcela mensal. O lance é convertido em percentual e aquele que
            representar o maior valor será o contemplado. Em caso de empate, a
            cota contemplada será a de número mais próximo à cota sorteada.
          </p>
          <p>
            <span className="text-brand">
              Percentual d{lanceUnico ? "a" : "e"} contemplação
            </span>
            <br />
            {lanceUnico ? (
              formatPercent(lanceUnico)
            ) : (
              <>
                menor {formatPercent(min)} - maior {formatPercent(max)}
              </>
            )}
          </p>
        </div>
      </Modal>
    </>
  );
};

PercentuaisLances.defaultProps = { min: 0, max: 0 };

PercentuaisLances.propTypes = {
  tamanho: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

const FooterMaisCotas = ({ cotas, modalidade, isLanceLivre }) => {
  const [show, setShow] = useState(false);
  const [cotasExibidas, setCotasExibidas] = useState(cotas);
  const [searchTerm, setSearchTerm] = useState("");

  const { lanceLivre } = useContext(ResultadoAssembleiaContext);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  function handleChange(evt) {
    const value = Number.parseInt(
      evt.currentTarget.value?.replace(/\D/, ""),
      10
    );

    setSearchTerm(value ? value.toString().padStart(6, "0").substr(0, 6) : "");
  }

  function searhTermHandle() {
    if (searchTerm) {
      const searchTermCota = Number.parseInt(searchTerm, 10);

      setCotasExibidas(() =>
        cotas.filter(({ codCota }) => codCota.includes(searchTermCota))
      );
    }
  }

  useEffect(() => {
    if (!searchTerm && cotas.length !== cotasExibidas.length) {
      setCotasExibidas(cotas);
    }
  }, [searchTerm, cotasExibidas, cotas]);

  const modalFooter = (
    <Button outline className="text-center" onClick={handleClose}>
      Fechar
    </Button>
  );

  return (
    <>
      <Row className="pt-lg-2">
        <Col className="d-flex justify-content-center">
          <Button
            variant={isLanceLivre ? "primary" : "link"}
            outline={isLanceLivre}
            block={!isLanceLivre}
            size={isLanceLivre ? "lg" : "sm"}
            className={`d-flex justify-content-center align-items-center ${
              !isLanceLivre ? "ver-mais" : ""
            }`}
            onClick={handleShow}
          >
            {isLanceLivre ? (
              "Ver cotas contempladas"
            ) : (
              <>
                Ver mais <CsIcon icon={download} />
              </>
            )}
          </Button>
        </Col>
        {isLanceLivre ? (
          <Col className="mt-3">
            <PercentuaisLances
              tamanho={cotas.length}
              min={lanceLivre.lanceMin}
              max={lanceLivre.lanceMax}
            />
          </Col>
        ) : null}
      </Row>
      <Modal
        show={show}
        handleClose={handleClose}
        title={`Confira todas as cotas contempladas por ${modalidade}`}
        titleAs="h3"
        className="modal-cotas"
        closeButton={false}
        size="xl"
        footer={modalFooter}
      >
        <Form.Row>
          <Col lg={{ span: 4, offset: 4 }}>
            <InputGroup className="busca-cotas">
              <Form.Control
                placeholder="Informe o número de uma cota"
                value={searchTerm}
                onChange={handleChange}
              />
              <InputGroup.Append>
                <FaSearch className="search-icon" onClick={searhTermHandle} />
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Row>
        <div className="cota-items d-flex justify-content-start mt-5 px-3 flex-wrap">
          {applyKey(cotasExibidas, ({ codCota, status }) => {
            const [cota] = codCota.split("-");
            return (
              <strong className="text-center numero-cota mx-3 pb-1 mb-5">
                {status === "ativa" ? cota : codCota}
              </strong>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

FooterMaisCotas.defaultProps = {
  isLanceLivre: false,
};

FooterMaisCotas.propTypes = {
  cotas: PropTypes.arrayOf(Object).isRequired,
  modalidade: PropTypes.string.isRequired,
  isLanceLivre: PropTypes.bool,
};

const CotasModalidade = ({ cotas, modalidade }) => {
  if (!cotas?.length) {
    return null;
  }

  const plural = cotas.length > 1 ? "s" : "";

  const header = (
    <p className="text-center">
      <span className="text-brand-secondary">{cotas.length}</span> cota{plural}{" "}
      contemplada{plural} por {modalidade.toLocaleLowerCase()}
    </p>
  );
  const isLanceLivre =
    modalidade.toLocaleLowerCase() === "Lance Livre".toLocaleLowerCase();

  const footer =
    isLanceLivre || cotas.length > 4 ? (
      <FooterMaisCotas
        cotas={cotas}
        modalidade={modalidade}
        isLanceLivre={isLanceLivre}
      />
    ) : null;

  return (
    <Card
      header={header}
      className={`card-cota ${_.kebabCase(modalidade)} m-lg-3 my-3 py-3`}
      footer={footer}
    >
      {!isLanceLivre ? (
        <Row>
          {applyKey(cotas.slice(0, 4), ({ codCota, status }) => {
            const [cota] = codCota.split("-");
            return (
              <Col xs={6}>
                <div className="numero-cota text-center px-2 mx-2 mx-lg-3 my-3">
                  <strong>{status === "ativa" ? cota : codCota}</strong>
                </div>
              </Col>
            );
          })}
        </Row>
      ) : null}
    </Card>
  );
};

CotasModalidade.propTypes = {
  cotas: PropTypes.arrayOf(Object).isRequired,
  modalidade: PropTypes.string.isRequired,
};

const CotasContempladas = ({ cotas, title }) => {
  if (!cotas?.length) {
    return null;
  }

  const modalidadesSorteadas = distinctArray(
    cotas.map(({ nomModalidade }) => nomModalidade)
  );

  return (
    <div className="lista-card-cota pb-3">
      <div className="lista-card-cota-title d-flex justify-content-center py-3 mb-3">
        <h6 className="p-3">{title}</h6>
      </div>
      <Row
        className={
          modalidadesSorteadas.length === 1
            ? "d-flex justify-content-center"
            : null
        }
      >
        {applyKey(modalidadesSorteadas, (modalidade) => (
          <Col xs={12} lg={4}>
            <CotasModalidade
              cotas={cotas.filter((x) => x.nomModalidade === modalidade)}
              modalidade={modalidade}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

CotasContempladas.propTypes = {
  cotas: PropTypes.arrayOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default CotasContempladas;
