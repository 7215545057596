import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import React from "react";
import AccordionComponent from "./Accordion";

const Faq = (props) => {
  return (
    <>
      <AccordionComponent
        {...props}
        name="faq"
        iconShow={FaAngleDown}
        iconHide={FaAngleUp}
      />
    </>
  );
};

export default Faq;
