import _ from "lodash";

const loadEnvironment = () => {
  return Object.keys(process.env)
    .filter((key) => key.includes("REACT_APP_"))
    .map((key) => ({
      [_.camelCase(key.replace("REACT_APP_", ""))]: process.env[key],
    }))
    .reduce((a, b) => ({ ...a, ...b }));
};

export default loadEnvironment();
