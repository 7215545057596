import { endPoints } from "../../config";
import useStaticRequest from "./useStaticRequest";

const useMapaCartilhas = () => {
  const [{ data: mapResult, error, loading }] = useStaticRequest(
    endPoints.getMapaCartilhasEndPoint
  );

  return { mapResult, error, loading };
};

export default useMapaCartilhas;
