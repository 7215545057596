import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

const SectionCard = ({ title, text, align, image, imgRight }) => {
  return (
    <S.Box>
      <S.Container align={align} imgRight={imgRight}>
        {image && <S.ContainerImage image={image}>{image}</S.ContainerImage>}
        <S.ContainerText image={image}>
          <div>
            <S.Title imgRight={imgRight}>{title}</S.Title>
            <S.Underscore />
          </div>
          <S.SectionText>{text}</S.SectionText>
        </S.ContainerText>
      </S.Container>
    </S.Box>
  );
};

SectionCard.defaultProps = { image: undefined, align: false, imgRight: false };

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.objectOf(Object).isRequired,
  align: PropTypes.bool,
  image: PropTypes.objectOf(Object),
  imgRight: PropTypes.bool,
};

export default SectionCard;
