import React, { useEffect } from "react";
import { Page, Section } from "../../../components/shared/pages";
import imagensConsorcio from "../../../asserts/images/consorcios";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import {
  ApresentacaoContemplacao,
  CartilhaContemplados,
  ValorDoCredito,
  BannerConsorcio,
} from "../sections";

const PosContemplacao = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 450);
    }, 500);
  });
  return (
    <Page
      {...props}
      id="page-entrega-bem"
      images={imagensConsorcio}
      banner={BannerConsorcio}
    >
      <SeguridadePageLayout.Tab eventKey="entrega">
        <Section
          title="Como utilizar o seu crédito após a contemplação?"
          as={ApresentacaoContemplacao}
          sectionName="o que fazer com credito"
        />
        <Section
          as={ValorDoCredito}
          sectionLayout={null}
          id="blocos-valores-credito"
          sectionName="credito e dinheiro"
        />
        <Section
          style={{ marginTop: "2rem" }}
          title="Cartilha para contemplados"
          as={CartilhaContemplados}
          sectionLayout={null}
          id="cartilhas-contemplados"
          sectionName="cartilhas contemplados"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default PosContemplacao;
