/* eslint-disable max-len */
import React from "react";

function IconHouse() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 400 400"
      version="1"
      viewBox="50 100 300 200"
      xmlSpace="preserve"
      fill="#035AA9"
    >
      <path d="M229.53 213.43c9.4 0 17.05-7.65 17.05-17.05s-7.65-17.05-17.05-17.05-17.05 7.65-17.05 17.05 7.65 17.05 17.05 17.05zm0-27.1c5.54 0 10.05 4.51 10.05 10.05s-4.51 10.05-10.05 10.05-10.05-4.51-10.05-10.05 4.51-10.05 10.05-10.05z" />
      <path d="M301.75 271.4h-21.17v-89.12c0-6.76-4.24-12.95-10.54-15.4l-66.53-25.8c-3.23-1.25-6.87-.85-9.74 1.09a10.57 10.57 0 00-4.67 8.61l-.06 3.9c-.2.04-.39.09-.58.16l-66.53 25.8c-6.31 2.45-10.54 8.63-10.54 15.4v74.55c0 .28.04.54.1.8H95.51c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h206.24c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.49-3.5-3.49zm-183.38-.8v-74.55c0-3.9 2.44-7.46 6.08-8.87l64.47-25-.91 59.75h-8.96c.58-.63.95-1.46.95-2.38v-19.79c0-1.93-1.57-3.5-3.5-3.5h-33.99c-1.93 0-3.5 1.57-3.5 3.5v22.04c0 .04.01.09.01.13h-8.06c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h60.5c1.91 0 3.47-1.54 3.5-3.45l1.14-74.58c.02-1.19.59-2.25 1.58-2.92.99-.66 2.19-.8 3.29-.37l66.53 25.8c3.63 1.41 6.08 4.97 6.08 8.87v89.12h-25.7v-33.44c0-3.15-2.66-5.72-5.92-5.72h-20.97c-3.26 0-5.92 2.56-5.92 5.72v33.44h-96.79c.05-.26.09-.53.09-.8zm55.58-48.68H146c0-.04.01-.08.01-.13v-18.54H173v16.29c0 .92.36 1.76.95 2.38zm66.93 49.48h-18.81v-32.15h18.81v32.15zM309.38 167.4l-111.2-45.54c-1.79-.73-3.83.12-4.57 1.91-.73 1.79.12 3.83 1.91 4.57l111.2 45.54c.43.18.88.26 1.33.26 1.38 0 2.69-.82 3.24-2.17.73-1.79-.12-3.84-1.91-4.57zM182.02 146.36a3.505 3.505 0 001.83-4.6 3.505 3.505 0 00-4.6-1.83l-88.69 38.28a3.505 3.505 0 00-1.83 4.6 3.502 3.502 0 003.22 2.11c.46 0 .93-.09 1.38-.29l88.69-38.27z" />
    </svg>
  );
}

export default IconHouse;
