import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";

const BannerOuvidoria = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerOuvidoria}
          className="text-white  ouvidoria-banner"
        >
          <Row>
            <Col>
              <Title>Ouvidoria</Title>
              <p>
                Se o atendimento em outros canais não foi satisfatório, nós
                podemos rever o seu pedido e juntos encontrarmos uma solução.
              </p>
            </Col>
          </Row>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

const Title = styled.h4`
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

BannerOuvidoria.defaultProps = { images: {} };

BannerOuvidoria.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerOuvidoria;
