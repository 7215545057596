import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Link from "./Link";
import { useFormatPhone } from "../../../hooks";

const PhoneLink = (props) => {
  const { phone, button, className } = props;

  const phoneValue = phone.replace(/\D/gi, "");

  const formatedPhone = useFormatPhone(phoneValue);

  const phoneProps = {
    ...props,
    href: `tel:${phone}`,
    className: `${className} d-lg-none`,
  };

  return (
    <>
      {button ? (
        <Button {...phoneProps}>{formatedPhone}</Button>
      ) : (
        <Link {...phoneProps}>{formatedPhone}</Link>
      )}
      <strong className="d-none d-lg-inline">{formatedPhone}</strong>
    </>
  );
};

PhoneLink.defaultProps = {
  ...Button.defaultProps,
  ...Link.defaultProps,
  button: false,
};

PhoneLink.propTypes = {
  ...Button.propTypes,
  ...Link.propTypes,
  phone: PropTypes.string.isRequired,
  button: PropTypes.bool,
};

export default PhoneLink;
