/* eslint react-hooks/exhaustive-deps: 0 */

import { useState, useEffect, useCallback } from "react";
import axios from "axios";

import useAuthToken from "./useAuthToken";
import useLogin from "./useLogin";
import useCache from "./useCache";
import envs from "../../config/envs";

export const GET = "get";
export const HEAD = "head";
export const POST = "post";
export const PUT = "put";
export const DELETE = "delete";
export const OPTIONS = "options";
export const PATCH = "patch";

const useAjaxRequest = (
  itemName = "",
  cacheConfig = {
    shouldCache: false,
    isCacheValid: () => false,
  },
  options = "",
  initialValue = {}
) => {
  const [response, setResponse] = useState({
    data: initialValue,
    loading: !!options,
    error: null,
  });
  const [lastOptions, setLastOptions] = useState(options);
  const { getToken, refreshToken } = useAuthToken();
  const {
    token: [isCachedLoginToken, setLoginToken, getLoginToken],
  } = useLogin();
  const [isCached, putCache, getCache] = useCache(itemName);
  const [retry, setRetry] = useState(0);

  const performRequest = useCallback(
    async (asyncOptions) => {
      setLastOptions(asyncOptions);

      const accessToken = await getToken();

      try {
        if (
          isCached &&
          cacheConfig.isCacheValid(getCache(), asyncOptions, accessToken)
        ) {
          const { data: cacheData } = getCache();
          setResponse({
            data: cacheData,
            loading: false,
          });
          return cacheData;
        }

        setResponse((prevResponse) => ({
          ...prevResponse,
          loading: true,
        }));

        const { data } = await axios.request({
          ...asyncOptions,
          headers: {
            ...asyncOptions.headers,
            Authorization: `Bearer ${
              isCachedLoginToken ? getLoginToken() : accessToken
            }`,
            "x-ibm-client-id": envs.clientId,
            "content-type": "application/json",
            "X-Robots-Tag": envs.robots,
          },
        });

        if (cacheConfig.shouldCache) {
          putCache(data, {
            ...asyncOptions,
          });
        }

        setResponse({
          data,
          loading: false,
        });
        return data;
      } catch (err) {
        if (
          err.response?.status === 401 &&
          retry < parseInt(envs.maxRetry, 10)
        ) {
          setRetry(retry + 1);
          if (isCachedLoginToken) {
            setResponse({
              ...response,
              loading: false,
              error: err,
            });
            setLoginToken(null);
            return {};
          }

          refreshToken();
        }
        setResponse({
          ...response,
          loading: false,
          error: err,
        });
      }
      return {};
    },
    [options, refreshToken]
  );

  useEffect(() => {
    if (options || lastOptions) {
      performRequest(lastOptions);
    }
  }, [performRequest, options]);

  return { performRequest, response };
};

export default useAjaxRequest;
