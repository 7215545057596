import React, { useEffect, useRef, useState } from "react";
import animatedScroll from "animated-scroll-to";
import PropTypes from "prop-types";
import PageLayouts from "../../layout/pageLayouts";
import PageNotFound from "./PageNotFound";
import PageError from "./PageError";

const Page = (props) => {
  const {
    banner: Banner,
    images,
    children,
    className,
    pageLayout,
    as: Component,
  } = props;

  const [groupBanner, setGroupBanner] = useState(null);

  const childrenWithProps = React.Children.map(children, (el) =>
    React.cloneElement(el, {
      images,
      groupBanner,
      setGroupBanner,
    })
  );

  const pageLayoutContent = React.createElement(
    pageLayout,
    {
      ...props,
      images,
      groupBanner,
      setGroupBanner,
    },
    Component ? (
      <Component {...props}>{childrenWithProps}</Component>
    ) : (
      childrenWithProps
    )
  );

  const ref = useRef(null);

  useEffect(() => {
    animatedScroll(ref.current, {
      verticalOffset: -300,
    });
  }, [ref.current]);

  return (
    <article ref={ref} className={className}>
      {Banner && (
        <>
          <Banner
            images={images}
            groupFilter={groupBanner}
            setGroupBanner={setGroupBanner}
          />
        </>
      )}
      {pageLayoutContent}
    </article>
  );
};

Page.Error = ({ errorProps, pageProps }) =>
  React.createElement(Page, pageProps, <PageError {...{ ...errorProps }} />);

Page.NotFound = ({ errorProps, pageProps }) =>
  React.createElement(Page, pageProps, <PageNotFound {...{ ...errorProps }} />);

Page.Error.defaultProps = {
  errorProps: null,
  pageProps: null,
};

Page.Error.propTypes = {
  errorProps: PropTypes.objectOf(Object),
  pageProps: PropTypes.objectOf(Object),
};

Page.NotFound.defaultProps = Page.Error.defaultProps;
Page.NotFound.propTypes = Page.Error.propTypes;

Page.defaultProps = {
  pageTitle: "Página",
  banner: null,
  images: {},
  to: "/",
  as: null,
  className: "default-page",
  pageLayout: PageLayouts.SeguridadePageLayout,
};

Page.propTypes = {
  pageTitle: PropTypes.string,
  as: PropTypes.func,
  banner: PropTypes.func,
  images: PropTypes.objectOf(Object),
  to: PropTypes.string,
  className: PropTypes.string,
  pageLayout: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
};

export default Page;
