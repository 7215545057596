import React from "react";
import PropTypes from "prop-types";
import "./accessibilityStyle.css";
import EyeIcon from "../../../asserts/images/Olho.svg";
import Libras from "../../../asserts/images/libras.svg";
import AccessibilitySAC from "../../../asserts/images/acessibilidadeSAC.svg";

const AccessibilityCards = ({ translator, accessibilityVisual, onClose }) => {
  return (
    <div className="accessibilityCards">
      <div
        className="accessibilityCard"
        onClick={() => {
          onClose();
          translator();
        }}
        onKeyDown={() => {
          onClose();
          translator();
        }}
        role="button"
        tabIndex="0"
      >
        <Libras />
        <p>Acessível em Libras</p>
      </div>
      <div
        className="accessibilityCard"
        onClick={() => {
          onClose();
          accessibilityVisual();
        }}
        onKeyDown={() => {
          onClose();
          accessibilityVisual();
        }}
        role="button"
        tabIndex="0"
      >
        <EyeIcon />
        <p>Recursos Assistivos</p>
      </div>
      <div>
        <a
          className="accessibilityCard"
          href="https://icom.app/AokfL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AccessibilitySAC />
          <p>SAC Libras</p>
        </a>
      </div>
    </div>
  );
};

AccessibilityCards.propTypes = {
  translator: PropTypes.func.isRequired,
  accessibilityVisual: PropTypes.func.isRequired,
  onClose: PropTypes.bool.isRequired,
};

export default AccessibilityCards;
