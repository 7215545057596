import envs from "../config/envs";

export const generateBase64 = (file, setAnexo, setError) => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = function Success() {
    setAnexo({
      nome: file.name,
      conteudo: btoa(reader.result),
      tamanho: file.size,
    });
  };
  reader.onerror = function Error() {
    setError("Ocorreu um problema ao subir o arquivo");
  };
};
export const acceptedFiles = envs.acceptedFiles.split(", ");

export const validateFile = (file, setError) => {
  let isValid = true;
  const ext = `.${/[^.]+$/.exec(file.name)[0]?.toUpperCase()}`;
  if (file.size > 600000) {
    setError(
      "Você anexou um arquivo maior que 600kb. Por favor, redimensione o arquivo."
    );
    isValid = false;
  } else if (!acceptedFiles.includes(ext)) {
    setError(
      `Você anexou um arquivo com a extensão diferente de: ${envs.acceptedFiles}.`
    );
    isValid = false;
  }
  return isValid;
};
