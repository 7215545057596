import React from "react";
import { Accordion as BsAccordion } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaMinus, FaPlus } from "react-icons/fa";
import { VARIANT_COLORS } from "@constants";
import { useMakeClass } from "../../../hooks";

const Accordion = (props) => {
  const {
    children,
    name,
    id,
    className,
    iconShow,
    iconHide,
    variant,
    verticalOffset,
  } = props;

  const cards = React.Children.map(children, (card, key) => {
    return React.cloneElement(card, {
      eventKey: key + 1,
      iconShow,
      iconHide,
      variant,
      verticalOffset,
    });
  });

  const classes = useMakeClass(
    "cvp-accordion",
    name && `accordion-${name}`,
    variant && `accordion-${variant}`,
    className
  );

  return (
    <BsAccordion className={classes} id={id}>
      {cards}
    </BsAccordion>
  );
};

Accordion.defaultProps = {
  iconShow: FaPlus,
  iconHide: FaMinus,
  name: null,
  className: "",
  id: null,
  variant: "primary",
  verticalOffset: -165,
};

Accordion.propTypes = {
  name: PropTypes.string,
  iconShow: PropTypes.string,
  iconHide: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.oneOf([...VARIANT_COLORS], "seguridade-light"),
  verticalOffset: PropTypes.number,
};

export default Accordion;
