import React from "react";
import { Section } from "@components/shared/pages";
import imagensConsorcio from "../../../asserts/images/consorcios";
import { Information } from "../../../components/blocosCustomizados";

export const ParticipeAgora = ({ sectionTitle }) => {
  const conhecaVantagens = [
    {
      image: imagensConsorcio.iconAquisicaoConsorcio,
      title: "Faça a aquisição de seu Consórcio",
      description: (
        <>
          <p className="descriptionInformation">
            Contrate qualquer Consórcio da CAIXA Consórcio entre os dias{" "}
            <b className="boldCaixa">01/07/2024 a 31/08/2024</b>. Quanto mais
            você contratar, mais números da sorte terá.
          </p>
        </>
      ),
      col: { lg: 4 },
    },
    {
      image: imagensConsorcio.iconNumeroSorte,
      title: "Espere pelo seu número da sorte",
      description:
        "Ao adquirir uma cota, você ganha um número para concorrer ao dinheirão. Fique atento ao seu contrato cadastrado e aguarde o tão sonhado número da sorte.",
      col: { lg: 4 },
    },
    {
      image: imagensConsorcio.iconSejaSortudao,
      title: "Seja um Sortudão",
      description: (
        <>
          <p className="descriptionInformation">
            Fique na torcida do prêmio de{" "}
            <b className="boldCaixa">R$ 100 mil</b> para ficar numa boa e
            aproveitar esse dinheirão. Você merece!
          </p>
        </>
      ),
      col: { lg: 4 },
    },
  ];

  return (
    <Information
      sectionTitle={sectionTitle}
      items={conhecaVantagens}
      className="cvp-benefit-image w-100 image-100"
    />
  );
};

ParticipeAgora.propTypes = {
  ...Section.specializedProps,
};
export default ParticipeAgora;
