import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Nav } from "react-bootstrap";
import { Link } from "@components/shared/actions";
import TabPane from "./TabPane";
import { applyKey } from "../../../../../utils";
import { TabRouteContext } from "../../../../../context";

const NavPageTab = ({ tabs }) => {
  const [navItens, setNavItens] = useState(null);

  const { navKey, setKey, contentKey } = useContext(TabRouteContext);

  useEffect(() => {
    if (tabs) {
      const navs = tabs.filter(({ label }) => label);
      setNavItens(navs);
    }
    return () => {
      setNavItens(null);
    };
  }, [setNavItens, tabs]);

  const activeTabKey =
    navItens
      ?.map(({ eventKey }) => eventKey)
      .find((eventKey) => eventKey === contentKey) || navKey;

  const navTabContents = applyKey(navItens, (navProps) => {
    const { eventKey, label } = navProps;
    return React.createElement(
      Nav.Item,
      {
        as: Link,
        onClick: () => setKey({ key: eventKey }),
        className: `ml-0 mr-2 my-2 mr-sm-3 pb-3 px-1 ${
          activeTabKey === eventKey ? " active" : ""
        }`,
      },
      label
    );
  });
  return (
    <Container fluid>
      <Nav
        variant="tabs"
        defaultActiveKey={navKey}
        className="page-layout-tab px-2 pb-3 border-0 d-flex"
      >
        {navTabContents}
      </Nav>
    </Container>
  );
};

NavPageTab.defaultProps = {};

NavPageTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.objectOf(TabPane)).isRequired,
};

export default NavPageTab;
