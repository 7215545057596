import { Page } from "../../components/shared/pages";

const useSegmentRoute = (routesConfig) => {
  if (!routesConfig || !routesConfig.length) {
    return { main: Page.NotFound };
  }

  const { component: main } =
    routesConfig.find((item) => item.default) || routesConfig.shift();
  const routes = [...routesConfig.filter((item) => !item.default)];

  return {
    routes,
    main,
  };
};

export default useSegmentRoute;
