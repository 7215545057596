import React from "react";
import applyKey from "./applyKey";

const defaultConfig = {
  propValue: "value",
  propLabel: "label",
  placeHolder: "Selecione",
};

function getItemValue(prop) {
  return (prop && this[prop]) || this;
}

export const mapToOptions = (elementArray, config) => {
  const { propValue, propLabel, isSelected } = { ...defaultConfig, ...config };

  function getItemValueHandle(item) {
    const getValue = getItemValue.bind(item);
    const label = getValue(propLabel || "label");
    const value = getValue(propValue || "value");
    const selected = !!(isSelected && isSelected(item));
    return { ...item, label: label || value, value, selected };
  }

  return (elementArray || []).map(getItemValueHandle);
};

export const createOptionList = (
  list,
  defaultOption = { value: "", label: "Selecione" }
) => {
  if (!Array.isArray(list)) {
    return null;
  }

  return applyKey(
    [{ ...defaultOption }].concat(list),
    ({ value, label, selected }) =>
      React.createElement("option", { selected, value }, label)
  );
};

export const createElementListItems = ({ list, itemCallBack, itemProps }) => {
  if (!Array.isArray(list)) {
    return null;
  }

  return applyKey(list, (item) =>
    React.createElement(
      "li",
      { ...itemProps },
      itemCallBack ? itemCallBack(item) : item
    )
  );
};
