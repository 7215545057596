import "./styles";
import Cookies from "js-cookies";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Header, Main, Footer } from "@components/shared/layout";
import HeaderSeguridade from "../components/headers";
import FooterSeguridade from "../components/footers";
import { RedirectRoutes } from "../components/shared/routes";
import { Consorcios, Page404, Ouvidoria } from "../areas";
import { redirects } from "../config";
import CookiesNotification from "../components/cookies/CookiesNotification";
import CookiesModal from "../components/cookies/CookiesModal";
import DSConfig from "./DSConfig";

const App = () => {
  const [showCookiesNotification, setShowCookiesNotification] = useState(
    !(
      Cookies.getItem("cookies_analiticos") ||
      Cookies.getItem("cookies_marketing")
    )
  );

  const [showCookiesModal, setShowCookiesModal] = useState(false);

  const handleAcceptCookies = (analitico, marketing) => {
    setShowCookiesNotification(false);
    setShowCookiesModal(false);
    Cookies.setItem("cookies_analiticos", analitico, { expires: 365 });
    Cookies.setItem("cookies_marketing", marketing, { expires: 365 });
  };

  const script = document.createElement("script");
  script.src = "https://plugin.handtalk.me/web/latest/handtalk.min.js";
  script.async = true;
  script.onload = () => {
    if (window.HT) {
      window.ht = new window.HT({
        token: "c5f9109c90cc77d17ef6e19b114b2dbc",
        side: "left",
        opacity: 20,
      });
    }
  };
  document.body.appendChild(script);

  const handleCustomizeCookies = () => {
    setShowCookiesModal(true);
  };

  const handleCloseModal = () => {
    setShowCookiesModal(false);
  };

  return (
    <div className="site-wrapper">
      <DSConfig>
        {showCookiesNotification && (
          <CookiesNotification
            onAccept={handleAcceptCookies}
            onCustomize={handleCustomizeCookies}
          />
        )}
        {showCookiesModal && (
          <CookiesModal
            onSave={handleAcceptCookies}
            onClose={handleCloseModal}
          />
        )}
        <Header component={HeaderSeguridade} />
        <Main>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/consorcio" />
            </Route>
            <Route path="/consorcio" component={Consorcios} />
            <Route path="/ouvidoria" component={Ouvidoria} />
            <Route path="/404" component={Page404} />
            <Redirect from="*" to="/404" />
            <RedirectRoutes redirects={redirects} />
          </Switch>
        </Main>
        <Footer component={FooterSeguridade} />
      </DSConfig>
    </div>
  );
};

export default App;
