import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ErrorText from "./styles";
import { PhoneLink } from "../../../../components/shared/actions";

const errorMessages = {
  statusCode400: "O Seu protoloco não é um número válido",
  statusCode404: "O seu protoloco não foi localizado",
};

const ProtocolStatusError = ({ errorCode, isLoadingProtocol }) => {
  const history = useHistory();
  if (isLoadingProtocol || !errorCode) {
    return null;
  }
  if (errorCode === 403) {
    history.push("/ouvidoria/pendente");
  }
  return (
    <ErrorText>
      {errorMessages[`statusCode${errorCode}`]}.
      <br />
      Confira se você digitou os números corretamente. Se você ainda não possui
      uma solicitação registrada, entre em contato com a nossa Central de
      Atendimento pelos telefones abaixo:
      <br />
      <PhoneLink phone="3004 4000" /> - Capitais e regiões metropolitanas com o
      custo de uma ligação local.
      <br />
      <PhoneLink phone="0800 975 1000" /> - Para as demais regiões do Brasil a
      ligação é gratuita.
    </ErrorText>
  );
};

ProtocolStatusError.defaultProps = {
  errorCode: 0,
  isLoadingProtocol: false,
};

ProtocolStatusError.propTypes = {
  errorCode: PropTypes.number,
  isLoadingProtocol: PropTypes.bool,
};

export default ProtocolStatusError;
