import React, { useEffect } from "react";
import * as S from "./style";
import SectionCard from "../../../components/shared/SectionCard";
import WarningFraud from "../../../asserts/images/consorcios/cuidado-fraude.png";
import PhoneFraud from "../../../asserts/images/consorcios/celular-fraude.png";
import PersonFraud from "../../../asserts/images/consorcios/pessoa-fraude.png";
import BuyingFraud from "../../../asserts/images/consorcios/pessoas-transferindo.png";
import Family from "../../../asserts/images/consorcios/familia-com-casa-amarela.png";
import Positivity from "../../../asserts/images/consorcios/pessoas-positivas.png";
import ToDo from "../../../asserts/images/consorcios/mulher-com-lapis.png";
import { envs } from "../../../config";

const Security = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  });
  return (
    <>
      <S.Background>
        <S.Container>
          <S.TitleText>Como evitar </S.TitleText>
          <S.SubtitleText>fraudes no Consórcio?</S.SubtitleText>
        </S.Container>
      </S.Background>
      <S.Box>
        <SectionCard
          align
          title="Fraude no Consórcio"
          image={
            <>
              <img loading="lazy" src={WarningFraud} alt="cuidado-fraude" />
            </>
          }
          text={
            <>
              <p>
                O consórcio é uma modalidade de investimento, por isso, nossa
                missão é garantir que você esteja atento a informações
                essenciais na hora de contratar a sua carta de crédito e ter a
                mellhor experiência durante a jornada de realização dos seus
                sonhos.
              </p>
              <p>
                Fique atento a algumas dicas para que a contratação de seu
                consórcio seja segura e transparente. E, o mais importante, para
                não cair em casos de fraude.
              </p>
            </>
          }
        />
        <SectionCard
          align
          imgRight
          title="Seu Consórcio Mais Seguro"
          image={
            <>
              <img loading="lazy" src={PhoneFraud} alt="telefone-fraude" />
              <img loading="lazy" src={PersonFraud} alt="pessoa-fraude" />
            </>
          }
          text={
            <>
              <b>Sem Exigência de Entrada e Pagamento Antecipado de Lances:</b>
              <p>
                No ato da contratação do seu consórcio, será cobrado o valor
                correspondente a primeira parcela da sua cota, valor esse que
                deverá ser pago através de débito em conta ou boleto bancário
                tendo como favorecido a XS5 Administradora de Consórcios S.A.
                (CAIXA Consórcio), CNPJ: {envs.cnpj}. Não exigimos qualquer
                pagamento de &quot;valor de entrada&quot; ou &quot;pagamento
                antecipado de lance&quot;. Valores devidos a título de lance
                deverão ser pagos após a confirmação pela CAIXA Consórcio de que
                o lance ofertado foi vencedor.
              </p>
              <b>Desconfie de Solicitações de Pagamento Adiantado:</b>
              <p>
                A CAIXA Consórcio não trabalha com empresas de intermediação ou
                assessoria financeira. Por este motivo, não realize pagamentos
                em conta corrente de titularidade de terceiros. Essa prática não
                condiz com nossa política transparente, podendo indicar uma
                possível fraude. Para sua segurança, orientamos que verifique em
                nosso site se o parceiro que está lhe auxiliando no processo de
                aquisição da sua cota de consórcio é realmente um correspondente
                CAIXA em:{" "}
                <a
                  href="https://www.caixa.gov.br/atendimento/Paginas/encontre-a-caixa.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.caixa.gov.br/atendimento/Paginas/encontre-a-caixa.aspx
                </a>
              </p>
            </>
          }
        />
        <SectionCard
          align
          title="Fique Atento!"
          text={
            <>
              <p>
                Ao efetuar o pagamento de boletos, é essencial adotar medidas
                preventivas para evitar possíveis fraudes. Temos algumas dicas
                valiosas para garantir a segurança nos seus pagamentos:
              </p>
              <b>Beneficiário:</b>
              <p>
                Sempre confira se a <b>XS5 Administradora de Consórcios S.A.</b>{" "}
                está identificada como beneficiária nos boletos recebidos. Essa
                verificação é uma camada extra de segurança.
              </p>

              <b>Formas de Pagamento aceitas:</b>
              <p>Boleto e débito automático.</p>

              <b>Segurança:</b>
              <p>
                Confira o valor da parcela, a data de vencimento e a linha
                digitável. Nossos boletos sempre iniciam com <b>104-0</b>,
                assegurando a identificação correta. Realizamos o envio do
                boleto por email e linha digitável por SMS cadastrado, por isso,
                mantenha sempre seus dados cadastrais atualizados.
              </p>

              <b>Débito Automático para Tranquilidade:</b>
              <p>
                Para uma experiência mais segura e sem preocupações, considere
                incluir sua forma de pagamento em débito automático. Acesse sua
                Área do Cliente para habilitar essa opção e simplificar seus
                pagamentos.
              </p>
            </>
          }
        />
        <SectionCard
          align
          title="Compra de Cota Contemplada"
          image={
            <>
              <img
                loading="lazy"
                src={BuyingFraud}
                alt="compra-cota-contemplada-fraude"
              />
            </>
          }
          text={
            <>
              <p>
                A compra de uma cota contemplada é permitida, porém, conforme
                orientação da Associação Brasileira de Administradoras de
                Consórcios (ABAC), certifique-se de que a cota está realmente
                contemplada. Solicite a declaração da administradora de
                consórcios confirmando a contemplação, valor do crédito, a data
                de contemplação e o rendimento financeiro que está acumulando no
                crédito desde essa data. Solicite também que na declaração
                constem informações sobre as garantias que deverão ser
                apresentadas para a utilização do crédito.
              </p>
              <p>
                Após a contemplação de sua cota para aquisição de sua carta
                crédito, necessariamente, você precisa estar em dia com seus
                parcelamentos e seu cadastro precisa ser aprovado nos termos da
                política de crédito da CAIXA Consórcio.
              </p>
              <p>
                Você também pode conferir mais dicas e detalhes em nossa
                cartilha{" "}
                <a
                  href="https://static.caixaconsorcio.com.br/Marketing/Cartilha_AntiFraude.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  clicando aqui
                </a>
              </p>
            </>
          }
        />
        <SectionCard
          align
          imgRight
          title="Venda de Imóvel Retomado de Financiamento"
          image={
            <>
              <img loading="lazy" src={Family} alt="imóvel-financiado-fraude" />
            </>
          }
          text={
            <>
              <p>
                Fique atento com anúncios de imóveis divulgados em redes sociais
                ou em plataformas de venda de imóveis, com valores de venda
                abaixo do mercado, sob o argumento de que se trata de um imóvel
                retomado de financiamento bancário. NÃO realize nenhum pagamento
                (PIX, Transferência ou Depósito) em conta bancária de terceiros,
                referentes a valores cobrados em título de reserva do imóvel
                anunciado.
              </p>
            </>
          }
        />
        <SectionCard
          align
          title="Confira as Dicas da ABAC"
          image={
            <>
              <img loading="lazy" src={Positivity} alt="positividade" />
            </>
          }
          text={
            <>
              <p>
                A Associação Brasileira de Administradoras de Consórcios (ABAC),
                é uma referência em boas práticas na modalidade e atua como
                interlocutora das administradoras de consórcios associadas. Além
                disso, disponibiliza dicas, conteúdos e informações essenciais
                para esclarecer dúvidas e proteger você de possíveis fraudes.
                Consulte em:{" "}
                <a href="https://abac.org.br/" target="_blank" rel="noreferrer">
                  https://abac.org.br/
                </a>
                .
              </p>
            </>
          }
        />
        <SectionCard
          align
          imgRight
          title="Transforme Seus Sonhos em Realidade"
          image={
            <>
              <img loading="lazy" src={ToDo} alt="sonho-em-realidade" />
            </>
          }
          text={
            <>
              <p>
                Transforme seus planos em realidade com a confiança que você
                merece. Conte com a credibilidade e expertise da CAIXA Consórcio
                para acompanhar a sua jornada na aquisição do bem com segurança.
              </p>
            </>
          }
        />
      </S.Box>
    </>
  );
};

export default Security;
