import { envs } from "../config";

const formatPercent = (number, locale, options) =>
  (number || 0).toLocaleString(locale || envs.locale, {
    ...{
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    ...options,
  });

export default formatPercent;
