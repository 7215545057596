import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { Col, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Apresentacao } from "../../../components/blocosCustomizados";
import { applyKey, parseISO } from "../../../utils";
import { Button } from "../../../components/shared/actions";
import { useCalendarioAssembleia } from "../../../hooks";

const DataColumn = ({ data, display }) => {
  return (
    <Col
      xs={12}
      lg={6}
      className={`mb-2${!display ? " d-none" : ""} d-lg-block`}
    >
      <div className="data-assembleia">
        {format(data, "dd/MM EEEE 'às' HH'h'", {
          locale: ptBR,
          timeZone: "America/Sao_Paulo",
        })}
      </div>
    </Col>
  );
};

DataColumn.defaultProps = { display: true };

DataColumn.propTypes = {
  data: PropTypes.instanceOf(Date).isRequired,
  display: PropTypes.bool,
};

const ProximasAssembleias = ({ images }) => {
  const [viewMore, setViewMore] = useState(false);
  const [datas, setDatas] = useState([]);
  const [tipoConsorcio, setTipoConsorcio] = useState("imob");

  const { calendarioAssembleia } = useCalendarioAssembleia();

  const sectionTitle = `Próximas assembleias de ${calendarioAssembleia?.ano}`;

  useEffect(() => {
    if (calendarioAssembleia) {
      const currentMoth = new Date().getMonth();
      const items = " "
        .padEnd(12, ".")
        .split(".")
        .map((a, idx) => ({
          imob: parseISO(calendarioAssembleia?.imob[idx]),
          veic: parseISO(calendarioAssembleia?.veic[idx]),
        }))
        .filter((a, idx) => viewMore || idx === currentMoth);

      setDatas(items);
    }
  }, [viewMore, calendarioAssembleia]);

  return (
    <>
      <Apresentacao
        title={sectionTitle}
        image={images.ilustracaoProximasAssembleias}
        imageColumn={{
          className:
            "d-none d-lg-flex justify-content-center align-items-start",
        }}
        titleAs="h1"
      >
        <Row className="d-flex d-lg-none text-center justify-items-center">
          <Col>
            <h6 className="mb-4">Qual o tipo do seu consórcio?</h6>
            <Button
              className={tipoConsorcio === "imob" ? "active" : null}
              onClick={() => setTipoConsorcio("imob")}
              outline
              block
              label="Imobiliário"
            />
            <Button
              className={tipoConsorcio === "veic" ? "active" : null}
              onClick={() => setTipoConsorcio("veic")}
              outline
              block
              label="Veículos"
            />
            <p>A próxima assembleia acontecerá em:</p>
          </Col>
        </Row>
        <Row className="pb-4 text-brand-secondary d-none d-lg-flex">
          <Col>
            <strong>Consórcio Imobiliário</strong>
          </Col>
          <Col>
            <strong>Consórcio Veículos Leves/Pesados</strong>
          </Col>
        </Row>
        <Row className="py-3">
          {applyKey(datas, (data) => (
            <>
              <DataColumn display={tipoConsorcio === "imob"} data={data.imob} />
              <DataColumn display={tipoConsorcio === "veic"} data={data.veic} />
            </>
          ))}
        </Row>
      </Apresentacao>
      <Row className="border-top">
        <Col xs={12}>
          <Button
            size="md"
            variant=""
            block
            onClick={() => {
              setViewMore(!viewMore);
              if (viewMore) {
                document
                  .getElementById("proximas-assembleias")
                  .scrollIntoView();
              }
            }}
            className={`text-brand toggle-link focus-shadow-none d-flex ${
              viewMore ? "flex-column-reverse" : "flex-column"
            } align-items-center`}
          >
            <div>{viewMore ? "Ver menos" : "Ver próximas datas"}</div>
            {React.createElement(viewMore ? FaAngleUp : FaAngleDown, {
              className: "toggle-icon",
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};

ProximasAssembleias.propTypes = {
  images: PropTypes.objectOf(Object).isRequired,
};

export default ProximasAssembleias;
