import envs from "../envs";

export const getAuthTokenEndPoint = `${envs.hostApic}/oauth-end/oauth2/token`;
export const getLoginEndPoint = `${envs.hostApic}/public/oauth2/token`;

export const getLgpdConsentEndPoint = (businessProcess, cpf) =>
  `${envs.hostApic}/lgpd/v1/consentimentos/processo-negocio/${businessProcess}/pessoas/${cpf}`;

export const getResultadosAssembeiaEndPoint = (grupo, mes, ano) =>
  `${envs.hostApic}/consorcios-caixa/v1/assembleias/grupos/${grupo}/cotas?mes=${mes}&ano=${ano}`;

export const consultCEPEndPoint = (CEP) =>
  `${envs.hostApic}/rs/v1.0/corporativo/cep/${CEP}`;

export const checarProtocoloAberturaOuvidoriaEndPoint = ({
  produto,
  protocolo: numProtocolo,
}) =>
  `${envs.hostApic}/area-aberta/cxc/v1/ouvidoria/protocolos/${produto}/protocolo/${numProtocolo}`;

export const criarOuvidoria = `${envs.hostApic}/area-aberta/cxc/v1/ouvidoria/protocolos/`;
