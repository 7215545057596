import React from "react";
import PropTypes from "prop-types";
import { Apresentacao } from "../../../components/blocosCustomizados";

const ApresentacaoConsorcio = ({ images, sectionTitle }) => {
  return (
    <Apresentacao image={images.entendaConsorcios} title={sectionTitle}>
      <div className="pl-2">
        <p>
          O consórcio é uma modalidade de compra coletiva, onde um grupo de
          pessoas se compromete a pagar uma parcela mensal, por um tempo
          determinado. Esse dinheiro é guardado em um fundo comum e, todo mês,
          alguns integrantes do grupo são escolhidos (por sorteio e por lance)
          para receber o valor do crédito e comprar o bem.
        </p>
        <p>
          Conquiste sua casa, carro, ônibus, caminhão ou máquina agrícola com o
          consórcio da CAIXA!
        </p>
      </div>
    </Apresentacao>
  );
};

ApresentacaoConsorcio.propTypes = {
  images: PropTypes.objectOf(Object).isRequired,
  sectionTitle: PropTypes.string.isRequired,
};

export default ApresentacaoConsorcio;
