import styled from "styled-components";

const IMAGELENGTH = (image) => image?.props?.children?.length;

export const Box = styled.div(({ theme }) => ({
  display: "flex",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "1140px",
  [theme.breakpoint.lg()]: {},
}));

export const Container = styled.div(({ theme, align, imgRight }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: `${align ? "center" : "normal"}`,
  gap: "12px",
  margin: "12px 0",
  borderRadius: "12px",
  padding: "20px !important",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px 0px rgba(51,51,51,0.62)",
  [theme.breakpoint.lg()]: {
    margin: "24px 0",
    padding: "40px 20px 25px 35px !important",
    flexDirection: imgRight ? "row-reverse" : "row",
  },
}));

export const ContainerImage = styled.div(({ theme, image }) => ({
  order: 1,
  display: "flex",
  flexDirection: "row",
  width: IMAGELENGTH(image) >= 2 ? "60%" : "100%",
  justifyContent: "center",
  img: {
    width: "80%",
    maxWidth: IMAGELENGTH(image) >= 2 ? "300px" : "400px",
  },
  [theme.breakpoint.lg()]: {
    order: 0,
    flexDirection: "column",
    width: "35%",
    img: {
      width: "100%",
      maxWidth: IMAGELENGTH(image) >= 2 ? "250px" : "400px",
    },
  },
}));

export const ContainerText = styled.div(({ theme, image }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  fontSize: "18px",
  [theme.breakpoint.lg()]: {
    width: image ? "56%" : "100%",
    minHeight: "300px",
  },
}));

export const Title = styled.h1(({ theme, imgRight }) => ({
  position: "relative",
  width: "100%",
  fontSize: "36px",
  fontFamily: "CAIXA Std",
  fontWeight: 700,
  color: "#005FC8",
  zIndex: "1",
  [theme.breakpoint.xl()]: {
    width: imgRight ? "140%" : "100%",
    fontSize: "40px",
    flexDirection: "row",
  },
}));

export const Underscore = styled.div(({ theme }) => ({
  borderBottom: "8px solid #f39200",
  marginBottom: "28px",
  marginLeft: "1px",
  width: "50px",
  [theme.breakpoint.lg()]: {},
}));

export const SectionText = styled.div(({ theme }) => ({
  width: "100%",
  fontSize: "18px",
  [theme.breakpoint.lg()]: {
    fontSize: "18px",
    flexDirection: "row",
  },
}));
