
export const JANEIRO = 'Janeiro';
export const FEVEREIRO = 'Fevereiro';
export const MARCO = 'Março';
export const ABRIL = 'Abril';
export const MAIO = 'Maio';
export const JUNHO = 'Junho';
export const JULHO = 'Julho';
export const AGOSTO = 'Agosto';
export const SETEMBRO = 'Setembro';
export const OUTUBRO = 'Outubro';
export const NOVEMBRO = 'Novembro';
export const DEZEMBRO = 'Dezembro';

export default [
  JANEIRO,
  FEVEREIRO,
  MARCO,
  ABRIL,
  MAIO,
  JUNHO,
  JULHO,
  AGOSTO,
  SETEMBRO,
  OUTUBRO,
  NOVEMBRO,
  DEZEMBRO,
];
