import React from "react";
import PropTypes from "prop-types";
import { CharactersLeft } from "../styles";
import { TextAreaControl } from "../../../../../components/shared/forms";

const DetailsInput = ({ pedido, formikBag }) => {
  const handlePedido = (e) => {
    if (e.target.value.length <= 5000) {
      formikBag.onChange(e);
    }
  };
  return (
    <>
      <TextAreaControl
        style={{ maxWidth: "920px", marginBottom: 0 }}
        controlId="formOuvidoria.ouvidoria.pedido"
        label="Escreva com detalhes a sua ocorrência. Seja o mais claro possível para que possamos entender o seu pedido."
        placeHolder="Digite..."
        name="ouvidoria.pedido"
        value={pedido}
        rows={8}
        {...formikBag}
        onChange={handlePedido}
      />
      <CharactersLeft>
        {5000 - pedido.length} caracteres restantes
      </CharactersLeft>
    </>
  );
};

DetailsInput.propTypes = {
  pedido: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default DetailsInput;
