import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "@constants";
import { ActionAutoCompra } from "../../../components/shared/actions";

const DeoPrimeiroPasso = ({ sectionTitle }) => {
  return (
    <div className="text-center pt-5 pb-4">
      <h1 className="mt-2 mb-3">{sectionTitle}</h1>
      <p className="text-body-lg">
        Conquiste o seu imóvel, veículo ou máquinas agrícolas agora mesmo.
      </p>
      <p>
        <ActionAutoCompra
          className="mt-3 cvp-action-comprar"
          blank
          secondary
          href={LINKS.autoCompraConsorcio}
          autoCompraId="consorcio"
        >
          Comprar online
        </ActionAutoCompra>
      </p>
    </div>
  );
};

DeoPrimeiroPasso.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

export default DeoPrimeiroPasso;
