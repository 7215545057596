import Form from "./Form";

export { default as InputControl } from "./InputControl";
export { default as SelectControl } from "./SelectControl";
export { default as TextAreaControl } from "./TextAreaControl";
export { default as SubmitControl } from "./SubmitControl";
export { default as ReCaptchaControl } from "./ReCaptchaControl";
export { default as RadioControl } from "./RadioControl";
export { default as DatePickerControl } from "./DatePickerControl";

export default Form;
