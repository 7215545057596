import querystring from "querystring";
import { useState } from "react";
import axios from "axios";
import { endPoints } from "../../config";
import envs from "../../config/envs";
import useCache from "./useCache";

const useLogin = () => {
  const [isCached, setToken, getToken] = useCache("logged_token");
  const [error, setError] = useState(null);

  const login = async (username, password) => {
    const tokenRequestBody = {
      client_id: envs.clientId,
      grant_type: "password",
      scope: envs.loginScope,
      username: username.value,
      password: password.value,
      service_channel: envs.loginServiceChannel,
    };

    try {
      const { data } = await axios.post(
        endPoints.getLoginEndPoint,
        querystring.stringify(tokenRequestBody),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      setToken(data.access_token);
      return data.access_token;
    } catch (err) {
      setError(err);
    }
    return "";
  };

  return { error, login, token: [isCached, setToken, getToken] };
};

export default useLogin;
