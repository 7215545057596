import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const EmailInput = ({ email, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.email"
    label="E-mail"
    placeHolder="Digite um e-mail"
    name="ouvidoria.contato.email"
    type="email"
    value={email}
    {...formikBag}
  />
);

EmailInput.propTypes = {
  email: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default EmailInput;
