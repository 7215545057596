import React from "react";
import PropTypes from "prop-types";
import { useAutoCompraHabilitado } from "../../../hooks";
import Button from "./Button";

const ActionAutoCompra = (props) => {
  const {
    actionType,
    autoCompraId,
    label,
    disabled,
    children,
    className: classStyled,
  } = props;

  const { habilitado } = useAutoCompraHabilitado(autoCompraId);

  const disabledAction = disabled || !habilitado;
  const disabledLabel = habilitado ? label || children : "adquira em breve";
  const className = `${classStyled || ""}${habilitado ? "" : " aguardando"}`;

  return React.createElement(
    actionType,
    {
      ...props,
      habilitado: null,
      disabled: disabledAction,
      label: disabledLabel,
      actionType: null,
      className,
    },
    disabledLabel
  );
};

ActionAutoCompra.defaultProps = {
  actionType: Button,
  label: null,
  disabled: false,
};

ActionAutoCompra.propTypes = {
  actionType: PropTypes.elementType,
  autoCompraId: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ActionAutoCompra;
