import useAjaxRequest, { GET } from "./useAjaxRequest";
import { endPoints } from "../../config";

const useCEP = () => {
  const {
    response: { error: errorCEP, loading: isLoadingCEP, data: dataCEP },
    performRequest,
  } = useAjaxRequest();

  async function consultCEP(CEP) {
    const options = {
      method: GET,
      url: endPoints.consultCEPEndPoint(CEP),
    };
    return performRequest(options);
  }

  return { errorCEP, isLoadingCEP, dataCEP, consultCEP };
};

export default useCEP;
