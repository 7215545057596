export const distinctArray = (list) => {
  if (!Array.isArray(list)) {
    return [];
  }

  const arraySet = [].concat(list).reduce((itens, item) => {
    itens.add(item);

    return itens;
  }, new Set());

  return Array.from(arraySet.values());
};

export const tryGetListOrEmpty = (list) => {
  return Array.isArray(list) ? list : [];
};
