import "./config/polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import Bootstrap from "./main";

registerLocale("pt-BR", ptBr);
setDefaultLocale("pt-BR");

ReactDOM.render(<Bootstrap />, document.getElementById("root"));
