import React from "react";
import PropTypes from "prop-types";
import BlockContents from "../../../components/shared/contents/BlockContents";
import { createImageElement } from "../../../asserts/images";

const ValorDoCredito = ({ images }) => {
  return (
    <BlockContents className="cc-card-section" columProps={{ lg: 6 }}>
      <BlockContents.Column
        image={createImageElement(images.valorCredito, {
          alt: "valor de crédito",
          className: "d-none d-lg-block",
        })}
        header={<h2 className="content-title m-2"> Valor do crédito </h2>}
        headerClassName="cvp-card-header d-flex justify-content-start align-items-center"
      >
        <p>
          Se tudo for aprovado, seu crédito ficará disponível para você negociar
          o melhor preço com o vendedor, pois vai pagar à vista.
        </p>
        <br />
        <p>
          Você também pode usar até 10% do crédito para pagar despesas
          referentes ao bem: fazer um seguro, pagar tributos e registros
          cartoriais etc.
        </p>
      </BlockContents.Column>

      <BlockContents.Column
        image={createImageElement(images.valorDinheiro, {
          alt: "valor de crédito",
          className: "d-none d-lg-block",
        })}
        header={<h2 className="content-title m-2"> Dinheiro na mão</h2>}
        headerClassName="cvp-card-header d-flex justify-content-start align-items-center"
      >
        <p>
          {" "}
          Caso você decida não utilizar seu crédito, após 180 dias da
          contemplação poderá convertê-lo em dinheiro. Para isso, você deverá
          pagar todo o seu saldo devedor.
        </p>
      </BlockContents.Column>
    </BlockContents>
  );
};

ValorDoCredito.propTypes = {
  images: PropTypes.objectOf(Object).isRequired,
};

export default ValorDoCredito;
