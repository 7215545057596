import React from "react";
import { useSegmentRoute } from "@hooks";
import { HomePage } from "./pages";
import AreaRoute from "../../components/shared/routes";
import { OuvidoriaPending } from "./sections";

export const Ouvidoria = () => {
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      component: OuvidoriaPending,
      path: "/pendente",
    },
  ]);

  return <AreaRoute {...routerContext} main={HomePage} title="Ouvidoria" />;
};

export default Ouvidoria;
