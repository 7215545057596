/* eslint-disable sonarjs/prefer-immediate-return */
export const formatMoney = (valueInput) => {
  let numericValue = valueInput.replace(/\D/g, "");
  numericValue = numericValue ? parseInt(numericValue, 10) : 0;

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  return formatter.format(numericValue / 100);
};

export const formatMoneyDecimal = (number) => {
  const numberParsed = parseFloat(number);
  const formattedNumber = numberParsed.toLocaleString("pt-BR", {
    style: "decimal",
    minimumFractioinDigits: 2,
  });
  return formattedNumber;
};
