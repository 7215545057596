import React from "react";
import PropTypes from "prop-types";

const NumerosLoteriaFederal = ({ premioLoteria }) => (
  <p className="numeros-federal text-center">
    <strong>
      Números da Loteria Federal:
      <div className="d-flex d-lg-inline justify-content-center flex-wrap py-2">
        {["Primeiro", "Segundo", "Terceiro", "Quarto", "Quinto"].map((p) => (
          <span className="m-2 d-block d-lg-inline">
            {premioLoteria[`cod${p}Premio`]}
          </span>
        ))}
      </div>
    </strong>
  </p>
);

NumerosLoteriaFederal.propTypes = {
  premioLoteria: PropTypes.objectOf(Object).isRequired,
};

export default NumerosLoteriaFederal;
