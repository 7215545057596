import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import styled from "styled-components";
import FormularioOuvidoria from "./FormularioOuvidoria";
import Form from "../../../components/shared/forms";
import {
  cpfCnpjRegex,
  cepRegex,
  protocoloRegex,
  nomeRegex,
} from "../../../utils/inputUtils";
import useOuvidoria from "../../../hooks/requests/useOuvidoria";

const AbrirOuvidoria = ({ sectionTitle, setNewProtocol }) => {
  const [anexo, setAnexo] = useState({
    nome: "",
    conteudo: "",
    tamanho: "",
    descricao: "Anexo complementar ouvidoria",
  });
  const {
    createOuvidoria,
    responseData,
    isLoadingOuvidoria,
    errorOuvidoria,
  } = useOuvidoria();
  const formProps = {
    anexo,
    setAnexo,
    isLoadingOuvidoria,
    errorOuvidoria,
  };

  useEffect(() => {
    if (responseData?.protocolo) {
      setNewProtocol(responseData);
    }
  }, [setNewProtocol, responseData]);
  return (
    <Container>
      <Title>{sectionTitle}</Title>
      <Subtitle>
        Antes de começar, tenha em mãos o <strong>protocolo anterior</strong>{" "}
        fornecido em um dos nossos canais de atendimento.
      </Subtitle>
      <Form
        formProps={formProps}
        form={FormularioOuvidoria}
        formId="search-panel-form"
        validationSchema={{
          reCaptcha: Yup.string().required("O campo reCAPTCHA é obrigatório."),
          produto: Yup.string().required("O campo Produto é obrigatório."),
          protocolo: Yup.string()
            .matches(protocoloRegex, "Protocolo inválido.")
            .required("O campo Protocolo é obrigatório."),
          ouvidoria: Yup.object()
            .shape({
              nome: Yup.string()
                .max(120, "o Campo nome ultrapassou o limite de caracteres.")
                .matches(nomeRegex, "Nome inválido.")
                .required("O campo nome é obrigatório."),
              cpfCnpj: Yup.string()
                .matches(cpfCnpjRegex, "Insira um CPF ou CNPJ válido.")
                .required("O campo CPF ou CNPJ é obrigatório."),
              pedido: Yup.string()
                .min(
                  50,
                  "O detalhe do seu pedido deve ter pelo menos 50 caracteres."
                )
                .max(
                  5000,
                  "O detalhe do seu pedido deve ter no máximo 5000 caracteres."
                )
                .required("O campo detalhes do pedido é obrigatório."),
              anexo: Yup.string(),
              contato: Yup.object()
                .shape({
                  telefone: Yup.string()
                    .min(14, "Telefone inválido.")
                    .required("O campo telefone é obrigatório."),
                  email: Yup.string()
                    .email("E-mail inválido.")
                    .required("O campo e-mail é obrigatório."),
                  cep: Yup.string()
                    .matches(cepRegex, "Insira um CEP válido.")
                    .required("O campo CEP é obrigatório."),
                  endereco: Yup.string().required(
                    "O campo endereço é obrigatório."
                  ),
                  numero: Yup.string().required(
                    "O campo número é obrigatório."
                  ),
                  complemento: Yup.string(),
                  estado: Yup.string().required(
                    "O campo Estado é obrigatório."
                  ),
                  cidade: Yup.string().required(
                    "O campo cidade é obrigatório."
                  ),
                })
                .required(),
            })
            .required(),
        }}
        initialValues={{
          produto: "",
          protocolo: "",
          ouvidoria: {
            nome: "",
            cpfCnpj: "",
            pedido: "",
            anexo,
            contato: {
              telefone: "",
              email: "",
              cep: "",
              endereco: "",
              numero: "",
              complemento: "",
              estado: "",
              cidade: "",
            },
          },
          reCaptcha: "",
        }}
        onSubmit={(data) => {
          const cpfCnpj = data.ouvidoria.cpfCnpj.replace(/\D/gi, "");
          const telefone = data.ouvidoria.contato.telefone.replace(/\D/gi, "");
          const cep = data.ouvidoria.contato.cep.replace(/\D/gi, "");

          const formData = {
            protocolo: {
              numero: data.protocolo,
              produto: data.produto,
            },
            solicitante: {
              nome: data.ouvidoria.nome,
              cpfCnPj: {
                numero: cpfCnpj,
                tipo: cpfCnpj.length === 14 ? 1 : 0,
              },
              telefone: {
                ddd: telefone.replace(/^(\d{2})(9?\d{8})$/gi, "$1"),
                numero: telefone.replace(/^(\d{2})(9?\d{8})$/gi, "$2"),
              },
              email: data.ouvidoria.contato.email,
              endereco: {
                cep,
                endereco: data.ouvidoria.contato.endereco,
                numero: data.ouvidoria.contato.numero,
                complemento: data.ouvidoria.contato.complemento,
                estado: data.ouvidoria.contato.estado,
                cidade: data.ouvidoria.contato.cidade,
              },
            },
            comentario: data.ouvidoria.pedido,
            anexos: anexo.conteudo
              ? [
                  {
                    nome: anexo.nome,
                    binario: anexo.conteudo,
                    descricao: anexo.descricao,
                  },
                ]
              : null,
          };

          createOuvidoria(formData, data.reCaptcha);
        }}
      />
    </Container>
  );
};

AbrirOuvidoria.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  setNewProtocol: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;

export default AbrirOuvidoria;
