import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";
import Banner from "./Banner";

const BannerCarousel = ({ children }) => {
  const carrosselItens = React.Children.map(children, (banner) =>
    React.isValidElement(banner) ? (
      <Carousel.Item>{banner}</Carousel.Item>
    ) : null
  );

  return (
    <>
      <Carousel
        controls={false}
        className="cvp-banners-carousel"
        interval={5000}
      >
        {carrosselItens}
      </Carousel>
    </>
  );
};

BannerCarousel.propTypes = {
  children: PropTypes.arrayOf(Banner).isRequired,
};

export default BannerCarousel;
