import React from "react";
import ContentWithFaq from "./ContentWithFaq";

const SeguridadeFaq = (props) => {
  return <ContentWithFaq {...props} variant="seguridade-light" />;
};

SeguridadeFaq.propTypes = {};

export default SeguridadeFaq;
