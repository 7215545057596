import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import { Button } from "../actions";
import { useMakeClass } from "../../../hooks";
import { VARIANT_COLORS } from "../../../constants";

const CardToggleButton = (props) => {
  const {
    label,
    eventKey,
    callback,
    iconShow,
    iconHide,
    variant,
    analitycsValue,
  } = props;

  const currentEventKey = useContext(AccordionContext);

  const toggleRef = useRef(null);

  const clickHandle = useAccordionToggle(eventKey, () => {
    return callback && callback(eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  const classNames = useMakeClass(
    "accordion-link-header",
    "d-flex justify-content-between align-items-center text-left p-3",
    isCurrentEventKey && "active"
  );

  return (
    <Button
      ref={toggleRef}
      transparent
      block
      variant={variant}
      className={classNames}
      onClick={clickHandle}
      label={label}
      analitycsValue={analitycsValue}
    >
      {label}
      {React.createElement(isCurrentEventKey ? iconHide : iconShow, {
        className: "ml-2 c-primary accordion-link-header-icon",
      })}
    </Button>
  );
};

CardToggleButton.defaultProps = {
  callback: null,
  variant: "primary",
  analitycsValue: null,
};

CardToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  callback: PropTypes.func,
  iconShow: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  iconHide: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  variant: PropTypes.oneOf([...VARIANT_COLORS, "seguridade-light"]),
  analitycsValue: PropTypes.string,
};

CardToggleButton.propTypes = {};

export default CardToggleButton;
