/* eslint-disable max-len */
import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import { LINKS } from "../../../constants";
import "../style.css";

const faqItems = [
  {
    label: "O que é o Consórcio Sortudão?",
    content: (
      <>
        <p>
          É uma promoção que irá sortear o valor de <b> R$100 mil </b>, através
          de título de capitalização para quem contratar uma ou mais cotas da
          CAIXA Consórcio, sem valor mínimo, mediante pagamento da parcela de
          adesão, no período de <b> 01/07/2024 a 31/08/2024 </b>.
        </p>
      </>
    ),
  },
  {
    label: "Preciso realizar um cadastro para participar da promoção?",
    content: (
      <>
        <p>
          Não. A participação na promoção será automática, a cada cota de
          consórcio contratada será disponibilizado um número da sorte.
        </p>
      </>
    ),
  },
  {
    label: "Onde consulto meu número da Sorte?",
    content: (
      <>
        <p>
          O número da sorte será enviado via SMS e e-mail em até 10 dias úteis
          após efetivada a contratação da cota. Além disso, o número da sorte
          poderá ser consultado na opção “Consórcio Sortudão” da{" "}
          <a href={LINKS.areaLogada}> área logada</a>.
        </p>
      </>
    ),
  },
  {
    label:
      "Em caso de contratação de mais de uma cota quantos números da sorte serão disponibilizados?",
    content: (
      <>
        <p>
          Para cada cota contratada será concedido um número da sorte, ou seja,
          quanto mais cotas contratar mais chances terá de ganhar.
        </p>
      </>
    ),
  },
  {
    label: "Quando será divulgado o número da sorte ganhador?",
    content: (
      <>
        <p>
          O resultado do número da sorte do ganhador será divulgado até
          12/11/2024, após o sorteio da Loteria Federal.
        </p>
      </>
    ),
  },
  {
    label: "Terei custo adicional para participar da promoção?",
    content: (
      <>
        <p>
          Esse é um benefício totalmente gratuito e não terá custo adicional
          para participação.
        </p>
      </>
    ),
  },
  {
    label: "Existe alguma exigência para ser premiado?",
    content: (
      <>
        <p>
          Sim, para ser elegível ao prêmio o participante sorteado deverá estar
          ativo e em dia com suas prestações mensais.
        </p>
      </>
    ),
  },
  {
    label: "O que acontece se o sorteado não for elegível ao prêmio?",
    content: (
      <>
        <p>
          Se a cota do participante sorteado estiver cancelada ou inadimplente,
          será automaticamente desclassificada e identificado o próximo elegível
          conforme regra de aproximação descrita no regulamento.
        </p>
      </>
    ),
  },
  {
    label: "Como o ganhador será notificado?",
    content: (
      <>
        <p>
          O participante ganhador será comunicado por contato telefônico e
          receberá uma notificação via SMS e e-mail, por isso é essencial que o
          cliente mantenha seus dados cadastrais atualizados. Além disso, o
          resultado poderá ser consultado na opção “Consórcio Sortudão” da{" "}
          <a href={LINKS.areaLogada}> área logada</a>.
        </p>
      </>
    ),
  },
  {
    label: "Qual o prazo para pagamento do prêmio?",
    content: (
      <>
        <p>
          O pagamento do prêmio será realizado pela CAIXA Capitalização em até
          15 (quinze) dias após o recebimento de toda documentação necessária,
          devendo estar completa e legível.
        </p>
      </>
    ),
  },
];

export const ConsorcioSortudaoFAQ = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      className="oi"
      title={sectionTitle}
      faqItems={faqItems}
      right
    />
  );
};

ConsorcioSortudaoFAQ.defaultProps = {
  ...Section.defaultProps,
};

ConsorcioSortudaoFAQ.propTypes = {
  ...Section.propTypes,
};

export default ConsorcioSortudaoFAQ;
