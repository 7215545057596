import "./style.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import { scrollToRefObject, scrollToTop, applyKey } from "@utils";
import { useAnchorParam } from "@hooks";
import { Link } from "../../actions";
import CustomSubMenuToggle from "./CustomSubMenuToggle";

export const PageMenu = ({ menuItens, menuTitle, to }) => {
  const DEFAULT_AJUSTMENT_ANCOR = -180;

  const [ancora] = useAnchorParam();
  useEffect(() => {
    if (ancora) {
      const section = menuItens.find(
        (x) => x.ref.current.id === ancora.replace("#", "")
      );
      if (section) {
        scrollToRefObject(
          section.ref,
          section.ajustment || DEFAULT_AJUSTMENT_ANCOR
        );
      }
    }
  }, [menuItens, ancora, DEFAULT_AJUSTMENT_ANCOR]);

  return menuItens && menuItens.length ? (
    <>
      <Navbar expand="lg" className="nav-page make-section" sticky="top">
        <Row className="m-0">
          <Col xs={6} sm={4} className="m-0 p-0">
            <Navbar.Brand
              toRoute={to}
              as={Link}
              onClick={() => setTimeout(() => scrollToTop(), 50)}
              className="nav-page-brand"
            >
              {menuTitle}
            </Navbar.Brand>
          </Col>
          <Col className="nav-page-menu-content p-0">
            <Navbar.Toggle
              as={CustomSubMenuToggle}
              menuItens={menuItens}
              aria-controls="nav-page-submenu"
            />
            <Navbar.Collapse id="nav-page-submenu">
              <Nav className="mr-auto nav-page-nav">
                {applyKey(menuItens, (item) => (
                  <Nav.Link
                    as={Link}
                    onClick={() =>
                      scrollToRefObject(
                        item.ref,
                        item.ajustment || DEFAULT_AJUSTMENT_ANCOR
                      )
                    }
                  >
                    {item.label}
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Navbar>
    </>
  ) : null;
};

PageMenu.defaultProps = {
  ...CustomSubMenuToggle.defaultProps,
};

PageMenu.propTypes = {
  ...CustomSubMenuToggle.propTypes,
  menuTitle: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default PageMenu;
