const params = {
  behavior: {
    auto: "auto",
    instant: "instant",
    smooth: "smooth",
  },
  block: {
    start: "start",
    center: "center",
    end: "end",
    nearest: "nearest",
  },
  inline: {
    start: "start",
    center: "center",
    end: "end",
    nearest: "nearest",
  },
};

export const scrollTo = (top, left = 0, behavior = "smooth") =>
  window.scrollTo({ top, left, behavior });
export const scrollToRefObject = (ref) =>
  ref.current.scrollIntoView({
    block: params.block.center,
    behavior: params.behavior.smooth,
  });
export const scrollToTop = () => scrollTo(0);
