import { Image } from "react-bootstrap";
import React from "react";
import brandLogoCaixaSeguridade from "./brandding-logo-caixa-seguridade.png";
import brandLogoCaixaConsorcios from "./brandding-logo-caixa-consorcios.png";
import geotrustIcon from "./icons/geotrust-secured-site-icon.svg";
import nortontIcon from "./icons/norton-site-icon.svg";
import imagemExclamacao from "./imagem-exclamacao.svg";
import imageHeaderSeguridade from "./header-image-temp.jpg";
import imageFooterSeguridade from "./footer-image-temp.jpg";
import vidaBlocoSuaEmpresaPodeContarComAGente from "./vida-bloco-sua-empresa-pode-contar-com-a-gente.svg";
import iconHeart from "./icon-heart.svg";
import iconPhonebook from "./icon-phonebook.svg";
import imgNortonTeste from "./img-nortonTeste.svg";
import seguridadeBannerMobile from "./seguridade-banner-mobile-background.png";
import seguridadeBannerMobileBlackFriday from "./banner-mobile-home-black-friday-bg.png";
import bannerNatalMobile from "./campaigns/Banner_Site_AUTOCOMPRA_MOBILE_376x320px.png";
import imgGeoTrust from "./img-geo-trust.png";
import iconHome from "./icon-home.svg";
import calendarIcon from "./calendar.svg";
import ilustracaoAtencao from "./ilustracao-atencao.svg";

export function createImageElement(image, imageProps) {
  return typeof image === "string" || !image
    ? React.createElement(Image, { ...imageProps, src: image })
    : React.createElement(image, {
        className: `svg-image ${imageProps.className || ""}`,
      });
}

export default {
  brandLogoCaixaSeguridade,
  brandLogoCaixaConsorcios,
  geotrustIcon,
  nortontIcon,
  imagemExclamacao,
  imageHeaderSeguridade,
  imageFooterSeguridade,
  vidaBlocoSuaEmpresaPodeContarComAGente,
  iconHeart,
  seguridadeBannerMobile,
  seguridadeBannerMobileBlackFriday,
  bannerNatalMobile,
  iconPhonebook,
  imgGeoTrust,
  imgNortonTeste,
  iconHome,
  calendarIcon,
  ilustracaoAtencao,
};
