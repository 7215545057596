import React from "react";
import { actionPropTypes } from "./Action";
import AnalitycsAction from "./AnalitycsAction";

const Link = (props) => <AnalitycsAction {...props} />;

Link.defaultProps = {
  custom: true,
};

Link.propTypes = {
  ...actionPropTypes,
};

export default Link;
