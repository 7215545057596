import React from "react";
import PropTypes from "prop-types";
import { Apresentacao } from "../../../components/blocosCustomizados";

const ApresentacaoContemplacao = ({ images, sectionTitle }) => {
  return (
    <Apresentacao title={sectionTitle} image={images.entregaBemContemplacao}>
      <p>
        O primeiro passo é ler a cartilha de orientação disponível no final
        desta página, nela você encontrará informações importantes para o início
        do seu processo.
      </p>

      <p>
        Para abrir a sua solicitação de uso do crédito acesse a opção
        “Solicitações”, disponível na Área do Cliente, em seguida clique em
        “Registrar” no menu correspondente. Você será redirecionado para o nosso
        portal de utilização do crédito, leia atentamente as orientações e abra
        a sua requisição através do menu “Solicitação de Utilização do Crédito”,
        preenchendo todos os campos e documentos solicitados.
      </p>

      <p>
        O valor do crédito só é liberado após a aprovação das análises, por isso
        é importante não assumir nenhum compromisso com o vendedor antes das
        confirmações necessárias.
      </p>
    </Apresentacao>
  );
};

ApresentacaoContemplacao.propTypes = {
  images: PropTypes.objectOf(Object).isRequired,
  sectionTitle: PropTypes.string.isRequired,
};

export default ApresentacaoContemplacao;
