/* eslint-disable max-len */
import React from 'react';

function HandShake() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='150'
      height='150'
      fill='none'
      viewBox='0 0 150 150'
    >
      <g clipPath='url(#clip0_4001_19)'>
        <path
          stroke='#FFAA59'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3.97'
          d='M104.892 80.03L122 65.82M98.475 89.589l2.021 1.52s4.628 3.204 7.609.746 2.133-6.063-.07-8.259c-2.203-2.195-27.244-21-27.244-21s-8.043 5.963-10.883 6.265c-4.275.453-7.65-2.457-6.225-4.462 1.425-2.004 9.459-12.529 13.541-14.593 4.083-2.065 9.459-1.612 9.459-1.612s17.239.453 21.443-3.807m-17.24 50.369s4.012 2.78 6.609.644c2.597-2.135 1.85-5.267-.06-7.17-1.91-1.904-13.865-10.717-13.865-10.717M73.394 92.429l9.327 6.849s4.214 2.649 7.124-.262c3.113-3.102-.778-5.882-.778-5.882L78.7 85.187m3.597 15.118c0 3.133-2.547 5.671-5.69 5.671a5.706 5.706 0 01-4.264-1.914M27.012 65.699l18.401 15.5m-5.446-37.195s9.074 3.878 15.289 3.878c6.214 0 14.773 0 18.532 2.71m-1.697 50.871a4.259 4.259 0 01-4.265 4.251 4.259 4.259 0 01-4.264-4.251c0-2.346 1.91-4.25 4.264-4.25a4.258 4.258 0 014.265 4.25zm-8.034-3.998c0 2.347-1.91 4.25-4.264 4.25a4.258 4.258 0 01-4.265-4.25c0-2.347 1.91-4.25 4.265-4.25a4.258 4.258 0 014.264 4.25zm-6.609-5.167c0 2.347-1.91 4.25-4.264 4.25a4.258 4.258 0 01-4.264-4.25c0-2.347 1.91-4.25 4.264-4.25a4.258 4.258 0 014.264 4.25zm-6.992-5.428c0 3.071-2.496 5.56-5.578 5.56a5.558 5.558 0 01-5.568-5.56c0-3.072 2.496-5.55 5.568-5.55 3.072 0 5.578 2.488 5.578 5.55z'
        />
      </g>
      <defs>
        <clipPath id='clip0_4001_19'>
          <path fill='#fff' d='M0 0H99V66H0z' transform='translate(25 42)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HandShake;
