/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { media } from "../../../utils/css";

export const InputMoneyStyled = styled.input`
  ${media.sm_desktop`
    position: relative;
    outline: none;
    font-size: 16px;
    width: 35%;
    height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    color: #494949;
`}
  position: relative;
  outline: none;
  font-size: 16px;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  color: #494949;
`;
