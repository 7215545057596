import React from "react";
import PropTypes from "prop-types";
import { SelectControl } from "../../../../../components/shared/forms";
import { mapToOptions } from "../../../../../utils";
import { PRODUCTS } from "../../../../../constants";

const ProductSelect = ({ produto, isLoadingProtocol, formikBag }) => (
  <SelectControl
    inputStyle={{ backgroundPositionX: "95%", maxWidth: "400px" }}
    controlId="formOuvidoria.produto"
    label="Sobre qual produto é a sua ocorrência?"
    placeHolder="Selecione um produto"
    name="produto"
    value={produto}
    options={mapToOptions(PRODUCTS)}
    disabled={isLoadingProtocol}
    {...formikBag}
  />
);

ProductSelect.propTypes = {
  produto: PropTypes.string.isRequired,
  isLoadingProtocol: PropTypes.bool.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default ProductSelect;
