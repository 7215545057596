import React from "react";
import PropTypes from "prop-types";
import { Text } from "@gcs/design-system/react";
import styled from "styled-components";
import { Section } from "../../../components/shared/pages";

const OuvidoriaContato = (props) => {
  const { sectionTitle } = props;

  return (
    <Container>
      <h3 className="text-center">{sectionTitle}</h3>
      <NumberStyle align="center">0800 702 4240</NumberStyle>
      <FunctioningTimeText align="center">
        Segunda a sexta-feira das 9h às 18h, exceto feriados nacionais.
      </FunctioningTimeText>
      <ReminderText align="center">
        Lembre-se de ter em mãos o protocolo do atendimento anterior, feito por
        outro canal.
      </ReminderText>
    </Container>
  );
};

const NumberStyle = styled(Text)`
  font-family: "CAIXA Std Bold";
  font-size: 28px !important;
  color: #000;
  margin: 25px 0 !important;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const FunctioningTimeText = styled(Text)`
  font-family: "CAIXA Std";
  font-size: 18px;
  line-height: 20px;
  color: #000;
  margin-bottom: 35px !important;
`;

const ReminderText = styled(Text)`
  font-family: "CAIXA Std";
  font-size: 20px;
  line-height: 24px;
  color: #666 !important;
`;

const Container = styled.div`
  font-family: "CAIXA Std";
  padding: 30px 0;
`;

OuvidoriaContato.defaultProps = {
  ...Section.defaultProps,
};

OuvidoriaContato.propTypes = {
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default OuvidoriaContato;
