import styled from "styled-components";
import { media } from "../../../../utils";
import { SubmitControl } from "../../../../components/shared/forms";

export const WarningParagraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 !important;
`;

const FormRowNameAndCpfDesktop = media.desktop`
  display: grid;
  grid-template-columns: 0.38fr 0.38fr;
  grid-gap: 20px;
`;
export const FormRowNameAndCpf = styled.div`
  ${FormRowNameAndCpfDesktop}
`;

const ContactDataFormTitleDesktop = media.desktop`
  font-size: 24px;
`;

export const ContactDataFormTitle = styled.h6`
  color: #646363;
  font-size: 20px;
  line-height: 30px;
  margin: 30px 0 !important;

  ${ContactDataFormTitleDesktop}
`;

const FormRowPhoneAndEmailDesktop = media.desktop`
  display: grid;
  grid-template-columns: 0.38fr 0.48fr;
  grid-gap: 20px;
`;

export const FormRowPhoneAndEmail = styled.div`
  ${FormRowPhoneAndEmailDesktop}
`;

const FormRowAddress1Desktop = media.desktop`
  display: grid;
  grid-template-columns: 0.5fr 0.3fr;
  grid-gap: 20px;
`;
export const FormRowAddress1 = styled.div`
  ${FormRowAddress1Desktop}
`;

const FormRowAddress2Desktop = media.desktop`
  display: grid;
  grid-template-columns: 0.35fr 0.3fr 0.3fr;
  grid-gap: 20px;
`;
export const FormRowAddress2 = styled.div`
  ${FormRowAddress2Desktop}
`;

export const CEPContainerDesktop = media.desktop`
  max-width: 200px;
  align-items: center;
`;
export const CEPContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${CEPContainerDesktop}
`;

export const CharactersLeft = styled.span`
  font-size: 16px;
  margin-bottom: 50px;
`;

export const SubmitButton = styled(SubmitControl)`
  width: 100%;
  max-width: 290px;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;
