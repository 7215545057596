import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import { Section } from "@components/shared/pages";
import FaqButtonAreaCliente from "../../../components/shared/common/FaqButtonAreaCliente";
import {
  Button,
  ChatButton,
  Link,
  PhoneLink,
} from "../../../components/shared/actions";
import Table from "../../../components/shared/table";
import { applyKey } from "../../../utils";
import { LINKS } from "../../../constants";
import { envs } from "../../../config";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import getContractLink from "../../../utils/contracts";

const tarifaImobiliario = "tarifas-imobiliario";
const tarifaVeiculosLeves = "tarifas-veiculos-leves";
const tarifaVeiculosPesados = "tarifas-veiculos-pesados";

const contratoImobiliario = "contrato-imobiliario";
const contratoVeiculos = "contrato-veiculos";

const substituicaoDaGarantia = "Substituição da garantia";
const transferenciaCotaCancelada = "Transferência de cota cancelada";
const tarifaUmPorCento = "1% do valor do crédito atualizado, por pedido";
const tarifaMeioPorCento = "0,5% do valor do crédito atualizado, por pedido";
const trezentosPorPedido = "R$ 300,00 por pedido";
const creditoAbaixoQuinhentosMil =
  "Para crédito abaixo de R$500 mil valor fixo de R$400 " +
  "| Para crédito igual ou superior a R$500 mil será cobrado 0,5% do valor do crédito atualizado.";

const SelecioneControl = ({ items }) => {
  return (
    <Form.Group className="d-flex justify-content-center pt-4">
      {applyKey(items, ({ label, id, checked, handleClick, defaultValue }) => (
        <Form.Check
          inline
          label={label}
          type="radio"
          id={id}
          checked={checked}
          defaultValue={defaultValue}
          onClick={handleClick}
        />
      ))}
    </Form.Group>
  );
};

SelecioneControl.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      defaultValue: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

// eslint-disable-next-line react/prop-types
const TabelaTarifa = ({ titulo, subtitulo, items }) => {
  return (
    <Table
      header={
        <tr>
          <th colSpan="2">
            <span>{titulo}</span>
            <span className="descricao-subtitulo">{subtitulo}</span>
          </th>
        </tr>
      }
      bordered={false}
      className="tabela-tarifas"
    >
      {applyKey(items, ({ descricao, descricaoValor }) => (
        <tr>
          <td>{descricao}</td>
          <td className="descricao-valor">{descricaoValor}</td>
        </tr>
      ))}
    </Table>
  );
};

const listaTarifasImobiliario = [
  {
    descricao:
      "Laudo de avaliação para aquisição de imóvel ou do projeto de obra para construção",
    descricaoValor: "R$ 750,00 por imóvel",
  },
  {
    descricao:
      "Laudo de mediação do acompanhamento da execução de obra: construção, reforma ou ampliação",
    descricaoValor: "R$ 500,00 por medição",
  },
  {
    descricao:
      "Análise jurídica da documentação imobiliária e elaboração do Instrumento Particular",
    descricaoValor: "R$ 1.500,00 por processo",
  },
  {
    descricao:
      "Análise do pedido de transferência de cota *" +
      "Cobrança de 50% no ato do pedido (não reembolsável em caso de não aprovação do comprador)*" +
      "Cobrança de 50% após a aprovação do pedido",
    descricaoValor: tarifaUmPorCento,
  },
  {
    descricao: transferenciaCotaCancelada,
    descricaoValor: trezentosPorPedido,
  },
  {
    descricao: substituicaoDaGarantia,
    descricaoValor: tarifaUmPorCento,
  },
  {
    descricao:
      "Cobrança extrajudicial, nos casos de inadimplência da cota contemplada com o bem entregue",
    descricaoValor: "Honorários advocatícios, conforme o caso",
  },
  {
    descricao: "Emissão de 2ª via do Instrumento Particular para alienação",
    descricaoValor: "R$ 250,00 por pedido",
  },
  {
    descricao:
      "Emissão de 2ª via do instrumento de liberação da alienação fiduciária",
    descricaoValor: "R$ 250,00 por pedido",
  },
  {
    descricao: "Taxa de acompanhamento do processo de construção",
    descricaoValor: "R$ 18,00 por vistoria",
  },
];

const listaTarifasAutomovelLeve = [
  {
    descricao: (
      <>
        <div>
          * Serviços de faturamento de veículo e despesas com a formalização da
          garantia (não reembolsável em caso de desistência do pedido).
        </div>
        <div className="item-description-light">
          * Cobrança de tarifa isenta para veículos híbridos e/ou elétricos nas
          solicitações de uso do crédito realizadas a partir de 01/06/2023.
        </div>
      </>
    ),
    descricaoValor: "R$ 450,00 por bem",
  },
  {
    //
    descricao:
      "Análise do pedido de transferência de cota ativa " +
      "* Cobrança de 50% no ato do pedido (não reembolsável em caso de não aprovação do comprador) " +
      "* Cobrança de 50% após a aprovação do pedido",
    descricaoValor: tarifaMeioPorCento,
  },
  {
    descricao: substituicaoDaGarantia,
    descricaoValor: "0,5% do valor do crédito atualizado, por pedido",
  },
  {
    descricao: transferenciaCotaCancelada,
    descricaoValor: trezentosPorPedido,
  },
  {
    descricao: "Alteração da UF na alienação após a entrega do bem",
    descricaoValor: "Taxa do DETRAN, conforme a região",
  },
  {
    descricao:
      "Cobrança extrajudicial - inadimplência da Cota contemplada com o bem entregue",
    descricaoValor: "Honorários Advocatícios, conforme o caso",
  },
  {
    descricao:
      "Laudo de avaliação para aquisição de embarcação ou aeronave usada",
    descricaoValor:
      "Taxa da Empresa Especializada em Vistoria, conforme o caso",
  },
];

const listaTarifasAutomovelPesado = [
  {
    descricao: (
      <>
        <div>
          * Serviços de faturamento de veículo e despesas com a formalização da
          garantia (não reembolsável em caso de desistência do pedido).
        </div>
        <div className="item-description-light">
          * Cobrança de tarifa isenta para veículos híbridos e/ou elétricos nas
          solicitações de uso do crédito realizadas a partir de 01/06/2023.
        </div>
      </>
    ),
    descricaoValor: "R$ 450,00 por bem",
  },
  {
    descricao:
      "Análise do pedido de transferência de cota ativa ou cancelada." +
      "Cobrança de 50% no ato do pedido (não reembolsável em caso de não aprovação do comprador) " +
      "* Cobrança de 50% após a aprovação do pedido",
    descricaoValor: creditoAbaixoQuinhentosMil,
  },
  {
    descricao: substituicaoDaGarantia,
    descricaoValor: creditoAbaixoQuinhentosMil,
  },
  {
    descricao: transferenciaCotaCancelada,
    descricaoValor: creditoAbaixoQuinhentosMil,
  },
  {
    descricao: "Alteração da UF na alienação após a entrega do bem",
    descricaoValor: "Taxa do DETRAN, conforme a região",
  },
  {
    descricao:
      "Cobrança extrajudicial - inadimplência da Cota contemplada com o bem entregue",
    descricaoValor: "Honorários Advocatícios, conforme o caso",
  },
  {
    descricao:
      "Laudo de avaliação para aquisição de embarcação ou aeronave usada",
    descricaoValor:
      "Taxa da Empresa Especializada em Vistoria, conforme o caso",
  },
];

const ComoFuncionaTransferencia = () => {
  const [linkFormularioTranferencia, setLinkFormularioTranferencia] = useState(
    LINKS.linkFormularioTransferenciaPF
  );
  const [linkCartilha, setLinkCartilha] = useState(LINKS.linkCartilhaTransImob);

  return (
    <>
      <p>
        Para transferir a titularidade de sua cota é necessário que as parcelas
        estejam com o pagamento em dia. Consulte aqui as cartilhas com
        orientações sobre o processo de <strong>transferência da cota</strong> e
        o formulário para fazer o pedido.
      </p>

      <p className="text-center">
        Selecione o tipo de consórcio para <strong>baixar a cartilha</strong>{" "}
        com orientações
      </p>
      <SelecioneControl
        items={[
          {
            label: "Imobiliário",
            id: "radio-link-cartilha-imobiliario",
            checked: linkCartilha === LINKS.linkCartilhaTransImob,
            defaultValue: LINKS.linkCartilhaTransImob,
            handleClick: () => setLinkCartilha(LINKS.linkCartilhaTransImob),
          },
          {
            label: "Veículos Leves",
            id: "radio-link-cartilha-veiculo-leves",
            checked: linkCartilha === LINKS.cartilhaParaTranferenciaVeiculoLeve,
            defaultValue: LINKS.cartilhaParaTranferenciaVeiculoLeve,
            handleClick: () =>
              setLinkCartilha(LINKS.cartilhaParaTranferenciaVeiculoLeve),
          },
          {
            label: "Veículos Pesados",
            id: "radio-link-cartilha-veiculo-pesados",
            checked:
              linkCartilha === LINKS.cartilhaParaTranferenciaVeiculoPesado,
            defaultValue: LINKS.cartilhaParaTranferenciaVeiculoPesado,
            handleClick: () =>
              setLinkCartilha(LINKS.cartilhaParaTranferenciaVeiculoPesado),
          },
        ]}
      />
      <p className="text-center">
        <Button href={linkCartilha} blank>
          Baixar cartilha para transferência
        </Button>
      </p>

      <p className="pt-3">
        Baixe o formulário para solicitar a{" "}
        <strong>transferência da cota:</strong>
      </p>
      <p className="text-center">
        Selecione o tipo de pessoa para que você deseja transferir sua cota
      </p>

      <SelecioneControl
        items={[
          {
            label: "Pessoa Física",
            id: "radio-formulario-tranferencia-pf",
            checked:
              linkFormularioTranferencia ===
              LINKS.linkFormularioTransferenciaPF,
            defaultValue: LINKS.linkFormularioTransferenciaPF,
            handleClick: () =>
              setLinkFormularioTranferencia(
                LINKS.linkFormularioTransferenciaPF
              ),
          },
          {
            label: "Pessoa Jurídica",
            id: "radio-formulario-tranferencia-pj",
            checked:
              linkFormularioTranferencia ===
              LINKS.linkFormularioTransferenciaPJ,
            defaultValue: LINKS.linkFormularioTransferenciaPJ,
            handleClick: () =>
              setLinkFormularioTranferencia(
                LINKS.linkFormularioTransferenciaPJ
              ),
          },
        ]}
      />
      <p className="text-center">
        <Button href={linkFormularioTranferencia} blank>
          Baixar formulário de transferência
        </Button>
      </p>
    </>
  );
};

const Tarifas = () => {
  const [tipoTarifa, setTipoTarifa] = useState(tarifaImobiliario);

  return (
    <>
      <>
        <p>
          Consulte aqui os valores das tarifas previstos no contrato de adesão.
        </p>
        <p>Selecione o tipo de consórcio para ver as tarifas.</p>
        <Form.Group className="d-flex justify-content-center">
          <Form.Check
            inline
            label="Imobiliário"
            type="radio"
            id="radio-tarifa-imobiliario"
            checked={tipoTarifa === tarifaImobiliario}
            defaultValue={tarifaImobiliario}
            onClick={() => setTipoTarifa(tarifaImobiliario)}
          />
          <Form.Check
            inline
            label="Veículos Leves"
            type="radio"
            id="radio-tarifa-veiculos-leves"
            checked={tipoTarifa === tarifaVeiculosLeves}
            defaultValue={tarifaVeiculosLeves}
            onClick={() => setTipoTarifa(tarifaVeiculosLeves)}
          />
          <Form.Check
            inline
            label="Veículos Pesados"
            type="radio"
            id="radio-tarifa-veiculos-pesados"
            checked={tipoTarifa === tarifaVeiculosPesados}
            defaultValue={tarifaVeiculosPesados}
            onClick={() => setTipoTarifa(tarifaVeiculosPesados)}
          />
        </Form.Group>

        <Collapse in={tipoTarifa === tarifaImobiliario}>
          <div>
            <TabelaTarifa
              titulo="Tarifas previstas para o Consórcio Imobiliário"
              subtitulo="(Vigente a partir de 23/08/2021)"
              items={listaTarifasImobiliario}
            />
          </div>
        </Collapse>

        <Collapse in={tipoTarifa === tarifaVeiculosLeves}>
          <div>
            <TabelaTarifa
              titulo="Tarifas previstas para o Consórcio de Veículos Leves"
              subtitulo="(Vigente a partir de 12/05/2022)"
              items={listaTarifasAutomovelLeve}
            />
          </div>
        </Collapse>

        <Collapse in={tipoTarifa === tarifaVeiculosPesados}>
          <div>
            <TabelaTarifa
              titulo="Tarifas previstas para o Consórcio de Veículos Pesados"
              subtitulo="(Vigente a partir de 12/05/2022)"
              items={listaTarifasAutomovelPesado}
            />
          </div>
        </Collapse>
      </>
    </>
  );
};

const ContratoAdesao = () => {
  const [contractType, setContractType] = useState(contratoImobiliario);
  const [group, setGroup] = useState("");
  const [link, setLink] = useState("");

  const handleSearch = useCallback(() => {
    const result = getContractLink(contractType, group);

    setLink(result);
  }, [group, contractType]);

  const writeSetGroup = useCallback(
    (value) => {
      if (link !== "") {
        setLink("");
      }
      setGroup(value);
    },

    [link]
  );

  return (
    <>
      <p>
        Estamos felizes em cuidar das suas conquistas! Selecione o seu tipo de
        consórcio e aproveite para consultar o seu{" "}
        <strong>contrato de adesão</strong>.
      </p>

      <Row>
        <Col xs={12} lg={6} className="px-4 py-1">
          <Button
            block
            onClick={() => setContractType(contratoImobiliario)}
            label="Consórcio Imobiliário"
            transparent={contractType !== contratoImobiliario}
          />
        </Col>
        <Col xs={12} lg={6} className="px-4 py-1">
          <Button
            block
            onClick={() => setContractType(contratoVeiculos)}
            label="Consórcio Veículos"
            transparent={contractType !== contratoVeiculos}
          />
        </Col>

        <Col xs={12} lg={{ span: 6, offset: 3 }} className="px-4 py-3">
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label className="align-center">
                Informe seu grupo
              </Form.Label>
              {link === "error" && (
                <p className="contract-error">
                  Grupo não encontrado, tente novamente
                </p>
              )}
              <input
                className="px-4 py-1 input-group"
                onChange={(e) => writeSetGroup(e.target.value)}
                value={group}
              />

              <Button block onClick={handleSearch} label="Emitir contrato" />

              {link !== "" && link !== "error" && (
                <Button
                  block
                  onClick={() => {
                    window.open(link);
                  }}
                  label="Abrir"
                />
              )}
            </Form.Group>
          </Form>
          <p>
            Não sabe o seu grupo? Consulte acessando a{" "}
            <a href="https://www.caixaseguridade.com.br/Paginas/AreaDoCliente.aspx">
              Area do Cliente
            </a>
          </p>
        </Col>
      </Row>
    </>
  );
};

const DownloadPDF = () => {
  const [contractType, setContractType] = useState(contratoImobiliario);

  let selectOptions = [];
  if (contractType === contratoImobiliario) {
    selectOptions = [
      {
        label: "Versão 01: 01/2021",
        value: LINKS.condicoesGeraisImobiliario,
      },
      {
        label: "Versão 02: 09/2021",
        value: LINKS.contratoAdesaoImobiliarioV092021,
      },
      {
        label: "Versão 03: 05/2023",
        value: LINKS.contratoAdesaoImobiliarioV052023,
      },
    ];
  } else if (contractType === contratoVeiculos) {
    selectOptions = [
      {
        label: "Versão 01: 01/2021",
        value: LINKS.condicoesGeraisVeiculo,
      },
      {
        label: "Versão 02: 09/2021",
        value: LINKS.contratoAdesaoVeiculosV092021,
      },
      {
        label: "Versão 03: 05/2023",
        value: LINKS.contratoAdesaoVeiculosV052023,
      },
    ];
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="px-4 py-1">
          <Button
            block
            onClick={() => setContractType(contratoImobiliario)}
            label="Consórcio Imobiliário"
            transparent={contractType !== contratoImobiliario}
          />
        </Col>
        <Col xs={12} lg={6} className="px-4 py-1">
          <Button
            block
            onClick={() => setContractType(contratoVeiculos)}
            label="Consórcio Veículos"
            transparent={contractType !== contratoVeiculos}
          />
        </Col>

        <Col xs={12} lg={{ span: 6, offset: 3 }} className="px-4 py-3">
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label className="align-center">
                Qual versão do contrato deseja?
              </Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(e) =>
                  !!e.target.value && window.open(e.target.value, "_blank")
                }
              >
                <option value="">Selecione</option>
                {selectOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const FraudesConsorcio = () => {
  return (
    <>
      <p>
        Nosso compromisso não se limita apenas a oferecer oportunidades de
        concretizar seus objetivos, mas em assegurar que sua jornada seja livre
        de preocupações e riscos desnecessários.
      </p>
      <p>
        Gostaríamos de compartilhar orientações essenciais para que você proteja
        seus sonhos e esteja sempre em alerta para desfrutar plenamente das
        vantagens do consórcio, sem cair em Fraudes. Confira nossas dicas a
        seguir:
      </p>
      <p className="text-center">
        <Button href="/consorcio/seguranca" blank>
          Segurança
        </Button>
      </p>
    </>
  );
};

const faqItems = [
  {
    label:
      "Emita a 2ª via do boleto do consórcio e confira outros serviços on-line",
    content: (
      <>
        <p style={{ lineHeight: 1.7 }}>
          Você pode <strong>emitir a 2ª via do boleto de pagamento</strong> na
          <Link href={LINKS.areaLogada} label="Área do Cliente" />. Além disso,
          você também pode:
        </p>
        <p>Atualizar seus dados cadastrais </p>
        <p>Consultar o extrato da sua cota </p>
        <p>Ofertar lances </p>
        <p>Visualizar atas das assembleias</p>
        <p>Alterar o pagamento para débito em conta</p>
        <p>Acessar seu Informe para o Imposto de Renda</p>
        <p>
          Consultar a Cartilha com Orientações para o consorciado contemplado
        </p>
        <p>
          Fazer movimentações na sua cota, como por exemplo, utilizar o crédito
        </p>
        <p>Consultar informações sobre o encerramento do seu grupo</p>
        <p>
          Se ainda não tiver acesso,{" "}
          <Link
            blank
            href={LINKS.cadastroAreaLogada}
            label=" faça seu cadastro"
          />
        </p>
      </>
    ),
  },
  {
    label: "Resultado das assembleias",
    content: (
      <>
        <p>
          Mensalmente acontece a{" "}
          <strong>Assembleia Geral Ordinária (AGO)</strong> que define os
          consorciados contemplados. Por isso, é importante que você realize o
          pagamento das suas parcelas até o vencimento para não perder o direito
          à contemplação.
        </p>
        <p>
          As assembleias são realizadas no seguinte endereço: Alameda Xingu,
          350, 11º Andar - CEP: 06.455-030 - Barueri/SP.
        </p>
        <p>
          <strong>Vale lembrar:</strong>
        </p>
        <p>
          Os lances podem ser ofertados até às 20h do dia anterior à assembleia,
          na <Link href={LINKS.areaLogada} label="Área do Cliente" /> ou pela
          Central de Serviços e Relacionamento, ligando no{" "}
          <PhoneLink phone="0800 975 1000" /> disponível de segunda a
          sexta-feira, das 8h às 21h, horário de Brasília.
        </p>
        <p>
          Para visualizar os resultados das assembleias do seu grupo, acesse a{" "}
          <a href={LINKS.areaLogada}>Área do Cliente</a>
          &nbsp; e faça o download da Ata da assembleia no menu “Assembleias”.
        </p>
        <p>
          Lembramos que, caso você seja contemplado, enviaremos uma comunicação
          por e-mail com todas as orientações necessárias.
        </p>
        <p>
          <a href="/consorcio/resultado-de-assembleias">
            Clique aqui e saiba mais
          </a>
        </p>
        <p>
          <strong className="text-brand-secondary">Você sabia?</strong> <br />
          Enquanto não houver sua contemplação, é possível mudar os planos e se
          adaptar a uma nova realidade. Dentro do limite do seu grupo você pode,
          por exemplo, aumentar ou reduzir o valor do crédito e pagar uma nova
          parcela.
        </p>
      </>
    ),
  },
  {
    label: "Fui contemplado. O que eu faço agora?",
    content: (
      <>
        <p>
          Após a sua contemplação, o valor do crédito será liberado depois da
          aprovação da análise de crédito e de garantia. Por isso, se você
          estiver com alguma restrição no nome, vai precisar regularizar sua
          situação. No Consórcio Imobiliário, o vendedor do imóvel também
          precisa estar sem restrições para que tudo seja aprovado.
        </p>

        <p className="text-center">
          <Button
            href="/consorcio/contemplacao"
            label="Processo de pós contemplação"
          />
        </p>
      </>
    ),
  },
  {
    label: "Tarifas",
    content: <Tarifas />,
  },
  {
    label: "Seu grupo foi encerrado, como saber se tem valores a receber?",
    content: (
      <>
        <p>
          Quando o grupo cumpre o prazo do consórcio contratado, realizando a
          última assembleia, o grupo é encerrado.
        </p>
        <ul>
          <li>
            Você será avisado se houver dinheiro sobrando. O valor a ser
            devolvido será proporcional ao valor do crédito, dividido entre
            todos os consorciados ativos.
          </li>
          <li>
            Nós enviamos um comunicado com o Aviso de Recebimento por e-mail
            solicitando que entre em contato com a Central de Serviços e
            Relacionamento por meio do telefone{" "}
            <PhoneLink phone="0800 975 1000" /> disponível de segunda a
            sexta-feira, das 8h às 21h, horário de Brasília.
          </li>
          <li>
            É necessário manter os dados cadastrais e bancários atualizados,
            pois os valores somente serão devolvidos em conta bancária.
          </li>
        </ul>
        <p>
          <strong className="text-brand-secondary">Você sabia?</strong>
          <br />A solicitação do seu valor deve ser feita até 30 dias depois do
          recebimento do comunicado. Após essa data, será cobrada uma taxa de
          permanência mensal de 5%, sobre o valor total a receber.
        </p>
      </>
    ),
  },
  {
    label: "Veja como funciona a transferência das cotas",
    content: <ComoFuncionaTransferencia />,
  },
  {
    label: "O seguro que cuida do seu consórcio",
    content: (
      <>
        <p>
          Em caso de invalidez por acidente ou morte, suas parcelas restantes do
          Consórcio podem ser quitadas, se você contratou o Seguro Prestamista.
        </p>
        <p>
          Para acionar um seguro, fale com a nosso Seguro Prestamista no
          telefone <PhoneLink phone="0800 975 1000" />.
        </p>
        <DownloadPDF />
      </>
    ),
  },
  {
    label: "Contrato de adesão",
    content: <ContratoAdesao />,
  },
  {
    label: "Fique atento as fraudes no Consórcio",
    content: <FraudesConsorcio />,
  },
  {
    label: "Como cancelar meu consórcio?",
    content: (
      <>
        <p>
          Para cancelar o seu consórcio você pode ligar para a nossa Central de
          Serviços e Relacionamento:
        </p>

        <p>
          <PhoneLink phone="0800 975 1000" />
          <br />
          disponível de segunda a sexta-feira, das 8h às 21h, horário de
          Brasília.
        </p>

        <p>
          Se comprou pelo site, cancele pelo{" "}
          <ChatButton chatId={envs.consorcioChatId} label="chat" />.
        </p>
      </>
    ),
  },
];

export const JaSouCliente = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={faqItems}
      faqFooterContent={<FaqButtonAreaCliente />}
      right
    />
  );
};

JaSouCliente.defaultProps = {
  ...Section.defaultProps,
};

JaSouCliente.propTypes = {
  ...Section.propTypes,
};

TabelaTarifa.propTypes = {
  titulo: PropTypes.string.isRequired,
  items: PropTypes.string.isRequired,
};

export default JaSouCliente;
