import { useCallback } from "react";

const useParams = () => {
  const getParams = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);

    return Array.from(urlParams.entries()).reduce(
      (reducedParams, [prop, value]) => ({ ...reducedParams, [prop]: value }),
      {}
    );
  }, []);

  return getParams();
};

export default useParams;
