import React, { useState } from "react";
import PropTypes from "prop-types";
import AccessibilityModal from "./AccessibilityModal";
import Accessibility from "../../../asserts/images/acessibilidade.svg";
import "./accessibilityStyle.css";

const AccessibilityButton = ({ translator, accessibilityVisual }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div>
      <div
        onClick={toggleModal}
        onKeyDown={toggleModal}
        role="button"
        aria-label="acessibility"
        tabIndex="0"
      >
        <div className="accessibilityButton">
          <Accessibility />
        </div>
      </div>

      <AccessibilityModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        translator={translator}
        accessibilityVisual={accessibilityVisual}
      />
    </div>
  );
};

AccessibilityButton.propTypes = {
  translator: PropTypes.func.isRequired,
  accessibilityVisual: PropTypes.func.isRequired,
};

export default AccessibilityButton;
