import React, { useEffect } from "react";
import axios from "axios";
import api from "../../../utils/api";
import ApresentacaoConsorcio from "../sections/ApresentacaoConsorcio";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import {
  ConhecaAsVantagens,
  SeuCredito,
  DeoPrimeiroPasso,
  FiqueAtento,
  SaibaMaisConsorcio,
  JaSouCliente,
  BannerConsorcio,
} from "../sections";
import imagensConsorcio from "../../../asserts/images/consorcios";

const HomePage = (props) => {
  useEffect(() => {
    axios
      .get(api.TOKEN_OAUTH, {
        headers: {
          client_id: process.env.REACT_APP_CLIENT_ID,
        },
      })
      .then((result) => {
        sessionStorage.setItem("access_token", result.data.access_token);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Page
      id="page-home"
      {...props}
      images={imagensConsorcio}
      banner={BannerConsorcio}
    >
      <SeguridadePageLayout.Tab eventKey="home">
        <Section
          title="Entenda o consórcio"
          as={ApresentacaoConsorcio}
          sectionName="entenda"
          className="pb-3"
        />
        <Section as={SeuCredito} sectionName="seu credito" className="pb-3" />
        <Section
          title="Vantagens dos nossos consórcios"
          as={ConhecaAsVantagens}
          sectionName="vantagens"
          className="pb-3"
        />
        <Section
          className="cc-primeiro-passo mb-4"
          title="Dê o primeiro passo para realizar o seu sonho!"
          as={DeoPrimeiroPasso}
          sectionName="primeiro passo"
          sectionLayout={null}
        />
        <Section
          title="Fique atento!"
          as={FiqueAtento}
          sectionName="fique atento"
          sectionLayout={null}
          className="py-4 cc-fique-atento"
        />
        <Section
          as={SaibaMaisConsorcio}
          title="Saiba mais sobre o consórcio"
          className="border-top cc-farq-saiba-mais"
          id="saiba-mais-consorcio"
          sectionLayout={null}
          sectionName="saiba mais"
        />
        <Section
          as={JaSouCliente}
          title="Já sou cliente"
          id="ja-sou-cliente"
          sectionLayout={null}
          sectionName="ja sou cliente"
          className="cc-farq-ja-sou"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
