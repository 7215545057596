import styled from "styled-components";
import images from "../../../../asserts/images";
import { christmasCampaing } from "../../../../utils";

export default styled.section`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  &.page-banner {
    background-attachment: scroll;
  }

  @media all and (max-width: 550px) {
    &.page-banner {
      background-image: url(${({ backgroundImageMobile }) =>
        backgroundImageMobile || images.seguridadeBannerMobile});
      /* background-image: url(${christmasCampaing
        ? images.bannerNatalMobile
        : images.seguridadeBannerMobile}); */
    }
  }
`;
