import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { Button } from "../../../components/shared/actions";
import { useMapaCartilhas } from "../../../hooks";
import { createOptionList } from "../../../utils";

const CartilhaContemplados = ({ sectionTitle }) => {
  const { mapResult } = useMapaCartilhas();

  const [kindConsortium, setKindConsortium] = useState(null);
  const [url, setUrl] = useState("");
  const [cartilhaOptions, setCartilhaOptions] = useState([]);

  useEffect(() => {
    if (kindConsortium && mapResult) {
      const options = mapResult
        .filter((e) => e.tipoConsorcio === kindConsortium)
        .map(({ descricao: label, url: value }) => ({ label, value }));

      setCartilhaOptions(options);
      setUrl("");
    }
  }, [mapResult, kindConsortium, setCartilhaOptions]);

  useEffect(() => {
    if (url) {
      window.open(`${process.env.REACT_APP_HOST_STATIC_CDN}${url}`);
    }
  }, [url]);

  return (
    <div className="text-center">
      <h2 className="mb-3">{sectionTitle}</h2>
      <p className="mb-3">
        Aqui você encontra um material bem detalhado para te ajudar a solicitar
        o seu crédito. Consulte a cartilha de acordo com tipo do seu consórcio e
        objetivo.
      </p>
      <h5 className="mb-3 mt-3">Qual tipo de consórcio?</h5>
      <div className="cc-button-cartilha mb-3">
        <Button
          outline
          label="Imobiliário"
          className={`${kindConsortium === "imob" ? "active" : null} mr-lg-3`}
          onClick={() => setKindConsortium("imob")}
        />
        <Button
          outline
          label="Veículos Leves"
          className={kindConsortium === "veic" ? "active" : null}
          onClick={() => setKindConsortium("veic")}
        />
        <Button
          outline
          label="Veículos Pesados"
          className={kindConsortium === "veic-pesados" ? "active" : null}
          onClick={() => setKindConsortium("veic-pesados")}
        />
      </div>

      <h5 className="mb-3">E o seu objetivo?</h5>

      <div className="cc-select col-md-6 col-lg-4 mx-auto p-md-0 mb-5">
        <Form.Group id="cc-select-cartilha" controlId="formGridState">
          <Form.Control
            size="lg"
            style={{ fontFamily: "CAIXA Std" }}
            value={url}
            className="text-align-center"
            as="select"
            defaultValue="Select..."
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          >
            {createOptionList(cartilhaOptions)}
          </Form.Control>
        </Form.Group>
      </div>
    </div>
  );
};

CartilhaContemplados.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};
export default CartilhaContemplados;
