import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const RadioControl = (props) => {
  const { options, value: currentValue, name, setFieldValue } = props;

  const [radioOptions, setRadioOptions] = useState([]);

  useEffect(() => {
    setRadioOptions(options);
    return () => {
      setRadioOptions([]);
    };
  }, [setRadioOptions, options]);

  function onChangeHandle(evt) {
    setFieldValue(name, evt.currentTarget.value);
  }

  return (
    <div className="inline-radio">
      {radioOptions.map(({ label, value }) => (
        <Form.Check
          id={`radio-${name}-value`}
          name={name}
          type="radio"
          key={value}
          defaultValue={value}
          value={value}
          label={label}
          checked={currentValue === value}
          onChange={onChangeHandle}
          inline
        />
      ))}
    </div>
  );
};

RadioControl.defaultProps = {
  value: "",
};

RadioControl.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default RadioControl;
