import React from "react";
import PropTypes from "prop-types";
import { Link } from "../../../components/shared/actions";
import { envs } from "../../../config";

const Info = ({ images, sectionTitle }) => {
  return (
    <div className="content-404">
      <h1>{sectionTitle}</h1>
      <img src={images.feedbackWarning} alt="404" />
      <div className="back">
        Volte para a{" "}
        <Link href={envs.homePortal} ignoreBlank>
          página principal
        </Link>
      </div>
    </div>
  );
};

Info.defaultProps = {};

Info.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default Info;
