import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Banner from "./Banner";
import BannerCarousel from "./BannerCarousel";

const showBanner = (group, groupToShow) => {
  return !group || [].concat(group).find((g) => g === groupToShow);
};
const BannerContents = ({ children, groupFilter }) => {
  const [banners, setBanners] = useState(null);
  const [viewBanners, setViewBanners] = useState(null);
  const [viewGroup, setViewGroup] = useState(null);

  useEffect(() => {
    const validBanners = React.Children.map(children, (item) =>
      React.isValidElement(item) ? item : null
    ).filter((b) => Banner.valid(b));

    setBanners(validBanners);

    return () => {
      setBanners(null);
    };
  }, [children]);

  useEffect(() => {
    if (banners) {
      const bannersFiltered = banners.filter(({ props: { group } }) =>
        showBanner(group, viewGroup)
      );

      setViewBanners(
        bannersFiltered.length >= 2 ? bannersFiltered : bannersFiltered[0]
      );
    }

    return () => {
      setViewBanners([]);
    };
  }, [banners, viewGroup]);

  useEffect(() => {
    setViewGroup(groupFilter);
    return () => {
      setViewGroup(null);
    };
  }, [groupFilter]);

  return (
    <>
      {Array.isArray(viewBanners) ? (
        <BannerCarousel>{viewBanners}</BannerCarousel>
      ) : (
        viewBanners
      )}
    </>
  );
};

BannerContents.Banner = Banner;
BannerContents.Carousel = BannerCarousel;

BannerContents.defaultProps = {
  groupFilter: null,
};

BannerContents.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.objectOf(Banner),
    PropTypes.arrayOf(Banner),
  ]).isRequired,
  groupFilter: PropTypes.string,
};

export default BannerContents;
