/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useState, useEffect } from "react";
import InputTxt from "./Inputs/InputTxt";
import * as S from "./style";
import InputFile from "./Inputs/InputFile";
import InputRadio from "./Inputs/InputRadio";
import FileUploader from "../FileUploader/FileUploader";

const FormInputs = ({
  register,
  errors,
  rgFile,
  cpf,
  comprovante,
  setRendaValue,
  setPremiacaoValue,
  setValue,
  setCpfFile64,
  setRgFile64,
  setCompFile64,
  cpfFilename,
  setCpfFilename,
  rgFilename,
  setRgFilename,
  compFilename,
  setCompFilename,
}) => {
  const [RBSelected, SetRBSelected] = useState("Fisica");

  function handleChange(value) {
    SetRBSelected(value);
  }

  const fields1 = [
    {
      label: "Nome Completo",
      name: "Nome",
      type: "text",
      validationRules: {
        required: "Nome é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "999.999.999-99",
      maskChar: null,
      label: "CPF",
      name: "CPF",
      type: "text",
      validationRules: {
        required: "CPF é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "RG",
      name: "RG",
      type: "text",
      validationRules: {
        required: "RG é obrigatório.",
        pattern: {
          value: !/^[0-9/]*$/,
          message: "RG deve conter apenas des chiffres.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Órgão Expeditor",
      name: "Orgao",
      type: "text",
      validationRules: {
        required: "Órgão Expeditor é obrigatório.",
        pattern: {
          value: /^[A-Za-zÀ-ÿ\s]+$/,
          message: "Órgão Expeditor deve conter apenas letras.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99/99/9999",
      maskChar: null,
      label: "Data de Expedição",
      name: "Expedicao",
      type: "text",
      validationRules: {
        required: "Data de Expedição é obrigatório.",
      },

      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99/99/9999",
      maskChar: null,
      label: "Data de Nascimento",
      name: "Nascimento",
      type: "text",
      validationRules: {
        required: "Data de Nascimento é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "PPE(Sim ou não)",
      name: "PPE",
      type: "text",
      validationRules: {
        required: "PPE é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Renda",
      name: "Renda",
      type: "text",
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Profissão",
      name: "Profissao",
      type: "text",
      validationRules: {
        required: "Profissão é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99999-999",
      maskChar: null,
      label: "CEP",
      name: "CEP",
      type: "text",
      validationRules: {
        required: "CEP é obrigatório.",
        pattern: {
          value: /^\d{5}-\d{3}$/,
          message: "CEP deve estar no formato 99999-999.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Endereço",
      name: "Endereço",
      type: "text",
      validationRules: {
        required: "Endereço é obrigatório.",
      },
    },
    {
      label: "Cidade",
      name: "Cidade",
      type: "text",
      validationRules: {
        required: "Cidade é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Bairro",
      name: "Bairro",
      type: "text",
      validationRules: {
        required: "Bairro é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "(99) 99999-9999",
      maskChar: null,
      label: "Telefone",
      name: "Telefone",
      type: "tel",
      validationRules: {
        required: "Telefone é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Estado",
      name: "Estado",
      type: "text",
      validationRules: {
        required: "Estado é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (número)",
      name: "NumBanco",
      type: "text",
      validationRules: {
        required: "Número do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (nome)",
      name: "NomeBanco",
      type: "text",
      validationRules: {
        required: "Nome do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Conta Corrente",
      name: "ContaCorrente",
      type: "text",
      validationRules: {
        required: "Conta Corrente é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Agência",
      name: "Agencia",
      type: "text",
      validationRules: {
        required: "Agência é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Email",
      name: "Email",
      type: "text",
      validationRules: {
        required: RBSelected === "Fisica",
      },
    },
  ];

  const fields2 = [
    {
      label: "Razão Social",
      name: "RazaoSocial",
      type: "text",
      validationRules: {
        required: "Razão Social é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99.999.999/9999-99",
      maskChar: null,
      label: "CNPJ",
      name: "CNPJ",
      type: "text",
      validationRules: {
        required: "CNPJ é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Atividade Principal",
      name: "Atividade",
      type: "text",
      validationRules: {
        required: "Atividade principal é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Nome do Procurador/Representante Legal/Administrador",
      name: "Nome",
      type: "text",
      validationRules: {
        required:
          "Nome do Procurador/Representante Legal/Administrador é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "999.999.999-99",
      maskChar: null,
      label: "CPF do Procurador/Representante Legal/Administrador",
      name: "CPFProcurador",
      type: "text",
      validationRules: {
        required:
          "CPF do Procurador/Representante Legal/Administrador é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "PPE(Sim ou não)",
      name: "PPE",
      type: "text",
      validationRules: {
        required: "PPE é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Renda do Procurador/Representante Legal/Administrador",
      name: "Renda",
      type: "text",
      validationRules: {
        required:
          "Renda do Procurador/Representante Legal/Administrador é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Profissão do Procurador/Representante Legal/Administrador",
      name: "Profissao",
      type: "text",
      validationRules: {
        required: "Profissão é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Qualificação do Procurador/Representante Legal/Administrador",
      name: "Qualificacao",
      type: "text",
      validationRules: {
        required: "Qualificacao é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Logradouro",
      name: "Endereço",
      type: "text",
      validationRules: {
        required: "Endereço é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-z0-9À-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Cidade",
      name: "Cidade",
      type: "text",
      validationRules: {
        required: "Cidade é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Bairro",
      name: "Bairro",
      type: "text",
      validationRules: {
        required: "Bairro é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99999-999",
      maskChar: null,
      label: "CEP",
      name: "CEP",
      type: "text",
      validationRules: {
        required: "CEP é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Estado",
      name: "Estado",
      type: "text",
      validationRules: {
        required: "Estado é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "(99) 99999-9999",
      maskChar: null,
      label: "Telefone",
      name: "Telefone",
      type: "tel",
      validationRules: {
        required: "Telefone é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (número)",
      name: "NumBanco",
      type: "text",
      validationRules: {
        required: "Número do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (nome)",
      name: "NomeBanco",
      type: "text",
      validationRules: {
        required: "Nome do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Conta Corrente",
      name: "ContaCorrente",
      type: "text",
      validationRules: {
        required: "Conta Corrente é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Agência",
      name: "Agencia",
      type: "text",
      validationRules: {
        required: "Agência é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Email",
      name: "Email",
      type: "text",
      validationRules: {
        required: RBSelected === "Juridica",
      },
    },
  ];

  const fields3 = [
    {
      label: "Nome Completo",
      name: "Nome",
      type: "text",
      validationRules: {
        required: "Nome é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "999.999.999-99",
      maskChar: null,
      label: "CPF",
      name: "CPF",
      type: "text",
      validationRules: {
        required: "CPF é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "RG",
      name: "RG",
      type: "text",
      validationRules: {
        required: "RG é obrigatório.",
        pattern: {
          value: !/^[0-9/]*$/,
          message: "RG deve conter apenas des chiffres.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Órgão Expeditor",
      name: "Orgao",
      type: "text",
      validationRules: {
        required: "Órgão Expeditor é obrigatório.",
        pattern: {
          value: /^[A-Za-zÀ-ÿ\s]+$/,
          message: "Órgão Expeditor deve conter apenas letras.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99/99/9999",
      maskChar: null,
      label: "Data de Expedição",
      name: "Expedicao",
      type: "text",
      validationRules: {
        required: "Data de Expedição é obrigatório.",
      },

      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99/99/9999",
      maskChar: null,
      label: "Data de Nascimento",
      name: "Nascimento",
      type: "text",
      validationRules: {
        required: "Data de Nascimento é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "PPE(Sim ou não)",
      name: "PPE",
      type: "text",
      validationRules: {
        required: "PPE é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Renda",
      name: "Renda",
      type: "text",
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Profissão",
      name: "Profissao",
      type: "text",
      validationRules: {
        required: "Profissão é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "99999-999",
      maskChar: null,
      label: "CEP",
      name: "CEP",
      type: "text",
      validationRules: {
        required: "CEP é obrigatório.",
        pattern: {
          value: /^\d{5}-\d{3}$/,
          message: "CEP deve estar no formato 99999-999.",
        },
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Endereço",
      name: "Endereço",
      type: "text",
      validationRules: {
        required: "Endereço é obrigatório.",
      },
    },
    {
      label: "Cidade",
      name: "Cidade",
      type: "text",
      validationRules: {
        required: "Cidade é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Bairro",
      name: "Bairro",
      type: "text",
      validationRules: {
        required: "Bairro é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      mask: "(99) 99999-9999",
      maskChar: null,
      label: "Telefone",
      name: "Telefone",
      type: "tel",
      validationRules: {
        required: "Telefone é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Estado",
      name: "Estado",
      type: "text",
      validationRules: {
        required: "Estado é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[A-Za-zÀ-ÿ\s]+$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (número)",
      name: "NumBanco",
      type: "text",
      validationRules: {
        required: "Número do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Banco (nome)",
      name: "NomeBanco",
      type: "text",
      validationRules: {
        required: "Nome do Banco é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[a-zA-Z /]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Conta Corrente",
      name: "ContaCorrente",
      type: "text",
      validationRules: {
        required: "Conta Corrente é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Agência",
      name: "Agencia",
      type: "text",
      validationRules: {
        required: "Agência é obrigatório.",
      },
      onKeyDown: (e) => {
        if (
          !/^[0-9/]*$/.test(e.key) &&
          e.key !== "Backspace" &&
          e.key !== "Tab"
        ) {
          e.preventDefault();
        }
      },
    },
    {
      label: "Email",
      name: "Email",
      type: "text",
      validationRules: {
        required: RBSelected === "Indicador",
      },
    },
  ];

  const [fields, SetFields] = useState(fields1);

  const CliqueAquiSpan = (
    <span style={{ color: "blue", cursor: "pointer" }}>Clique aqui</span>
  );
  const DocumentoComFoto = <span>Documento com foto</span>;
  const CopiaDoRG = <span>RG</span>;
  const CopiaDoCNH = <span>CNH</span>;
  const CopiaDoCPF = <span>CPF</span>;
  const ComprovanteEndereco = <span>comprovante de endereço</span>;
  const Anexar = "para anexar uma cópia do seu";
  const CopiaContratoSocial = <span>Contrato Social</span>;
  const CopiaAlteracaoContratual = <span>Última Alteração Contratual</span>;
  const CopiaCartaoInscricaoCNPJ = <span>Cartão de Inscrição no CNPJ</span>;

  const RadioBtns = [
    {
      label: "Pessoa Fisica",
      name: "Fisica",
      type: "radio",
    },
    {
      label: "Pessoa Juridica",
      name: "Juridica",
      type: "radio",
    },
    {
      label: "Indicador CAIXA",
      name: "Indicador",
      type: "radio",
    },
  ];

  useEffect(() => {
    if (RBSelected === "Juridica") {
      SetFields(fields2);
    } else if (RBSelected === "Indicador") {
      SetFields(fields3);
    } else {
      SetFields(fields1);
    }
  }, [RBSelected]);

  return (
    <S.Container>
      <S.RadioContainer>
        {RadioBtns.map(({ label, name, type }) => (
          <InputRadio
            label={label}
            name={name}
            type={type}
            register={register}
            onClick={handleChange}
            Selected={RBSelected}
          />
        ))}
      </S.RadioContainer>
      <S.InputContainer>
        {fields.map(
          ({
            mask,
            maskChar,
            label,
            name,
            type,
            validationRules,
            onKeyDown,
          }) => (
            <InputTxt
              mask={mask}
              maskChar={maskChar}
              label={label}
              name={name}
              type={type}
              register={register}
              setValue={setValue}
              errors={errors}
              validationRules={validationRules}
              onkeydown={onKeyDown}
              setRendaValue={setRendaValue}
              setPremiacaoValue={setPremiacaoValue}
            />
          )
        )}
        <S.ContainerNovo>
          {(RBSelected === "Fisica" || RBSelected === "Indicador") && (
            <>
              <FileUploader
                bg="#F7BCAC"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {CopiaDoCPF}
                  </p>
                }
                name="CPFCopia"
                setFileBase64={setCpfFile64}
                fileName={cpfFilename}
                setFileName={setCpfFilename}
              />
              <FileUploader
                bg="#9CB6FF"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {CopiaDoRG}, {CopiaDoCNH} ou{" "}
                    {DocumentoComFoto}
                  </p>
                }
                label="Copia do RG, CNH ou Documento"
                name="RGCopia"
                setFileBase64={setRgFile64}
                fileName={rgFilename}
                setFileName={setRgFilename}
              />
              <FileUploader
                bg="#9CB6FF"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {ComprovanteEndereco} emitido nos
                    últimos 180 dias
                  </p>
                }
                label="Comprovante de Endereço"
                name="CompEndereco"
                setFileBase64={setCompFile64}
                fileName={compFilename}
                setFileName={setCompFilename}
              />
            </>
          )}

          {RBSelected === "Juridica" && (
            <>
              <FileUploader
                bg="#F7BCAC"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {CopiaContratoSocial} ou{" "}
                    {CopiaAlteracaoContratual}
                  </p>
                }
                name="CPFCopia"
                setFileBase64={setCpfFile64}
                fileName={cpfFilename}
                setFileName={setCpfFilename}
              />
              <FileUploader
                bg="#9CB6FF"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {CopiaCartaoInscricaoCNPJ}
                  </p>
                }
                label="Clique aqui para anexar uma cópia do seu Cartão de Inscrição
                    no CNPJ"
                name="RGCopia"
                setFileBase64={setRgFile64}
                fileName={rgFilename}
                setFileName={setRgFilename}
              />
              <FileUploader
                bg="#9CB6FF"
                text={
                  <p>
                    {CliqueAquiSpan} {Anexar} {CopiaDoRG} ou {CopiaDoCPF}
                  </p>
                }
                label="Clique aqui para anexar uma cópia do RG e CPF do Representante Legal."
                name="CompEndereco"
                setFileBase64={setCompFile64}
                fileName={compFilename}
                setFileName={setCompFilename}
              />
            </>
          )}
        </S.ContainerNovo>
      </S.InputContainer>
    </S.Container>
  );
};

export default FormInputs;
