import styled from "styled-components";
import { media } from "../../../../../../utils";

export const ExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const InternalContainerDesktop = media.desktop`
  flex-direction: row;
`;

export const InternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${InternalContainerDesktop}
`;

export const MainText = styled.p`
  font-size: 18px;
  line-height: 24px;
  max-width: 700px;
  color: #646363;
`;

export const AuxiliarText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`;

export const LabelInputFile = styled.label`
  min-width: 20%;
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
