import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { InputControl } from "../shared/forms";

const InputCotaControl = ({
  formikBag,
  cotas,
  position,
  cotaHandleChange,
  removeControlHandle,
  ...props
}) => {
  const removeButtonIcon =
    cotas.length > 1 ? (
      <FaTimes
        onClick={() => removeControlHandle(position)}
        className="cota-remove-icon"
      />
    ) : null;

  return cotas.length && position + 1 <= cotas.length ? (
    <Col
      xs={12}
      md={{ span: 4, offset: 4 }}
      className="p-relative cota-control"
    >
      <InputControl
        controlId={`resultado-assembleias.cota[${position}]`}
        placeHolder="Nº da cota"
        name={`cotas[${position}]`}
        value={cotas[position]}
        {...props}
        {...formikBag}
        onChange={(evt) => cotaHandleChange(evt, position)}
      />
      {removeButtonIcon}
    </Col>
  ) : null;
};

InputCotaControl.defaultProps = {
  position: 0,
};

InputCotaControl.propTypes = {
  formikBag: PropTypes.objectOf(Object).isRequired,
  cotas: PropTypes.arrayOf(Object).isRequired,
  position: PropTypes.number,
  cotaHandleChange: PropTypes.func.isRequired,
  removeControlHandle: PropTypes.func.isRequired,
};

export default InputCotaControl;
