import useAjaxRequest, { POST } from "./useAjaxRequest";
import { endPoints } from "../../config";

const useOuvidoria = () => {
  const {
    response: {
      error: errorOuvidoria,
      loading: isLoadingOuvidoria,
      data: responseData,
    },
    performRequest,
  } = useAjaxRequest();

  async function createOuvidoria(formData, reCaptcha) {
    const options = {
      method: POST,
      url: endPoints.criarOuvidoria,
      data: formData,
      headers: {
        "g-captcha-response": reCaptcha,
      },
    };
    return performRequest(options);
  }

  return { errorOuvidoria, isLoadingOuvidoria, responseData, createOuvidoria };
};

export default useOuvidoria;
