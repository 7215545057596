import React from "react";
import PropTypes, { element } from "prop-types";
import { Row, Col } from "react-bootstrap";

const Step = ({ stepNumber, children, footer }) => (
  <div className="step-item">
    <div className="step-count-header">
      <h5 className="c-primary">{stepNumber}</h5>
    </div>
    <div className="step-body">{children}</div>
    {footer && <div className="step-footer">{footer}</div>}
  </div>
);

Step.defaultProps = {
  footer: null,
};

Step.propTypes = {
  stepNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(element)]),
};

const ContentWithStep = ({
  children,
  header,
  title,
  widthCol,
  classNameStep,
}) => {
  const steps = React.Children.map(children, (item, index) => {
    if (React.isValidElement(item)) {
      return (
        <p className="m-0">
          <strong className="text-brand">{index + 1} - </strong> {item}
        </p>
      );
    }
    return null;
  });
  return (
    <Row className="justify-content-center mb-5">
      <Col xs={12} md={widthCol}>
        <Row>
          <Col>{header || <h6 className="text-center p-3">{title}</h6>}</Col>
        </Row>
        <Row>
          <Col className={classNameStep}>{steps}</Col>
        </Row>
      </Col>
    </Row>
  );
};

ContentWithStep.defaultProps = {
  header: null,
  widthCol: 11,
  classNameStep: "",
};

ContentWithStep.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(element)]),
  widthCol: PropTypes.number,
  classNameStep: PropTypes.string,
};

export default ContentWithStep;
