import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const PhoneInput = ({ telefone, formikBag }) => {
  const phoneMask = (value) => {
    return value
      .replace(/\D/, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const handlePhoneCellphone = (e) => {
    const unmaskedValue = e.target.value
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    e.target.value = phoneMask(unmaskedValue);
    formikBag.onChange(e);
  };

  return (
    <InputControl
      inputStyle={{ minHeight: "50px" }}
      controlId="formOuvidoria.ouvidoria.contato.telefone"
      label="Telefone"
      placeHolder="Digite um número de telefone"
      name="ouvidoria.contato.telefone"
      type="tel"
      value={telefone}
      {...formikBag}
      onChange={handlePhoneCellphone}
    />
  );
};

PhoneInput.propTypes = {
  telefone: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default PhoneInput;
