import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const AddressComplementInput = ({ complemento, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.complemento"
    label="Complemento"
    placeHolder="Digite um complemento"
    name="ouvidoria.contato.complemento"
    value={complemento}
    {...formikBag}
  />
);

AddressComplementInput.propTypes = {
  complemento: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default AddressComplementInput;
