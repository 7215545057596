import React from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import Card from "../shared/cards";
import { applyKey } from "../../utils";

const ContentWithCardList = ({ listItens, children, showItens, className }) => {
  const contentList = applyKey(listItens, (item) => {
    const cardContent = !React.isValidElement(item) ? (
      <Card
        className={item.className}
        image={item.imagem}
        header={item.titleConteudo}
        footer={item.footer}
      >
        {item.conteudo}
      </Card>
    ) : (
      React.cloneElement(<Card />, item.props)
    );

    return <Col {...{ xs: 12, lg: 3, ...item.col }}>{cardContent}</Col>;
  });

  return (
    <Row className={`cvp-content-list-card ${className}`}>
      <Col xs={12} className="text-center">
        {children}
      </Col>
      <Collapse in={showItens}>
        <Col className="d-sm-flex justify-content-center">
          <Row>{contentList}</Row>
        </Col>
      </Collapse>
    </Row>
  );
};

ContentWithCardList.defaultProps = {
  showItens: true,
};

ContentWithCardList.propTypes = {
  showItens: PropTypes.bool,
  listItens: PropTypes.arrayOf(Object).isRequired,
};

export default ContentWithCardList;
