import React from "react";
import * as style from "./styles";
import File from "../../../../asserts/images/consorcios/file.png";
import Trash from "../../../../asserts/images/consorcios/trashbtn.png";

function InputFile({
  bg,
  text,
  label,
  name,
  type,
  register,
  errors,
  fileName,
}) {
  const handleRemoveFile = () => {
    document.getElementById(name).value = null;
  };

  return (
    <style.InputFdiv>
      <style.LabInputFile htmlFor={name} bg={bg}>
        {text}
      </style.LabInputFile>
      <style.InputFile
        id={name}
        name={name}
        type={type}
        {...register(name, { required: `${label} é obrigatório` })}
      />
      {errors[name] && <p>{errors[name].message}</p>}

      {fileName && fileName.length > 0 && (
        <style.FileName>
          <img src={File} alt="file" />
          <p>{fileName[0]?.name}</p>
          <style.DeleteButton onClick={handleRemoveFile}>
            <img src={Trash} alt="delete" />
          </style.DeleteButton>
        </style.FileName>
      )}
    </style.InputFdiv>
  );
}

export default InputFile;
