import React from "react";
import PropTypes from "prop-types";
import { Text } from "@gcs/design-system/react";
import styled from "styled-components";
import FeedbackSucesso from "../../../asserts/images/feedback-sucesso.svg";

const SCREEN_BREAKPOINT = "860px";

const OuvidoriaSucesso = ({ newProtocol }) => {
  return (
    <Wrapper>
      <FeedbackSucessoIcon />
      <Title variant="headline-04" align="center" />
      <Description variant="body01-lg" align="center">
        O seu protocolo é <Strong>{newProtocol?.protocolo}</Strong>.
        <br />A ocorrência será concluída até o dia{" "}
        {newProtocol?.dataPrevista
          ? new Date(newProtocol?.dataPrevista).toLocaleDateString()
          : ""}
        .
      </Description>
      <Description variant="body01-lg" align="center">
        Aguarde que entraremos em contato com você.
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeedbackSucessoIcon = styled(FeedbackSucesso)`
  margin-top: 32px;
  margin-bottom: 9px;

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    margin-bottom: 44px;
  }
`;

const Title = styled(Text)`
  font-family: "CAIXA Std Bold";
  margin-bottom: 44px;

  &:after {
    content: "Sua ocorrência foi aberta com sucesso!";
  }

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    &:after {
      content: "Sua ocorrência para a Ouvidoria foi aberta com sucesso!";
    }
  }
`;

const Description = styled(Text)`
  font-family: "CAIXA Std";
  font-size: 24px;
`;

const Strong = styled.strong`
  font-family: "CAIXA Std Bold";
`;

OuvidoriaSucesso.propTypes = {
  newProtocol: PropTypes.objectOf(Object).isRequired,
};

export default OuvidoriaSucesso;
