import React from "react";
import { Section } from "@components/shared/pages";
import { Link, PhoneLink } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

const faqItems = [
  {
    label: "Como consultar as atas das assembleias",
    content: (
      <>
        <p>
          Para baixar as atas das asssembleias,{" "}
          <Link href={LINKS.areaLogada} label="área do cliente" />.
        </p>
      </>
    ),
  },
  {
    label: "Minha cota foi cancelada. Quando vou receber o que já paguei?",
    content: (
      <>
        <p>
          Se desistiu do consórcio ou teve sua cota cancelada antes da
          contemplação, você também participa das assembleias. O recebimento do
          valor pago de fundo comum durante o período de participação do grupo
          será atualizado e descontado 10% de multa, conforme previsto no
          Contrato de Adesão. Este valor será devolvido por meio de sorteio nas
          assembleias mensais ou no encerramento do grupo.
        </p>

        <p>Para saber mais, acesse seu contrato:</p>

        <p>
          <Link
            href={LINKS.contratoAdesaoImobiliario}
            label="Contrato de adesão imobiliário"
          />
          <br />
          <Link
            href={LINKS.contratoAdesaoVeiculos}
            label="Contrato de adesão veículos"
          />
        </p>
      </>
    ),
  },
  {
    label: "Fui contemplado por lance. O que preciso fazer?",
    content: (
      <>
        <p>
          A partir da data da assembleia em que foi contemplado, você deve,{" "}
          <b>em até 5 dias úteis, entrar em contato por telefone</b> para
          confirmar a sua contemplação e receber o boleto para pagamento do
          lance. <b>Após este prazo, você perderá o direito à contemplação</b>
          , por isso, não deixe pra última hora.
          <br />
          <p />
          <p>
            Central de Serviços e Relacionamento: <br />
            <PhoneLink phone="0800 975 1000" />.
            <br />
          </p>
          Lembre-se: é importante manter seus dados sempre atualizados para
          receber nossas comunicações.
        </p>
      </>
    ),
  },
];

export const TemAlgumaDuvida = ({ sectionTitle }) => {
  return <SeguridadeFaq title={sectionTitle} faqItems={faqItems} right />;
};

TemAlgumaDuvida.defaultProps = {
  ...Section.defaultProps,
};

TemAlgumaDuvida.propTypes = {
  ...Section.propTypes,
};

export default TemAlgumaDuvida;
