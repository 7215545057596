import React from "react";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { redirects } from "../../config";

export const history = createBrowserHistory();

const MainRouter = ({ children }) => {
  const { pathname } = window.location;

  const redirect = redirects.find(([to]) => to === pathname);
  if (redirect) {
    const [, from] = redirect;
    window.location = from;
  }

  return <Router>{children}</Router>;
};

MainRouter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainRouter;
