import React, { useState } from "react";
import PropTypes from "prop-types";
import ContentWithImage from "../shared/contents/ContentWithImage";
import { createImageElement } from "../../asserts/images";

const Apresentacao = ({
  children,
  image,
  imageColumn,
  right,
  title,
  titleCenter,
  titleRight,
  contentColumn,
  titleAs,
  titleOptions,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  window.addEventListener("resize", () => {
    setIsMobile(window.matchMedia("(max-width: 768px)").matches);
  });

  const titleCentered = titleCenter ? "text-center" : "text-left";
  const titlePosition = titleRight ? "text-right" : titleCentered;
  return (
    <ContentWithImage
      imageColumn={{
        xs: 3,
        className: "d-none d-lg-flex justify-content-center align-items-center",
        ...imageColumn,
      }}
      image={createImageElement(image, { alt: title })}
      imageRight={right}
      contentColumn={{ className: "px-0 px-md-2", ...contentColumn }}
      className="bloco-apresentacao"
    >
      {title
        ? React.createElement(
            titleAs,
            {
              ...titleOptions,
              className: `content-title ${
                isMobile ? "text-center" : titlePosition
              } ${titleOptions?.className || ""}`,
            },
            title
          )
        : null}
      {children}
    </ContentWithImage>
  );
};

Apresentacao.defaultProps = {
  imageColumn: { xs: 3 },
  right: false,
  title: "",
  titleCenter: false,
  titleRight: false,
  contentColumn: null,
  titleAs: "h3",
  titleOptions: null,
};

Apresentacao.propTypes = {
  image: PropTypes.oneOf([PropTypes.string]).isRequired,
  imageColumn: PropTypes.objectOf(Object),
  right: PropTypes.bool,
  title: PropTypes.string,
  titleCenter: PropTypes.bool,
  titleRight: PropTypes.bool,
  contentColumn: PropTypes.objectOf(Object),
  titleAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  titleOptions: PropTypes.objectOf(Object),
};

export default Apresentacao;
