import React from "react";
import * as style from "./styles";

const InputRadio = ({ label, name, type, register, onClick, Selected }) => (
  <style.DivRB>
    <style.LabelRadio>
      <style.InputRadio
        id={name}
        value={name}
        type={type}
        checked={Selected === name}
        onClick={() => onClick(name)}
        {...register("tipo")}
      />
      {label}
    </style.LabelRadio>
  </style.DivRB>
);

export default InputRadio;
