import React from "react";
import PropTypes from "prop-types";
import AccessibilityCards from "./AccessibilityCards";
import "./accessibilityStyle.css";

const AccessibilityModal = ({
  isOpen,
  onClose,
  translator,
  accessibilityVisual,
}) => {
  if (!isOpen) return null;
  return (
    <div className="accessibilityModal">
      <div className="accessibilityModalContent">
        <div className="accessibilityModalHeader">
          <p className="accessibilityModalTitle">Acessibilidade</p>
          <span
            role="button"
            tabIndex="0"
            className="closeButton"
            onClick={onClose}
            onKeyDown={onClose}
          >
            x
          </span>
        </div>
        <AccessibilityCards
          translator={translator}
          accessibilityVisual={accessibilityVisual}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

AccessibilityModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  translator: PropTypes.func.isRequired,
  accessibilityVisual: PropTypes.func.isRequired,
};

export default AccessibilityModal;
