import React from "react";
import PropTypes from "prop-types";
import { createOptionList } from "../../../utils";
import FormControl from "./FormControl";

const SelectControl = (props) => {
  const { options: rawOptions, placeHolder } = props;
  const options = createOptionList(rawOptions, {
    label: `${placeHolder}`,
    value: "",
  });
  return <FormControl {...props} as="select" options={options} />;
};

SelectControl.defaultProps = {
  placeHolder: "Selecione",
};
SelectControl.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  placeHolder: PropTypes.string,
};

export default SelectControl;
