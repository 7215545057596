import React from "react";
import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";
import { nanoid } from "nanoid";

const TabPane = (props) => {
  const { children, eventKey, label, images, setKey, analytics } = props;

  const elements = React.Children.map(children, (el) => {
    return React.isValidElement(el)
      ? React.cloneElement(el, { images, navChangeHandle: setKey, analytics })
      : null;
  });

  return (
    <Tab.Pane eventKey={eventKey} label={label}>
      {elements}
    </Tab.Pane>
  );
};

TabPane.isTabPane = (el) => TabPane.name === el.type.name;

TabPane.defaultProps = {
  eventKey: nanoid(8),
  label: null,
  images: {},
  setKey: null,
  analytics: {},
};

TabPane.propTypes = {
  eventKey: PropTypes.string,
  label: PropTypes.string,
  images: PropTypes.objectOf(Object),
  setKey: PropTypes.func,
  analytics: PropTypes.objectOf(Object),
};

export default TabPane;
