import { useState } from "react";

const useBreadCrumb = (initialValue) => {
  const [crumbs, setCrumbs] = useState({ items: initialValue || [] });

  function push(item, updateLast) {
    const { items } = crumbs;
    const newItems = items.concat([...(updateLast ? [updateLast] : []), item]);

    setCrumbs({ ...crumbs, items: newItems });
  }

  return [crumbs, push];
};

export default useBreadCrumb;
